import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getSelectedLanguage',
    ]),
  },

  methods: {
    jsonToProperties: function(elementsJSONArr, returnErrors){
      var self = this;
      var elementsPropArr = [];
      var blockStart = [];
      var newObj;
      var element;
      var blockElem;
      var removedOldConditionalPage = {removed: 0, allPages: 0, onPages: []};

      var jsonObj = elementsJSONArr;
      var string = JSON.stringify(jsonObj);
      var regEx;

      //replace old upload urls
      for(var oldUrl in self.$urlUploadsOld){
        if(self.$urlUploadsOld[oldUrl] != self.$urlUploads){
          regEx = new RegExp(self.$urlUploadsOld[oldUrl], "g");
          string = string.replace(regEx, self.$urlUploads);
        }
      }

      elementsJSONArr = JSON.parse(string);

      //remove pages in blocks (conditional page should be used now)
      //iterate through array backwards (open blockCounter--, close blockCounter++)
      var blockCounter = 0;
      for(var n = elementsJSONArr.length - 1; n >= 0; n--){
        if(elementsJSONArr[n].elementtype === "elements/blockopens"){
          blockCounter--;
        }else if(elementsJSONArr[n].elementtype === "elements/blockcloses"){
          blockCounter++;
        }else if(elementsJSONArr[n].elementtype === "elements/pages" && blockCounter > 0){
          elementsJSONArr.splice(n, 1);
          removedOldConditionalPage.onPages.push(removedOldConditionalPage.allPages); //save number of counted pages (later calculate: all - number) since backwards
          removedOldConditionalPage.removed++;
          removedOldConditionalPage.allPages++;
        }else if(elementsJSONArr[n].elementtype === "elements/pages"){
          removedOldConditionalPage.allPages++;
        }
      }

      elementsJSONArr.forEach(function(jsonObj){
        switch(jsonObj.elementtype){
          case "elements/headlines":
            newObj = self.getHeadlineProperties(jsonObj);
            element = {type: 'ElementsHeadline', propsObj: newObj.propsObj, translations: newObj.translations};
          break;

          case "elements/pages":
            newObj = self.getPageProperties(jsonObj);
            element = {type: 'ElementsPage', propsObj: newObj.propsObj, translations: newObj.translations};
          break;

          case "elements/lines":
            newObj = self.getLineProperties(jsonObj);
            element = {type: 'ElementsLine', propsObj: newObj};
          break;

          case "elements/spaces":
            newObj = self.getSpaceProperties(jsonObj);
            element = {type: 'ElementsSpace', propsObj: newObj};
          break;

          case "elements/questions":
            newObj = self.getQuestionProperties(jsonObj);
            element = {type: 'ElementsQuestion', propsObj: newObj.propsObj, translations: newObj.translations};
          break;

          case "elements/texts":
            newObj = self.getTextProperties(jsonObj);
            element = {type: (newObj.propsObj.type != "table") ? 'ElementsText' : 'ElementsTable',
              propsObj: newObj.propsObj, translations: newObj.translations};
          break;

          case "elements/media":
            newObj = self.getMediaProperties(jsonObj);
            element = {type: 'ElementsMedia', propsObj: newObj.propsObj, translations: newObj.translations};
          break;

          case "elements/blockopens":
            newObj = self.getBlockProperties(jsonObj);
            element = {type: 'ElementsBlock', propsObj: newObj.propsObj, translations: newObj.translations};

            //save ref of block ->  for all elements after block element, before next block close element,
            //elements are added to elements array of this block (see below)
            blockStart.push(element);
          break;

          case "elements/blockcloses":
            //get innerest block element ref (last entry of blockStart) (with nested elements inside) which is now finished
            //-> added to elements or block elements array (see below)
            //blockStart is already updated to whole block is added to correct level
            element = blockStart.pop();
          break;

          default:
          break;
        }

        if(jsonObj.elementtype != "elements/blockopens"){
          if(blockStart.length > 0){ //elements which are inside block -> add to block elements array (of most deeply nested one -> last entry of blockStart)
            blockElem = blockStart[blockStart.length - 1];
            blockElem.propsObj.elements.push(element);
          }else{ //elements which are not inside block -> add to elements array
            elementsPropArr.push(element);
          }
        }
      });

      //now every lesson has to have page as first element; for page config -> add if not already
      //use elementsJSONArr.length + 1 for id
      if(elementsPropArr.length === 0 || elementsPropArr[0].type != "ElementsPage"){
        var newElement = this.getNewElementProperties("ElementsPage", elementsJSONArr.length + 1, this.getSelectedLanguage, "")
        elementsPropArr.splice(0, 0, newElement);
      }

      if(returnErrors){
        var pages = removedOldConditionalPage.onPages.reverse();
        pages = pages.map(x => removedOldConditionalPage.allPages - x)
        //removed: how many conditional pages were removed
        //pages: on which pages were the blocks from which the page breaks were removed 
        return { removedOldConditionalPage: removedOldConditionalPage.removed, pages: pages, elements: elementsPropArr}
      }else{
        return elementsPropArr
      }
    },

    getHeadlineProperties: function(jsonObj){
      var headline = jsonObj.translations[0].headline;

      var counter = 0;
      //which html tag
      if(headline[0] === "<"){
        counter = headline[2];
      }

      var selHeadLine = 'headline' + counter;
      var translations = {};
      var selectedLanguageIncluded = false;

      for(var translation in jsonObj.translations){
        var hl = jsonObj.translations[translation].headline;
        if(selHeadLine != 'headline0'){
          hl = hl.slice(4,hl.length).slice(0,-5);
        }
        if(jsonObj.translations[translation].locale === this.getSelectedLanguage){
          selectedLanguageIncluded = true;
        }
        translations[jsonObj.translations[translation].locale] = hl;
      }

      if(!selectedLanguageIncluded){
        translations[this.getSelectedLanguage] = "";
      }

      var propsObj =
      {
        selectedHeadlineProp: selHeadLine,
        id: jsonObj.position,
        color: jsonObj.color ? jsonObj.color : "#000000"
      };

      return { propsObj: propsObj, translations: translations }
    },

    getPageProperties: function(jsonObj){
      var condition = "";
      var complexCond = false;
      if(jsonObj.condition && jsonObj.condition.ifBlock){
        condition = jsonObj.condition.ifBlock;
        complexCond = (jsonObj.condition.ifBlock.nodeType != "leaf");

        if(!condition.operation){
          condition.operation = "";
        }
        if(!condition.secondValue){
          condition.secondValue = "";
        }
      }

      var propsObj = {
        id: jsonObj.position,
        color: jsonObj.color ? jsonObj.color : "",
        condition: condition,
        isComplexCondition: complexCond,
        conditionDescription: jsonObj.condition && jsonObj.condition.description ? jsonObj.condition.description : ""
      };

      var translations = {};

      if(this.$enabledFeatures.includes('progress')){
        var selectedLanguageIncluded = false;

        for(var translation in jsonObj.translations){
          if(jsonObj.translations[translation].locale === this.getSelectedLanguage){
            selectedLanguageIncluded = true;
          }
          translations[jsonObj.translations[translation].locale] = jsonObj.translations[translation].progressbar_text;
        }

        if(!selectedLanguageIncluded){
          translations[this.getSelectedLanguage] = "";
        }
      }

      return { propsObj: propsObj, translations: translations }
    },

    getLineProperties: function(jsonObj){
      return {
        id: jsonObj.position,
      }
    },

    getSpaceProperties: function(jsonObj){
      return {
        id: jsonObj.position,
        size: jsonObj.size,
      }
    },

    getQuestionProperties: function(jsonObj){
      var propsObj =
      {
        labelProp: jsonObj.label,
        id: jsonObj.position,
        questionReq: jsonObj.required ? (jsonObj.required === true || jsonObj.required === 1) : false,
        selectedQuestionProp: jsonObj.questiontype,
        values: jsonObj.values
      };

      if(propsObj.selectedQuestionProp === "TextDate" && propsObj.values && propsObj.values.length != 0){
        //old date questions have values [] or null
        if(propsObj.values[0] === "time"){
          propsObj.selectedQuestionProp = "TextTime";
        }else if(propsObj.values[0] === "datetime"){
          propsObj.selectedQuestionProp = "TextDateTime";
        }
        propsObj.values = [];
      }else if(propsObj.selectedQuestionProp === "TextString" || propsObj.selectedQuestionProp === "TextArea"){
        propsObj.values = [];
      }else if(propsObj.selectedQuestionProp === "Slider" && !propsObj.values.start && propsObj.values.start != 0){
        propsObj.values.start = propsObj.values.min;
      }

      var translations = {};
      var selectedLanguageIncluded = false;
      var answersLength = 0;

      for(var translation in jsonObj.translations){
        //slider answers were accidentally not saved after refactoring update -> init if empty
        if(propsObj.selectedQuestionProp === "Slider" && jsonObj.translations[translation].answers.length === 0){
          jsonObj.translations[translation].answers = [
            {value: propsObj.values.min, label: ""},
            {value: propsObj.values.max, label: ""}
          ];
        }

        if(jsonObj.translations[translation].locale === this.getSelectedLanguage){
          selectedLanguageIncluded = true;
        }
        translations[jsonObj.translations[translation].locale] = {
          question: jsonObj.translations[translation].question,
          answers: jsonObj.translations[translation].answers
        };
        answersLength = jsonObj.translations[translation].answers.length;
      }

      if(!selectedLanguageIncluded){
        translations[this.getSelectedLanguage] = {
          question: "",
          answers: new Array(answersLength).fill("")
        };
      }

      return { propsObj: propsObj, translations: translations }
    },

    getTextProperties: function(jsonObj){
      var propsObj =
      {
        id: jsonObj.position,
        type: jsonObj.type ? jsonObj.type : ""
      };

      var translations = {};
      var selectedLanguageIncluded = false;

      for(var translation in jsonObj.translations){
        if(jsonObj.translations[translation].locale === this.getSelectedLanguage){
          selectedLanguageIncluded = true;
        }
        translations[jsonObj.translations[translation].locale] = jsonObj.translations[translation].text;
      }

      if(!selectedLanguageIncluded){
        translations[this.getSelectedLanguage] = "";
      }

      return { propsObj: propsObj, translations: translations }
    },

    getMediaProperties: function(jsonObj){
      var propsObj =
      {
        id: jsonObj.position,
        linkType: jsonObj.mimetype,
        width: jsonObj.width ? jsonObj.width : 100,
        height: jsonObj.height ? jsonObj.height : (jsonObj.width ? jsonObj.width : 100),
        banner: jsonObj.banner && (jsonObj.banner === true || jsonObj.banner === 1) ? true : false,
        isAudio: jsonObj.is_audio && (jsonObj.is_audio === true || jsonObj.is_audio === 1) ? true : false,
      };

      var translations = {};
      var selectedLanguageIncluded = false;
      var link = "";
      var filename = "";

      for(var translation in jsonObj.translations){
        if(jsonObj.translations[translation].locale === this.getSelectedLanguage){
          selectedLanguageIncluded = true;
        }
        translations[jsonObj.translations[translation].locale] = {
          uri: jsonObj.translations[translation].uri,
          filename: jsonObj.translations[translation].filename,
          subtitle: jsonObj.translations[translation].subtitle,
          description: jsonObj.translations[translation].description
        };

        //if any uri and filename are set, save them to set for new language
        if(jsonObj.translations[translation].uri){
          link = jsonObj.translations[translation].uri;
        }
        if(jsonObj.translations[translation].filename){
          filename = jsonObj.translations[translation].filename;
        }
      }

      if(!selectedLanguageIncluded){
        translations[this.getSelectedLanguage] = {
          uri: link,
          filename: filename,
          subtitle: "",
          description: "",
        };
      }

      return { propsObj: propsObj, translations: translations }
    },

    getBlockProperties: function(jsonObj){
      var condition = "";
      var thenBlock = "";
      var complexCond = false;
      if(jsonObj.condition){
        if(jsonObj.type != "details" && jsonObj.type != "none"){
          if(jsonObj.condition.ifBlock){
            condition = jsonObj.condition.ifBlock;
            complexCond = (jsonObj.condition.ifBlock.nodeType != "leaf");
          }else{
            //change first old strcuture to new structure -> can be deleted later
            if(jsonObj.condition.label){
              condition = {
                nodeType: "leaf",
                leafType: "question",
                questionLabel: jsonObj.condition.label,
                value: jsonObj.condition.value,
              };
              complexCond = false;
            }
            //change second old structure to new structure -> can be deleted later
            else if(jsonObj.condition.nodeType){
              condition = jsonObj.condition;
              complexCond = (jsonObj.condition.nodeType != "leaf");
            }
          }
          if(!condition.operation){
            condition.operation = "";
          }
          if(!condition.secondValue){
            condition.secondValue = "";
          }
        }

        if(jsonObj.condition && jsonObj.condition.thenBlock){
          thenBlock = jsonObj.condition.thenBlock;
        }else{ //change old structure to new structure -> can be deleted later
          thenBlock = {
            repeat: {
              question: false,
              data: 1
            }
          };
        }
      }

      var propsObj =
      {
        id: jsonObj.position,
        condition: condition,
        thenBlock: thenBlock,
        conditionDescription: jsonObj.condition && jsonObj.condition.description ? jsonObj.condition.description : "",
        isComplexCondition: complexCond,
        type: jsonObj.type,
        elements: [],
      };

      var translations = {};
      var selectedLanguageIncluded = false;

      for(var translation in jsonObj.translations){
        if(jsonObj.translations[translation].locale === this.getSelectedLanguage){
          selectedLanguageIncluded = true;
        }
        translations[jsonObj.translations[translation].locale] = jsonObj.translations[translation].text;
      }

      if(!selectedLanguageIncluded){
        translations[this.getSelectedLanguage] = "";
      }

      return { propsObj: propsObj, translations: translations }
    },

    getNewElementProperties: function(elementtype, pos, language1, language2){
      var element = {
        propsObj: {
          id: pos,
        },
        type: elementtype
      };

      if(elementtype != "ElementsLine" && elementtype != "ElementsSpace"){ 
        //translations with single attribute already added (headline, text, table, block, page)
        element.translations = {
          [language1]: ""
        };

        if(language2 != ""){
          element.translations[language2] = "";
        }
      }

      switch(elementtype){
        case "ElementsSpace":
          element.propsObj.size = 1;
        break;

        case "ElementsPage":
          element.propsObj.color = "";
          element.propsObj.condition = "";
          element.propsObj.isComplexCondition = false;
          element.propsObj.conditionDescription = "";
        break;

        case "ElementsHeadline":
          element.propsObj.selectedHeadlineProp = "headline0";
          element.propsObj.color = "#000000";
        break;

        case "ElementsText":
          element.propsObj.type = "none";
        break;

        case "ElementsTable":
          element.propsObj.type = "table";
        break;

        case "ElementsMedia":
          element.propsObj.linkType = "";
          element.propsObj.width = 100;
          element.propsObj.height = 100;
          element.propsObj.banner = false;
          element.propsObj.isAudio = false;
          element.translations[language1] = {
            uri: "",
            filename: "",
            subtitle: "",
            description: ""
          };
          if(language2 != ""){
            element.translations[language2] = {
              uri: "",
              filename: "",
              subtitle: "",
              description: ""
            };
          }
        break;

        case "ElementsQuestion":
          element.propsObj.labelProp = "";
          element.propsObj.selectedQuestionProp = "YesNoSwitch";
          element.propsObj.values = [true,false];
          element.propsObj.questionReq = true;

          element.translations[language1] = {
            question: "",
            answers: language1 === "de" ? ["JA","NEIN"] : ["YES","NO"]
          };

          if(language2 != ""){
            element.translations[language2] = {
              question: "",
              answers: language1 === "de" ? ["JA","NEIN"] : ["YES","NO"]
            };
          }
        break;

        case "ElementsBlock":
          element.propsObj.type = "none";
          element.propsObj.condition = "";
          element.propsObj.isComplexCondition = false;
          element.propsObj.thenBlock = { repeat: { question: false, data: 1 } };
          element.propsObj.conditionDescription = "";
          element.propsObj.elements = [];
        break;

        default:
        break;
      }
      return element
    },
  }
};
