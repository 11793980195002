<template>
  <div class="interventionEditorDetails">
    <section :style="{visibility: showIfNotTranslation}">
      <label>
        {{ 'interventionTranslation.name' | translate }} *
      </label>
      <input type="text" :value="interventionDetails.name" id="inputName" :placeholder="$t('interventionTranslation.name')"
        :disabled="!getIsEditable" @input="$emit('change-attribute', {'name': 'name', 'value': $event.target.value})">
    </section>

    <label>
      {{titleTextInLanguage}} *
    </label>
    <input type="text" :value="translation.title" :id="'inputTitle' + (isTranslation ? 'Translation' : '')" :placeholder="titleTextInLanguage" :disabled="!getIsEditable"
      @input="$emit('change-attribute', {'name': 'title', 'isTranslation': isTranslation, 'value': $event.target.value})">

    <label>
      {{descriptionTextInLanguage}} *
    </label>
    <textarea :value="translation.description" :id="'inputDescription' + (isTranslation ? 'Translation' : '')" :placeholder="descriptionTextInLanguage" :disabled="!getIsEditable"
      @input="$emit('change-attribute', {'name': 'description', 'isTranslation': isTranslation, 'value': $event.target.value})"/>

    <section v-if="!isTranslation" class="wrapper media">
      <section class="col sec50">
        <section class="wrapper">
          <label>
            {{ 'interventionTranslation.image' | translate }}
          </label>
          <br>
          <img v-if="interventionPictureLink != ''" :src="interventionPictureLink" class="buttonForm">
          <i class="far fa-image fa-2x" v-else></i>
        </section>
        <br>
        <button type="button" v-if="getIsEditable" id="buttonSelectPicture" @click="getStudyMedia">
          {{ 'elementsMediaTranslation.selectPicture' | translate }}
        </button>
      </section>
      <section class="col sec50">
      </section>
    </section>

    <section class="wrapper" v-if="!isTranslation && isDiary">
      <br>
      <label>
        {{ 'lessonTranslation.selectDefaultPageColor' | translate }} *
      </label>
      <br>

      <BaseSelectBackground
        :pageColor="interventionDetails.page_color"
        :studyId="studyId"
        @change-attribute="$emit('change-attribute', $event)"
        @show-modal="$emit('show-modal', $event)"
      ></BaseSelectBackground>
    </section>

    <section v-if="$enabledFeatures.includes('jitai') && !isTranslation && isDiary" class="wrapper">
      <label>
        {{ 'interventionTranslation.schedule' | translate }} *
        <i class="far fa-question-circle" data-toggle="tooltip" :title="$tlang(getAppLanguage, 'interventionTranslation.scheduleHint')"></i>
      </label>
      <input type="number" v-model="scheduleNumber" id="inputScheduleNumber" min="0" :disabled="!getIsEditable">
      {{ 'interventionTranslation.perWeek' | translate }}
      <div v-if="scheduleNumber > 0">
        <section v-for="(number, index) in parseInt(scheduleNumber)" :key="number">
          <select :value="interventionDetails.schedule.every[number - 1].weekday" :id="'inputSchedule' + index" :disabled="!getIsEditable"
              @input="$emit('change-attribute',{'name': 'schedule-day', 'value': $event.target.value, 'index': number - 1})">
            <option value="" :id="'inputSchedule' + index + 'None'" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option v-for="day in weekdays" :id="'inputSchedule' + index + 'Value' + day.value" :key="day.value" :value="day.value">
              {{day.text}}
            </option>
          </select>
          <flatPickr :value="interventionDetails.schedule.every[number - 1].hour + ':' + interventionDetails.schedule.every[number - 1].minute" :config="config"
            :id="'inputScheduleDate' + (number - 1)" :placeholder="$tlang(getAppLanguage, 'generalTranslation.select')"
          ></flatPickr>
        </section>
      </div>
    </section>

    <section v-if="!isTranslation && !isDiary" class="wrapper">
      <label>
        {{ 'interventionTranslation.interventionGuided' | translate }} *
      </label>
      <select :value="interventionDetails.intervention_type" id="inputGuided" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'intervention_type', 'value': $event.target.value})">
        <option value="" id="inputGuidedNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option value="accompanied" id="inputGuidedTrue">
          {{ 'interventionTranslation.guided' | translate }}
        </option>
        <option value="unaccompanied" id="inputGuidedFalse">
          {{ 'interventionTranslation.unguided' | translate }}
        </option>
      </select>
    </section>

    <section v-if="!isTranslation" class="wrapper">
      <label>
        {{ 'interventionTranslation.smallLayout' | translate }} *
      </label>
      <select :value="interventionDetails.small_layout" id="inputSmallLayout" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'small_layout', 'value': $event.target.value})">
        <option value="" id="inputSmallLayoutNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option value="0" id="inputSmallLayoutFalse">
          {{ 'generalTranslation.no' | translate }}
        </option>
        <option value="1" id="inputSmallLayoutTrue">
          {{ 'generalTranslation.yes' | translate }}
        </option>
      </select>
    </section>

    <section v-if="!isTranslation">
      <section class="col sec50">
        <br>
        <label>
          {{textLanguages}} *
        </label>
        <ul>
          <li v-for="(languageOption, index) in interventionDetails.locales" :id="'language' + languageOption" :key="languageOption">
            {{languageOption}}
            <i class="fa fa-trash" v-if="getIsEditable" :id="'buttonDeleteLanguage' + index" data-toggle="tooltip"
              :title="$t('lessonTranslation.removeElement')" @click="deleteLanguage(index)"></i>
          </li>
        </ul>
      </section>

      <section class="col sec50">
        <section v-if="getIsEditable && interventionDetails.locales">
          <br>
          <label>
            {{ 'interventionTranslation.addLanguage' | translate }}
          </label>
          <form class="buttonForm" @submit.prevent="updateLanguages">
            <select v-model="selectedLanguageToAdd" id="inputLanguage" class="addLanguageSelect buttonForm">
              <option value="" id="inputLanguageNone" disabled>
                {{ 'generalTranslation.chooseLanguage' | translate }}
              </option>
              <option v-for="language in getAvailableLocales.filter(language => !interventionDetails.locales.includes(language))" :id="'inputLanguage' + language" :key="language">
                {{language}}
              </option>
            </select>
            <button type="submit" id="buttonAddLanguage" :disabled="selectedLanguageToAdd === ''" class="buttonMiddle2">
              {{ 'interventionTranslation.add' | translate }}
            </button>
          </form>
        </section>
      </section>
    </section>

    <section v-if="!isTranslation && !isDiary && $enabledFeatures.includes('buddy')" class="wrapper">
      <label>
        {{ 'interventionTranslation.useBuddyFeature' | translate }} *
        <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('interventionTranslation.buddyFeatureExplanation')"></i>
      </label>
      <select :value="interventionDetails.buddy_support" id="inputBuddy" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'buddy_support', 'value': $event.target.value})">
        <option value="" id="inputBuddyNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="1" id="inputBuddyTrue">
          {{ 'generalTranslation.yes' | translate }}
        </option>
        <option :value="0" id="inputBuddyFalse" selected>
          {{ 'generalTranslation.no' | translate }}
        </option>
      </select>
    </section>

    <section v-if="$enabledFeatures.includes('progress') && !isTranslation && !isDiary" class="wrapper">
      <label>
        {{ 'interventionTranslation.useProgressBars' | translate }} *
      </label>
      <select :value="interventionDetails.gamification ? interventionDetails.gamification.progressbars_enabled : 0" id="inputProgress" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'progressbars_enabled', 'value': $event.target.value})">
        <option value="" id="inputProgressNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="1" id="inputProgressTrue">
          {{ 'generalTranslation.yes' | translate }}
        </option>
        <option :value="0" id="inputProgressFalse" selected>
          {{ 'generalTranslation.no' | translate }}
        </option>
      </select>
    </section>

    <!--
      is_private not configurable right now
      is_private is set according to intervention_type -> currently not used for diary
    -->
    <section v-if="!isTranslation && !isDiary && isDiary" class="wrapper">
      <label>
        {{textParticipants}}
      </label>
      <select :value="interventionDetails.is_private" id="inputPrivate" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'is_private', 'value': $event.target.value})">
        <option value="" id="inputPrivateNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="1" id="inputPrivateTrue">
          {{ 'interventionTranslation.addedByEcoach' | translate }}
        </option>
        <option :value="0" id="inputPrivateFalse">
          {{ 'interventionTranslation.addedOrSubscribing' | translate }}
        </option>
      </select>
    </section>

    <!-- only for picture, background in baseSelectBackground component -->
    <StudyMedia
      v-if="studyMediaVisible"
      id="mediaSelection"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="closeModal"
      @item-selected="selectMedia"
    ></StudyMedia>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import BaseSelectBackground from './BaseSelectBackground.vue';
import StudyMedia from './StudyMedia.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/de.js';

export default {
  name: 'InterventionEditorDetails',

  components: {
    BaseSelectBackground,
    StudyMedia,
    flatPickr,
  },

  props: {
    interventionDetails: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    isDiary: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },
  },

  data: function(){
    return {
      selectedLanguageToAdd: '',
      studyMediaVisible: false,
      scheduleNumber: 0,
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
      'getAvailableLocales',
      'getSelectedLanguage',
      'getAppLanguage',
    ]),

    interventionPictureLink: function(){
      return this.interventionDetails.picture ? this.$urlUploads + "/studies/" + this.studyId + "/" + this.interventionDetails.picture : ""
    },

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    titleTextInLanguage: function(){
      return Vue.i18n.translate('interventionTranslation.title' + (this.isTranslation ? 'Translation' : ''))
    },

    descriptionTextInLanguage: function(){
      return Vue.i18n.translate('interventionTranslation.description' + (this.isTranslation ? 'Translation' : ''))
    },

    textLanguages: function(){
      return Vue.i18n.translate((!this.isDiary ? 'interventionTranslation.languagesIntervention' : 'diaryTranslation.languagesDiary'))
    },

    textParticipants: function(){
      return Vue.i18n.translate((!this.isDiary ? 'interventionTranslation.participants' : 'diaryTranslation.participants'))
    },

    config: function(){
      return {
        altFormat: Vue.i18n.translateIn(this.getAppLanguage, 'elementsQuestionTranslation.timeFormat'),
        altInput: true,
        dateFormat: 'H:i',
        enableTime: true,
        noCalendar: true,
        defaultDate: "12:00",
        locale: this.getAppLanguage,
        position: "auto center",
        onClose: (dateobj, datestr, instance) => {
          this.$emit('change-attribute', {'name': 'schedule-time', 'value': datestr, 'index': Number(instance.element.id)});
        }
      }
    },

    weekdays: function(){
      return [
        {
          value: 1,
          text: Vue.i18n.translate('interventionTranslation.monday')
        },
        {
          value: 2,
          text: Vue.i18n.translate('interventionTranslation.tuesday')
        },
        {
          value: 3,
          text: Vue.i18n.translate('interventionTranslation.wednesday')
        },
        {
          value: 4,
          text: Vue.i18n.translate('interventionTranslation.thursday')
        },
        {
          value: 5,
          text: Vue.i18n.translate('interventionTranslation.friday')
        },
        {
          value: 6,
          text: Vue.i18n.translate('interventionTranslation.saturday')
        },
        {
          value: 7,
          text: Vue.i18n.translate('interventionTranslation.sunday')
        },
      ]
    },
  },

  watch: {
    interventionDetails: function(newVal, oldVal){
      if(this.$enabledFeatures.includes('jitai') && oldVal.length === 0 && newVal.schedule && newVal.schedule.every){
        this.scheduleNumber = newVal.schedule.every.length;
      }
    },

    'interventionDetails.intervention_type': function(newVal){
      if(!this.isTranslation && newVal != undefined && newVal != null && newVal != "" && !newVal){
        this.$emit('change-attribute', {'name': 'intervention_type', 'value': "accompanied" });
      }
    },

    scheduleNumber: function(newVal){
      this.$emit('change-attribute', {'name': 'schedule', 'value': newVal });
    },
  },

  created(){
    if(this.$enabledFeatures.includes('jitai') && this.interventionDetails.schedule && this.interventionDetails.schedule.every){
      this.scheduleNumber = this.interventionDetails.schedule.every.length;
    }
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    updateLanguages: function(){
      if(this.selectedLanguageToAdd != ""){
        this.closeNotification();
        var arr = JSON.parse(JSON.stringify(this.interventionDetails.locales));
        arr.push(this.selectedLanguageToAdd);
        this.selectedLanguageToAdd = "";
        this.$emit('change-attribute', {'name': 'locales', 'value': arr});
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.selectLanguageWarning')});
      }
    },

    deleteLanguage: function(index){
      var arr = JSON.parse(JSON.stringify(this.interventionDetails.locales));

      if((arr[index] === this.getSelectedLanguage) || (arr[index] === this.selectedLanguageForTranslation) && this.isTranslationMode){
        this.$emit('show-modal', { type: 'LanguageError' });
      }else{
        arr.splice(index, 1);
        this.$emit('change-attribute', {'name': 'locales', 'value': arr});
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    getStudyMedia: function(){
      this.studyMediaVisible = true;
      this.$emit('show-modal', { type: 'StudyMedia', value: true });
    },

    selectMedia: function(obj){
      var filename = obj.link.split("").reverse().join("");
      filename = filename.substr(0, filename.indexOf('/'));
      filename = filename.split("").reverse().join("");
      this.$emit('change-attribute', {'name': 'picture', 'value': filename });
      this.closeModal();
    },

    closeModal: function(){
      this.studyMediaVisible = false;
      this.$emit('show-modal', { type: 'StudyMedia', value: false });
    },
  }
}
</script>
