<template>
  <div class="elementsReference">
    <section class="wrapper">
      <div
        v-if="getIsEditable"
        :id="textfieldId"
        key="editableSec"
        contenteditable="true"
        :placeholderData="placeholderData"
        :class="['textFieldReference',classToSet]"
        :style="{'color': color}"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input="changeText"
        v-html="textWithHTML"
      ></div>
      <div
        v-else
        :id="textfieldId"
        key="nonEditableSec"
        contenteditable="false"
        :class="['textFieldReferenceNotEditable',classToSet]"
        :style="{'color': color}"
        v-html="textWithHTML"
      ></div>
      <section v-if="getIsEditable && !isSkill" class="col refButton">
        <button type="button" data-toggle="tooltip" :id="'buttonInsertReference' + id" :title="$t('referenceTranslation.insertReference')" @click="showReferenceModal(true)">
          {{buttonText}}
        </button>
      </section>
    </section>

    <BaseModal
      v-if="insertReferenceVisible"
      id="insertReferenceModal"
      :headerText="$t('referenceTranslation.insertReference')"
      :leftButtonText="$t('referenceTranslation.insert')"
      :disableLeftButton="!(insertLabel != '' || insertLabel === 0)"
      @close-modal="closeModal"
    >
      <template v-slot:body>
        {{ 'referenceTranslation.referenceFor' | translate }} *<br>
        <select v-model="insertLabel" :id="'inputReference' + id">
          <option value="" disabled :id="'inputReference' + id + 'None'">
            {{ 'elementsBlockTranslation.selectLabel' | translate }}
          </option>
          <option v-for="(option, index) in getQuestionLabelList.filter(option => option && option != 0)" :value="index" :id="'inputReference' + id + '-' + index" :key="index">
            {{option.label}}
          </option>
        </select>
        <p v-if="errorDE != ''" class="errorMessage" id="errorTextReference">
          {{errorDE}}
        </p>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import BaseModal from './BaseModal.vue';

export default {
  name: 'ElementsReference',

  components: {
    BaseModal,
  },

  props: {
    id: {
      required: true,
      type: Number,
    },

    text: {
      required: true,
      type: String,
    },

    classToSet: {
      required: false,
      type: String,
    },

    color: {
      required: false,
      type: String,
    },

    placeholderData: {
      required: false,
      type: String,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      insertPos: 0,
      newInsertPos: 0,
      isFocused: false,
      textField: '',
      insertLabel: '',
      insertReferenceVisible: false,
      errorDE: "",
      textfieldId: "",
      textForAnswerOf: "",
      buttonText: '{{ }}',
      textWithHTML: "",
    }
  },

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getIsEditable',
    ]),
  },

  watch: {
    text: function(newVal){
      if((newVal != "" && this.newInsertPos === "") || newVal === ""){
        this.textWithHTML = this.insertHTMLinContent(newVal);
      }
    },

    //needs deep:true & handler because array in array changes -> 'normal' watch function does not detect changes in nested array
    getQuestionLabelList: {
      handler: function(){
        if(this.text){
          this.textWithHTML = this.insertHTMLinContent(this.text);
        }
      },
      deep: true
    },
  },

  created(){
    this.textfieldId = "referenceText" + this.id;
    this.textForAnswerOf = Vue.i18n.translate('referenceTranslation.answerOf');
    if(this.text){
      this.textWithHTML = this.insertHTMLinContent(this.text);
    }
  },

  mounted(){
    document.addEventListener("selectionchange", this.getSelection, false);
    document.addEventListener("mouseup", this.getSelection, false);
    document.addEventListener("mousedown", this.getSelection, false);
    document.addEventListener("keyup", this.getSelection, false);
    this.textField = document.getElementById(this.textfieldId);
  },

  beforeDestroy(){
    document.removeEventListener("selectionchange", this.getSelection);
    document.removeEventListener("mouseup", this.getSelection);
    document.removeEventListener("mousedown", this.getSelection);
    document.removeEventListener("keyup", this.getSelection);
  },

  methods: {
    showReferenceModal: function(bool){
      this.insertReferenceVisible = bool;
      this.$emit('change-element', {'type': 'show-media-reference-modal', 'value': bool});
    },

    closeModal: function(done){
      if(done){
        //check if label was selected
        if(this.insertLabel != "" || this.insertLabel === 0){
          this.errorDE = "";
          this.insertAtPos();
        }else{
          this.errorDE = Vue.i18n.translate('elementsBlockTranslation.errorLabel');
        }
      }else{
        this.showReferenceModal(false)
      }
    },

    //replace {{answerPos}} with <cite contenteditable='false'>{{Antwort von answerLabel}}</cite>
    insertHTMLinContent: function(content){
      content = content.replace(/</g, '&lt;');
      content = content.replace(/>/g,'&gt;');

      var self = this;
      //first replace gets {{number}} as x -> brackets are replaced in second replace -> only number -> create html
      content = content.replace(/\{{2}([^}{]+)}{2}/g, function(x){
        var number = Number(x.replace(/{/g, "").replace(/}/g, ""));
        if(Number.isNaN(number)){
          return ""
        }else if(self.getQuestionLabelList[number] != 0){
          var label = self.getQuestionLabelList[number].label;
          return "<cite contenteditable='false'>{{" + self.textForAnswerOf + " " + label + "}}</cite>"
        }else{
          var textContent = self.textField.textContent;
          textContent = textContent.replace(/</g, '&lt;');
          textContent = textContent.replace(/>/g,'&gt;');
          self.$emit('change-text', self.changeFormatOfReferences(textContent));
          return ""
        }
      });
      return content
    },

    //replace {{Antwort von answerLabel}} with {{answerPos}}
    changeFormatOfReferences: function(content){
      var self = this;
      //first replace gets {{Antwort von label}} as x -> brackets & Antwort von are replaced in second replace -> only label -> get index of this label from getQuestionLabelList
      var regEx = new RegExp("\\{{2}(" + this.textForAnswerOf + " )([^}{]+)}{2}", 'g');
      var regEx2 = new RegExp("\\{{2}(" + this.textForAnswerOf + " )", 'g');
      // /\{{2}(Antwort von )([^}{]+)}{2}/g
      // /\{{2}(Antwort von )/g
      content = content.replace(regEx, function(x){
        var label = x.replace(regEx2, "").replace(/}{2}/g, "");
        var index = Number(self.getQuestionLabelList.findIndex(elem => elem && elem.label === label));
        return (index != -1) ? "{{" + Number(index) + "}}" : ""
      });

      return content

    },

    insertAtPos: function(){
      var textContent = this.textField.textContent;
      //option: use this.textField.outerHTML -> instead of getting {{label}} one gets <cite>..</cite>
      //-> simply delete all {{ }} (user can not insert them) instead of changing format from {{}} to html and filtering {{}} with non-valid text inside
      //not working because position can only be determined in non-HTML text

      //insert new reference
      var start = textContent.slice(0, this.insertPos);
      var end = textContent.slice(this.insertPos, textContent.length);

      var labelList = this.getQuestionLabelList.filter(option => option && option != 0);

      if(labelList[this.insertLabel] != 0){
        textContent = start + "{{" + this.textForAnswerOf + " " + labelList[this.insertLabel].label + "}}" + end;
      }else{
        //no label
        textContent = start + end;
      }

      //AFTER inserting delete < & > so that no html tags can be inserted by user (else wrong position)
      textContent = textContent.replace(/</g, '&lt;');
      textContent = textContent.replace(/>/g,'&gt;');
      //delete {{number}} -> can not be inserted manually
      //textContent = textContent.replace(/{{2}\d}{2}/g,'');

      //replace all {{word}} where not (word is Antwort von label & label is in questionLabelList)
      //first replace gets all {{word}}
      //then delete first two and last two chars: {{word}} -> word
      var self = this;
      textContent = textContent.replace(/{{2}([^{}]|[^{{])*}{2}/g, function(x){
        var content = x.slice(2).split("").reverse().join("").slice(2).split("").reverse().join("");
        //if not Antwort von label -> return word without {{ }}
        //else check if valid label
        if(!content.startsWith(self.textForAnswerOf)){
          //delete }} inside word {{a{{b}} {{c}}d}}: {{ab c}}d -> which results in new {{word}}
          return content.replace(/{{2}/g, "").replace(/}{2}/g, "");
        }else{
          var label = content.replace(self.textForAnswerOf + " ", "");
          var index = labelList.findIndex(elem => elem && elem.label === label);
          //if not valid label -> return word without {{ }}
          //(delete }} inside word {{a{{b}} {{c}}d}}: {{ab c}}d -> which results in new {{word}})
          //else return
          return (index === -1) ? content.replace(/{{2}/g, "").replace(/}{2}/g, "") : x
        }
      });

      var newTextWithRef = this.changeFormatOfReferences(textContent);
      newTextWithRef = newTextWithRef.replace(/</g, '&lt;');
      newTextWithRef = newTextWithRef.replace(/>/g,'&gt;');
      this.$emit('change-text', newTextWithRef);
      this.textWithHTML = this.insertHTMLinContent(newTextWithRef);
      this.showReferenceModal(false);
    },

    //https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container/4812022#4812022
    getSelectionCharacterOffsetWithin: function(element){
      var start = 0;
      var end = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      if(typeof win.getSelection != "undefined"){
        sel = win.getSelection();
        if(sel.rangeCount > 0){
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.startContainer, range.startOffset);
          start = preCaretRange.toString().length;
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          end = preCaretRange.toString().length;
        }
      }else if((sel = doc.selection) && sel.type != "Control"){
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToStart", textRange);
        start = preCaretTextRange.text.length;
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        end = preCaretTextRange.text.length;
      }
      return { start: start, end: end };
    },

    getSelection: function(){
      var selOffsets = this.getSelectionCharacterOffsetWithin(this.textField);
      if(this.isFocused){
        this.insertPos = this.newInsertPos;
        this.newInsertPos = selOffsets.start;
      }
    },

    changeText: function(){
      var textContent = this.textField.textContent;
      textContent = textContent.replace(/</g, '&lt;');
      textContent = textContent.replace(/>/g,'&gt;');
      this.$emit('change-text', this.changeFormatOfReferences(textContent));
    }
  },
}
</script>
