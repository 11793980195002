<template>
  <div class="elementsTextToolbar toolbar ql-toolbar ql-snow" :class="{wrapper: !isTranslationMode}">
    <div :class="{wrapper: isTranslationMode}">
      <select class="ql-font" data-toggle="tooltip" data-placement="bottom" :title="$t('elementsTextTranslation.font')">
      </select>

      <select class="ql-header" data-toggle="tooltip" data-placement="bottom" :title="$t('elementsTextTranslation.header')">
        <option selected></option>
        <option v-for="n in 6" :value="n" :key="n"></option>
      </select>

      <button type="button" v-for="toolbarButton in toolbarButtonsTop" :key="toolbarButton.name" :class="toolbarButton.name"
        data-toggle="tooltip" data-placement="bottom" :title="toolbarButton.title"
      ></button>

      <button type="button" v-for="toolbarButton in toolbarButtonsAlign" :value="toolbarButton.value" :key="toolbarButton.name + toolbarButton.value"
        :class="toolbarButton.name" data-toggle="tooltip" data-placement="bottom" :title="toolbarButton.title"
      ></button>
    </div>

    <div :class="{wrapper: isTranslationMode}">
      <button type="button" v-for="toolbarButton in toolbarButtonsBottom" :value="toolbarButton.value" :key="toolbarButton.name + toolbarButton.value"
        :class="toolbarButton.name" data-toggle="tooltip" data-placement="bottom" :title="toolbarButton.title"
      ></button>

      <select class="ql-color" data-toggle="tooltip" data-placement="bottom"
        :title="$t('elementsTextTranslation.color')">
      </select>

      <select class="ql-background" data-toggle="tooltip" data-placement="bottom"
        :title="$t('elementsTextTranslation.background')">
      </select>

      <button type="button" class="ql-blockquote" data-toggle="tooltip" data-placement="bottom"
        :title="$t('elementsTextTranslation.blockquote')"></button>

      <button type="button" class="ql-link" data-toggle="tooltip" data-placement="bottom"
        :title="$t('elementsTextTranslation.link')"></button>

      <button type="button" class="fas fa-image" :id="'buttonInsertImage' + id + (isTranslationMode ? 'Translation' : '')" data-toggle="tooltip" data-placement="bottom"
        :title="$t('elementsTextTranslation.image')" @click="$emit('show-modal', {type: 'Insert', isRef: false})"></button>

      <button type="button" class="ql-clean" data-toggle="tooltip" data-placement="bottom"
        :title="$t('elementsTextTranslation.clean')"></button>

      <button type="button" v-if="!isSkill" :id="'buttonInsertReference' + id + (isTranslationMode ? 'Translation' : '')" data-toggle="tooltip" data-placement="bottom"
          :title="$t('elementsTextTranslation.reference')" class="textEditorButton" @click="$emit('show-modal', {type: 'Insert', isRef: true})">
        {{buttonText}}
      </button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
  name: 'ElementsTextToolbar',

  props: {
    id: {
      required: true,
      type: Number,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    toolbarButtonsTop: function(){
      return [
        {
          name: "ql-bold",
          title: Vue.i18n.translate('elementsTextTranslation.bold')
        },
        {
          name: "ql-italic",
          title: Vue.i18n.translate('elementsTextTranslation.italic')
        },
        {
          name: "ql-underline",
          title: Vue.i18n.translate('elementsTextTranslation.underline')
        },
        {
          name: "ql-strike",
          title: Vue.i18n.translate('elementsTextTranslation.strike')
        },
      ]
    },

    toolbarButtonsAlign: function(){
      return [
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.align'),
          value: ""
        },
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.alignCenter'),
          value: "center"
        },
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.alignRight'),
          value: "right"
        },
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.alignJustify'),
          value: "justify"
        },
      ]
    },

    toolbarButtonsBottom: function(){
      return [
        {
          name: "ql-list",
          title: Vue.i18n.translate('elementsTextTranslation.listOrdered'),
          value: "ordered"
        },
        {
          name: "ql-list",
          title: Vue.i18n.translate('elementsTextTranslation.listBullet'),
          value: "bullet"
        },
        {
          name: "ql-script",
          title: Vue.i18n.translate('elementsTextTranslation.scriptSub'),
          value: "sub"
        },
        {
          name: "ql-script",
          title: Vue.i18n.translate('elementsTextTranslation.scriptSuper'),
          value: "super"
        },
        {
          name: "ql-indent",
          title: Vue.i18n.translate('elementsTextTranslation.indentMinus'),
          value: "-1"
        },
        {
          name: "ql-indent",
          title: Vue.i18n.translate('elementsTextTranslation.indentPlus'),
          value: "+1"
        },
      ]
    },
  },

  data: function(){
    return {
      buttonText: '{{}}',
    }
  },
}
</script>
