<template>
  <BaseModal
    id="interventionConfigModal"
    class="interventionEditorConfig pageModal"
    :headerText="$t('interventionTranslation.changeConfig')"
    :leftButtonText="$t('generalTranslation.save')"
    :disableButtons="disableConfigBool"
    :disableLeftButton="!isOwner"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <div class="wrapper diaryInt">
        <div v-if="!loadingConfig" :id="idInterventionType" class="col secL">
          <p v-if="isCollaborator" class="wrapper">
            {{ 'interventionTranslation.editableForOwner' | translate }}
          </p>
          {{interventionTypeText}}
        </div>
        <div v-if="$enabledFeatures.includes('jitai') && hasConditionalLesson" class="col secR">
          <button type="button" id="buttonCheckConfig" class="buttonForm" :disabled="!isOwner || disableConfigBool" @click="checkConfigConditions">
            {{ 'interventionTranslation.checkConfig' | translate }}
          </button>
        </div>
      </div>

      <ul v-if="!loadingConfig" class="configClass wrapper">
        <div class="wrapper">
          <div class="col sec50">
            <h3>
              {{ 'interventionTranslation.unlockDiariesIntervention' | translate }}<br>
              {{ 'interventionTranslation.unlockDiariesInterventionExplanation' | translate }}
            </h3>
            <section class="wrapper diaryInt">
              <section class="checkboxSection notBold col sectionLeft4">
              </section>
              <section class="col configElement">
                <ul v-if="diariesArr.length > 0" id="diariesIntervention">
                  <li v-for="(diaryId, diaryIndex) in unlockDiaryList.int" :id="'diariesIntervention' + diaryId" :key="diaryId">
                    {{getNameOfDiary(diaryId)}}
                    <i class="fa fa-trash" v-if="isOwner" :id="'buttonRemoveDiaryInt' + diaryId" data-toggle="tooltip" :title="$t('lessonTranslation.removeElement')"
                      @click="changeConfig({ attribute: 'unlock-diary-int-delete', index: diaryIndex })"></i>
                  </li>
                </ul>
                <section v-if="unlockDiaryList.int.length === 0" id="diariesInterventionNone">
                  {{ 'interventionTranslation.unlockDiariesEmpty' | translate }}
                </section>
                <br>
                <label>
                  {{ 'interventionTranslation.unlockDiariesAdd' | translate }}
                </label>
                <select v-if="getSelectableDiariesIntervention.length > 0" v-model="selectedDiaryInt" id="inputAddDiaryIntervention" class="addLanguageSelect buttonForm" :disabled="!isOwner">
                  <option value="" id="inputAddDiaryInterventionNone" disabled selected>
                    {{ 'interventionTranslation.chooseDiary' | translate }}
                  </option>
                  <option v-for="diary in getSelectableDiariesIntervention" :value="Number(diary.id)" :id="'inputAddDiaryIntervention' + diary.id" :key="diary.id">
                    {{diary.attributes.name}}
                  </option>
                </select>
                <p v-else id="addDiaryInterventionNone">
                  {{noDiariesText}}
                </p>
                <button type="button" v-if="getSelectableDiariesIntervention.length > 0" id="buttonAddDiaryIntervention" :disabled="!isOwner || selectedDiaryInt === ''" class="buttonMiddle2"
                    @click="changeConfig({ attribute: 'unlock-diary-int-set' })">
                  {{ 'interventionTranslation.add' | translate }}
                </button>
              </section>
            </section>
          </div>
          <div v-if="$enabledFeatures.includes('jitai') && hasConditionalLesson" class="col sec50">
            <h3>
              {{ 'interventionTranslation.unlockLessons' | translate }}
            </h3>
            <section class="wrapper diaryInt">
              <section class="checkboxSection notBold col sectionLeft4">
              </section>
              <section class="col configElement">
                <select v-model="unlockMultipleLessons" id="inputUnlockMultiple" class="addLanguageSelect buttonForm" :disabled="!isOwner">
                  <option value="" id="inputUnlockMultipleNone" disabled selected>
                    {{ 'generalTranslation.choose' | translate }}
                  </option>
                  <option :value="1" id="inputUnlockMultipleTrue" key="multiple">
                    {{ 'interventionTranslation.unlockLessonsMultiple' | translate }}
                  </option>
                  <option :value="0" id="inputUnlockMultipleFalse" key="one">
                    {{ 'interventionTranslation.unlockLessonsOne' | translate }}
                  </option>
                </select>
              </section>
            </section>
          </div>
        </div>

        <!-- in custom order -->
        <InterventionEditorConfigElement
          :showIncluded="true"
          :configJSON="configJSON"
          :lessonsDiaries="lessonsDiaries"
          :customOrder="customOrder"
          :unlockDiaryList="unlockDiaryList"
          :daysOrWeeks="daysOrWeeks"
          :interventionType="interventionDetails.intervention_type"
          :errorIndexes="errorIndexes"
          :lessonElementsLabels="lessonElementsLabels"
          @change-config="changeConfig"
          @open-condition-editor="openConditionEditor"
        ></InterventionEditorConfigElement>

        <!-- not in custom order -->
        <InterventionEditorConfigElement
          :showIncluded="false"
          :configJSON="configJSON"
          :lessonsDiaries="lessonsDiaries"
          :customOrder="customOrder"
          :unlockDiaryList="unlockDiaryList"
          :daysOrWeeks="daysOrWeeks"
          :interventionType="interventionDetails.intervention_type"
          :errorIndexes="errorIndexes"
          :lessonElementsLabels="lessonElementsLabels"
          @change-config="changeConfig"
          @open-condition-editor="openConditionEditor"
        ></InterventionEditorConfigElement>
      </ul>
      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import questionConditionHelper from '../mixins/questionConditionHelper';
import BaseModal from './BaseModal.vue';
import InterventionEditorConfigElement from './InterventionEditorConfigElement.vue';

export default {
  name: 'InterventionEditorConfig',

  components: {
    BaseModal,
    InterventionEditorConfigElement,
  },

  mixins: [httpHelper, questionConditionHelper],

  props: {
    allLessonsArray: {
      required: true,
      type: Array,
    },

    interventionDetails: {
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      loadingConfig: true,
      disableConfigBool: false,
      diariesArr: [],
      configJSON: [],
      oldData: {},
      customOrder: [],
      unlockDiaryList: {},
      daysOrWeeks: [],
      errorIndexes: [],
      lessonElementsLabels: {},
      selectedDiaryInt: "",
      unlockMultipleLessons: 1,
    }
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
      'getNotificationText',
      'getConditionEditorData',
      'getQuestionLabelList'
    ]),

    interventionId: function(){
      return Number(this.$route.params.interventionId)
    },

    isOwner: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    isCollaborator: function(){
      return (this.getMyRoleForStudy === "Collaborator")
    },

    getSelectableDiariesIntervention: function(){
      return this.diariesArr.filter(diary => !this.unlockDiaryList.int.includes(Number(diary.id))).filter(diary => !this.unlockDiaryList.int.includes(diary.id))
    },

    idInterventionType: function(){
      return (this.interventionDetails.intervention_type === "accompanied") ? "interventionGuided" : "interventionNotGuided"
    },

    interventionTypeText: function(){
      if(this.interventionDetails.intervention_type === "accompanied"){
        return Vue.i18n.translate('interventionTranslation.interventionGuidedSelected')
      }else{
        return Vue.i18n.translate('interventionTranslation.interventionUnguidedSelected')
      }
    },

    allSelectedDiaries: function(){
      var diaries = [];
      for(var diary in this.unlockDiaryList){
        for(var entry in this.unlockDiaryList[diary]){
          if(!diaries.includes(Number(this.unlockDiaryList[diary][entry]))){
            diaries.push(Number(this.unlockDiaryList[diary][entry]))
          }
        }
      }
      return diaries
    },

    configChanged: function(){
      return (JSON.stringify(this.configJSON) != JSON.stringify(this.oldData.configJSON) ||
        JSON.stringify(this.customOrder) != JSON.stringify(this.oldData.customOrder) ||
        JSON.stringify(this.unlockDiaryList) != JSON.stringify(this.oldData.unlockDiaryList) ||
        this.unlockMultipleLessons != this.oldData.unlockMultipleLessons)
    },

    lessonsDiaries: function(){
      var arr = this.allLessonsArray.concat(this.diariesArr);
      var newArr = [];
      for(var elem in arr){
        if(arr[elem].type === "diary"){
          arr[elem].attributes.diaryId = arr[elem].id;
          arr[elem].attributes.id = arr[elem].attributes.questionnaire_id;
        }else{
          arr[elem].attributes.id = arr[elem].id;
        }
        newArr.push(arr[elem].attributes);
      }
      return newArr
    },

    noDiariesText: function(){
      return (!this.unlockDiaryList.int || this.unlockDiaryList.int.length === 0) ? Vue.i18n.translate('interventionTranslation.noDiariesInStudy') : Vue.i18n.translate('interventionTranslation.noMoreDiariesInStudy')
    },

    hasConditionalLesson: function(){
      for(var config in this.configJSON){
        if(this.configJSON[config].unlock_type === "conditional"){
          return true
        }
      }
      return false
    },
  },

  watch:{
    getNotificationText(newVal){
      if((newVal != "" && newVal.type === "error") || (newVal != "" && newVal.type === "success" &&
          newVal.text === Vue.i18n.translate('interventionTranslation.updateInterventionConfigSuccess'))){
        this.disableConfigBool = false;
      }
    },
  },

  created(){
    document.body.style.overflow = 'hidden';
    if(this.getConditionEditorData === "" || !this.$enabledFeatures.includes('jitai')){
      this.getNewDiaries(this.interventionDetails.study_id, 0, "");
      this.SET_CONDITIONEDITORDATA("");
    }else{
      this.configJSON = this.getConditionEditorData.configJSON;
      this.customOrder = this.getConditionEditorData.customOrder;
      this.unlockDiaryList = this.getConditionEditorData.unlockDiaryList;
      this.oldData = this.getConditionEditorData.oldData;
      this.diariesArr = this.getConditionEditorData.diariesArr;
      this.daysOrWeeks = this.getConditionEditorData.daysOrWeeks;
      this.lessonElementsLabels = this.getConditionEditorData.lessonElementsLabels;
      this.unlockMultipleLessons = this.getConditionEditorData.unlockMultipleLessons;

      //new condition has to be set for lesson
      var lessonId = this.getConditionEditorData.pos;
      var ind = this.configJSON.findIndex(elem => Number(elem.id) === Number(lessonId));
      if(ind != -1){
        this.changeConfig({ attribute: 'config-attribute', index: ind, attributeName: "conditionJSON", value: this.getConditionEditorData.condition, conditionIndex: this.getConditionEditorData.conditionIndex });
      }
      this.SET_CONDITIONEDITORDATA("");
      this.loadingConfig = false;
    }
  },

  beforeDestroy(){
    document.body.style.overflow = 'visible';
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_QUESTIONLABELLIST',
      'SET_CONDITIONEDITORDATA',
    ]),

    getNewDiaries: function(studyId, page, filterTerm){
      var self = this;
      this.requestDiariesOfStudy(studyId, page, filterTerm, -1, false)
      .then( function(response){
        self.diariesArr = response.list;
        self.checkAndUpdateConfig();
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewDiaries(studyId, page, filterTerm) }, "");
      });
    },

    checkAndUpdateConfig: function(){
      var unsortedConfig = (this.interventionDetails.default_configuration) ? JSON.parse(JSON.stringify(this.interventionDetails.default_configuration.questionnaire_configuration)) : [];
      var sortedConfig = [];
      this.customOrder = (this.interventionDetails.default_configuration) ? JSON.parse(JSON.stringify(this.interventionDetails.default_configuration.custom_order)) : [];
      this.unlockMultipleLessons = (this.interventionDetails.unlock_multiple_conditionals != undefined && this.interventionDetails.unlock_multiple_conditionals != null) ? this.interventionDetails.unlock_multiple_conditionals : 1;

      //add entries for lessons not included in config
      for(var lesson in this.allLessonsArray){
        var ind = unsortedConfig.findIndex(elem => elem.id === String(this.allLessonsArray[lesson].id) || elem.id === Number(this.allLessonsArray[lesson].id));
        if(ind === -1){
          sortedConfig.push(
            {
              id: this.allLessonsArray[lesson].id,
              unlock_type: "after_previous",
              unlock_days_after_start: null,
              feedback_required: false
            }
          );
        }else{
          sortedConfig.push(unsortedConfig[ind])
        }
      }

      var unlockList = {};
      for(var l in this.allLessonsArray){
        var unlock = this.allLessonsArray[l].attributes.unlock_diaries;
        if(!unlock){
          unlock = [];
        }
        unlockList[this.allLessonsArray[l].id] = unlock.sort(this.sortNumber);
      }

      for(var lessonConfig in sortedConfig){
        var index = this.allLessonsArray.findIndex(elem => elem.id === sortedConfig[lessonConfig].id);

        if(index === 0 && sortedConfig[lessonConfig].unlock_type === "after_previous"){
          sortedConfig[lessonConfig].unlock_type = "always";
        }
        if(sortedConfig[lessonConfig].unlock_type === "at_date"){
          if(sortedConfig[lessonConfig].unlock_days_after_start % 7 === 0){
            sortedConfig[lessonConfig].unlock_days_after_start = sortedConfig[lessonConfig].unlock_days_after_start / 7;
            this.daysOrWeeks[lessonConfig] = false;
          }else{
            this.daysOrWeeks[lessonConfig] = true;
          }
        }else{
          var conditionEmpty = false;
          if(sortedConfig[lessonConfig].unlock_type === "conditional"){
            if(this.$enabledFeatures.includes('jitai')){
              //change from single condition to array
              if(!Array.isArray(sortedConfig[lessonConfig].condition)){
                var singleCondition = JSON.parse(JSON.stringify(sortedConfig[lessonConfig].condition));
                sortedConfig[lessonConfig].condition = [singleCondition];
              }
              var newConditions = [];
              //check if lessons/diaries used in condition are part of this study (may not be the case after copy intervention)
              for(var c in sortedConfig[lessonConfig].condition){
                //set text instead of labellist
                if(!sortedConfig[lessonConfig].condition[c].text){
                  var conditionLabels = sortedConfig[lessonConfig].condition[c].labels;
                  if(conditionLabels){
                    sortedConfig[lessonConfig].condition[c].text = Vue.i18n.translate('interventionTranslation.conditionLesson') + " " +
                      this.treeToString(sortedConfig[lessonConfig].condition[c].ifBlock, conditionLabels, false);
                  }
                }

                //set type of condition (true if condition for question, false if for lesson finished)
                if(sortedConfig[lessonConfig].condition[c].ifBlock === true){
                  sortedConfig[lessonConfig].condition[c].type = false;
                }else{
                  sortedConfig[lessonConfig].condition[c].type = true;
                }

                var id = sortedConfig[lessonConfig].condition[c].diary_id;
                if(id === null){ //lesson
                  id = sortedConfig[lessonConfig].condition[c].questionnaire_id;
                  if(this.allLessonsArray.findIndex(elem => Number(elem.id) === Number(id)) != -1){
                    newConditions.push(sortedConfig[lessonConfig].condition[c]);
                  }
                }else if(this.diariesArr.findIndex(elem => Number(elem.id) === Number(id)) != -1){ //diary
                  newConditions.push(sortedConfig[lessonConfig].condition[c]);
                }
              }

              //check if condition now empty
              if(newConditions.length > 0){
                sortedConfig[lessonConfig].condition = newConditions;
              }else{
                sortedConfig[lessonConfig].condition = null;
                conditionEmpty = true;
              }

              //remove unlock diaries for conditional lessons (which could have been set before changing the unlock type to conditional)
              unlockList[sortedConfig[lessonConfig].id] = [];

              if(sortedConfig[lessonConfig].bonus_lesson === undefined){
                sortedConfig[lessonConfig].bonus_lesson = true;
              }
            }else{
              sortedConfig[lessonConfig].unlock_type = "always";
              delete sortedConfig[lessonConfig]["condition"];
            }
          }

          if((sortedConfig[lessonConfig].unlock_type === "manually" && this.interventionDetails.intervention_type === "unaccompanied") || conditionEmpty){
            sortedConfig[lessonConfig].unlock_type = (index === 0) ? "always" : "after_previous";
          }

          //default values if unlockType changed to atdate
          sortedConfig[lessonConfig].unlock_days_after_start = 1;
          this.daysOrWeeks[lessonConfig] = true;
        }

        if(this.interventionDetails.intervention_type === "unaccompanied"){
          sortedConfig[lessonConfig].feedback_required = false;
        }
      }

      this.configJSON = sortedConfig;

      unlockList.int = (this.interventionDetails.unlock_diaries) ? JSON.parse(JSON.stringify(this.interventionDetails.unlock_diaries)) : [];
      this.unlockDiaryList = unlockList;
      this.oldData = {
        configJSON: JSON.parse(JSON.stringify(this.configJSON)),
        customOrder: JSON.parse(JSON.stringify(this.customOrder)),
        unlockDiaryList: JSON.parse(JSON.stringify(this.unlockDiaryList)),
        unlockMultipleLessons: this.unlockMultipleLessons
      };
      this.loadingConfig = false;
    },

    changeConfig: function(obj){
      var lesson;
      switch (obj.attribute) {
        case "config-attribute":
          this.setConfigAttributeOfLesson(obj);
        break;

        case "days-or-weeks":
          this.daysOrWeeks[obj.index] = (obj.value == true || obj.value === "true" ? true : false);
        break;

        case "unlock-type-all":
          for(lesson in this.configJSON){
            this.configJSON[lesson].unlock_type = obj.unlockType;
            if(obj.unlockType === "at_date"){
              this.configJSON[lesson].unlock_days_after_start = obj.days;
            }
          }
          if(obj.unlockType === "at_date"){
            this.daysOrWeeks.fill(obj.daysOrWeeks);
          }
        break;

        case "feedback-all":
          for(lesson in this.configJSON){
            this.configJSON[lesson].feedback_required = obj.value;
          }
        break;

        case "unlock-diary-set":
          this.unlockDiaryList[obj.lessonId] = JSON.parse(JSON.stringify(obj.value.sort(this.sortNumber)));
        break;

        case "unlock-diary-delete":
          this.unlockDiaryList[obj.lessonId].splice(obj.diaryIndex, 1);
        break;

        case "unlock-diary-int-set":
          if(this.selectedDiaryInt != ""){
            var newList = this.unlockDiaryList.int;
            newList.push(this.selectedDiaryInt);
            this.unlockDiaryList.int = JSON.parse(JSON.stringify(newList.sort(this.sortNumber)));
            this.selectedDiaryInt = "";
          }
        break;

        case "unlock-diary-int-delete":
          this.unlockDiaryList.int.splice(obj.index, 1);
        break;

        case "custom-order":
          if(obj.value){ //add
            this.customOrder.push(obj.lessonId);
          }else{ //delete
            var index = this.customOrder.indexOf(obj.lessonId);
            if(index != -1){
              this.customOrder.splice(index, 1);
            }
          }
        break;

        case "custom-order-add-all":
          var newCustomOrder = [];
          for(lesson in this.allLessonsArray){
            newCustomOrder.push(Number(this.allLessonsArray[lesson].id));
          }
          this.customOrder = newCustomOrder;
        break;

        case "custom-order-delete-all":
          this.customOrder = [];
        break;

        default:
        break;

      }
    },

    setConfigAttributeOfLesson: function(obj){
      if(!obj.attributeName.includes("condition") && !obj.attributeName.includes("Condition")){
        this.configJSON[obj.index][obj.attributeName] = obj.value;

        if(obj.attributeName === "unlock_type" && !this.configJSON[obj.index].condition && this.$enabledFeatures.includes('jitai')){
          this.configJSON[obj.index].condition = [ { questionnaire_id: "", diary_id: null, ifBlock: null, type: true, text: "", description: "" } ];
        }

        if(obj.attributeName === "unlock_type" && obj.value === "conditional"){
          if(this.$enabledFeatures.includes('jitai')){
            //remove unlock diaries
            this.unlockDiaryList[this.configJSON[obj.index].id] = [];
          }else{
            this.configJSON[obj.index][obj.attributeName] = "always";
          }
        }
      }else{
        if(!this.configJSON[obj.index].condition){
          this.configJSON[obj.index].condition = [ { questionnaire_id: "", diary_id: null, ifBlock: null, type: true, text: "", description: "" } ];
        }

        switch (obj.attributeName) {
          case "conditionId":
            var index = this.diariesArr.findIndex(diary => Number(diary.attributes.questionnaire_id) === Number(obj.value));
            var oldCondition = this.configJSON[obj.index].condition[obj.conditionIndex];
            var newCondition = { questionnaire_id: obj.value, diary_id: ((index != -1) ? this.diariesArr[index].id : null), ifBlock: !oldCondition.type ? true : null,
              type: oldCondition.type, text: "", description: oldCondition.description };
            this.configJSON[obj.index].condition[obj.conditionIndex] = newCondition;
            this.configJSON = JSON.parse(JSON.stringify(this.configJSON)); //reinit nested (to update questionnaire_id)
          break;

          case "conditionJSON":
            this.configJSON[obj.index].condition[obj.conditionIndex].ifBlock = obj.value;
            var text = Vue.i18n.translate('interventionTranslation.conditionLesson') + " " + this.treeToString(obj.value, this.getQuestionLabelList, false);
            this.configJSON[obj.index].condition[obj.conditionIndex].text = text;
          break;

          case "conditionDesc":
            this.configJSON[obj.index].condition[obj.conditionIndex].description = obj.value;
          break;

          case "addCondition":
            this.configJSON[obj.index].condition.push({ questionnaire_id: "", diary_id: null, ifBlock: null, type: true, text: "", description: "" });
          break;

          case "removeCondition":
            this.configJSON[obj.index].condition.splice(obj.conditionIndex, 1);
            this.configJSON = JSON.parse(JSON.stringify(this.configJSON)); //reinit nested
          break;

          case "conditionType":
            this.configJSON[obj.index].condition[obj.conditionIndex].type = (obj.value === true || obj.value === "true") ? true : false;
            if(obj.value === true || obj.value === "true"){ //question
              this.configJSON[obj.index].condition[obj.conditionIndex].ifBlock = null;
            }else{
              this.configJSON[obj.index].condition[obj.conditionIndex].ifBlock = true;
            }
            this.configJSON[obj.index].condition[obj.conditionIndex].text = "";
          break;

          default:
          break;
        }
      }
    },

    getNameOfDiary: function(diary){
      var index = this.diariesArr.findIndex(elem => Number(elem.id) === Number(diary));
      return (index != -1) ? this.diariesArr[index].attributes.name : ""
    },

    sortNumber: function(a, b) {
      return a - b;
    },

    checkConfig: function(){
      var hasErrors = false;
      var currentHasErrors = false;
      this.errorIndexes = {};
      for(var lessonConfig in this.configJSON){
        if(this.configJSON[lessonConfig].unlock_type === "conditional"){
          if(this.$enabledFeatures.includes('jitai')){
            var indexes = [];
            for(var c in this.configJSON[lessonConfig].condition){
              if(this.configJSON[lessonConfig].condition[c].type){ //is question (does not have to be checked for lesson finished)
                //if questionnaire id set check if valid -> has to be lesson id or (diary lesson id & this has to be in unlock diaries of any lesson / intervention)
                //if this is not the case, set empty
                if(this.configJSON[lessonConfig].condition[c].questionnaire_id){
                  var lessonIndex = this.allLessonsArray.findIndex(elem => Number(elem.id) === Number(this.configJSON[lessonConfig].condition[c].questionnaire_id));
                  //index != -1 -> is lesson -> valid id
                  //index === 1 -> is diary -> check if in allSelectedDiaries
                  if(lessonIndex === -1){
                    var diaryIndex = this.diariesArr.findIndex(elem => Number(elem.attributes.id) === Number(this.configJSON[lessonConfig].condition[c].questionnaire_id));
                    if(diaryIndex === -1){
                      //diary does not exist
                      this.configJSON[lessonConfig].condition[c].questionnaire_id = "";
                      this.configJSON[lessonConfig].condition[c].ifBlock = null;
                      this.configJSON[lessonConfig].condition[c].type = true;
                    }else{
                      //check if diary selected
                      var diaryId = Number(this.diariesArr[diaryIndex].id);
                      if(!this.allSelectedDiaries.includes(diaryId)){
                        this.configJSON[lessonConfig].condition[c].questionnaire_id = "";
                        this.configJSON[lessonConfig].condition[c].ifBlock = null;
                        this.configJSON[lessonConfig].condition[c].type = true;
                      }
                    }
                  }
                }
              }

              if(!this.configJSON[lessonConfig].condition[c] || !this.configJSON[lessonConfig].condition[c].questionnaire_id ||
                  !this.configJSON[lessonConfig].condition[c].ifBlock){
                indexes.push(Number(c));
                currentHasErrors = true;
              }
              //description empty, only set hasError, don't save index
              if(!this.configJSON[lessonConfig].condition[c].description || this.configJSON[lessonConfig].condition[c].description === ''){
                currentHasErrors = true;
              }
            }
            if(currentHasErrors){
              this.errorIndexes[this.configJSON[lessonConfig].id] = indexes;
              currentHasErrors = false;
              hasErrors = true;
            }
          }else{
            this.configJSON[lessonConfig].unlock_type = "always";
          }
        }
      }

      if(!hasErrors){
        this.saveConfig();
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.errorConditionsIncomplete')});
      }
    },

    saveConfig: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updateInterventionConfigLoad')});
      this.disableConfigBool = true;
      for(var lessonConfig in this.configJSON){
        if(this.configJSON[lessonConfig].unlock_type === "at_date"){
          if(!this.daysOrWeeks[lessonConfig]){
            this.configJSON[lessonConfig].unlock_days_after_start = this.configJSON[lessonConfig].unlock_days_after_start * 7;
          }
        }else{
          this.configJSON[lessonConfig].unlock_days_after_start = null;
        }

        if(this.$enabledFeatures.includes('jitai')){
          if(this.configJSON[lessonConfig].unlock_type != "conditional"){
            this.configJSON[lessonConfig].condition = null;
          }else{
            //remove unlock diaries for conditional lessons (which could have been set before changing the unlock type to conditional)
            this.unlockDiaryList[this.configJSON[lessonConfig].id] = [];

            //set condition to true if condition for lesson finished, remove type
            for(var c in this.configJSON[lessonConfig].condition){
              if(!this.configJSON[lessonConfig].condition[c].type){
                this.configJSON[lessonConfig].condition[c].ifBlock = true;
              }
              delete this.configJSON[lessonConfig].condition[c]["type"]
            }
          }
        }else{
          delete this.configJSON[lessonConfig]["conditon"];
        }
      }

      var unlockDiariesChanged = false;
      if(JSON.stringify(this.unlockDiaryList) != JSON.stringify(this.oldData.unlockDiaryList)){
        unlockDiariesChanged = true;
      }
      var newUnlockData = [];

      //remove ids of deleted lessons from custom_order
      var customOrder = this.customOrder;
      var newCustomOrder = [];

      var allLessonIds = [];

      for(var lesson in this.allLessonsArray){
        var lessonId = this.allLessonsArray[lesson].id;
        if(customOrder.includes(Number(lessonId)) || customOrder.includes(lessonId)){
          newCustomOrder.push(Number(lessonId));
        }
        allLessonIds.push(Number(lessonId));

        if(unlockDiariesChanged && JSON.stringify(this.unlockDiaryList[lessonId]) != JSON.stringify(this.oldData.unlockDiaryList[lessonId])){
          newUnlockData.push(
            {
              "questionnaire_id": lessonId,
              "unlock_diaries": this.unlockDiaryList[lessonId]
            }
          );
        }
      }

      //remove config of deleted questionnaires and duplicate entries from questionnaire_configuration
      var questionnaireConfig = this.configJSON;
      var newQuestionnaireConfig = [];
      var alreadyAdded = [];
      for(var index in questionnaireConfig){
        if(allLessonIds.includes(Number(questionnaireConfig[index].id)) && !alreadyAdded.includes(Number(questionnaireConfig[index].id))){
          newQuestionnaireConfig.push(questionnaireConfig[index]);
          alreadyAdded.push(Number(questionnaireConfig[index].id));
        }
      }

      var json = {
        data: {
          type: "interventions",
          id: this.interventionId,
          attributes: {
            default_configuration: {
              questionnaire_configuration: newQuestionnaireConfig,
              custom_order: newCustomOrder
            },
            unlock_diaries: this.unlockDiaryList.int,
            unlock_multiple_conditionals: this.unlockMultipleLessons
          }
        }
      };

      if(newUnlockData.length > 0){
        var unlockJson = {
          "data": {
            "type": "questionnaires",
            "attributes": {
              "unlock_data": newUnlockData
            }
          }
        }
        this.updateUnlockDiaries(unlockJson, json);
      }else{
        this.$emit('close-modal', { done: true, json: json });
      }
    },

    updateUnlockDiaries: function(unlockJson, json){
      var self = this;
      this.updateUnlockDiariesForLessonsRequest(unlockJson)
      .then( function(){
        self.$emit('close-modal', { done: true, json: json });
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.updateUnlockDiaries(unlockJson, json) }, "");
      });
    },

    closeModal: function(done){
      if(done){
        this.checkConfig();
      }else if(!this.disableConfigBool){
        if(this.isOwner && this.configChanged && (this.getNotificationText === "" || (this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.unsavedChangesConfigLeave') &&
            this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.unsavedChangesConfigLeaveInterventionEditor')))){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.unsavedChangesConfigLeave')});
        }else{
          this.$emit('close-modal', { done: false });
        }
      }
    },

    openConditionEditor: function(obj){
      if(obj.open){
        var self = this;
        this.requestLessonElements(obj.conditionId, false, false)
        .then(function (response){
          var labellist = self.getLabelList(response).list;

          var labellistEmpty = true;
          for(var label in labellist){
            if(labellist[label] != 0 && labellist[label] != undefined){
              labellistEmpty = false;
              break
            }
          }

          if(labellistEmpty){
            self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.errorNoQuestions')});
          }else{
            self.SET_QUESTIONLABELLIST(labellist);

            var data = {
              pos: obj.lessonId, //condition config for this lesson
              condition: obj.conditionJSON, //condition
              conditionId: obj.conditionId, //diary or lesson for which condition is defined (question labels from these elements)
              conditionIndex: obj.conditionIndex, //index of condition for this lesson (lesson may have multiple conditions)
              configJSON: self.configJSON,
              customOrder: self.customOrder,
              unlockMultipleLessons: self.unlockMultipleLessons,
              unlockDiaryList: self.unlockDiaryList,
              oldData: self.oldData, //old values of configJSON, customOrder, unlockDiaryList, unlockMultipleLessons
              diariesArr: self.diariesArr,
              daysOrWeeks: self.daysOrWeeks,
              lessonElementsLabels: self.lessonElementsLabels,
              configChanged: self.configChanged
            };
            self.$emit('open-condition-editor', data);
          }
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.openConditionEditor(obj) }, "studyDetails");
        });
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.errorSelectLessonDiary')});
      }
    },

    checkConfigConditions: async function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.checkConfigLoad')});
      this.loadingConfig = true;
      this.disableConfigBool = true;
      var lessons = [];
      var allConditions = [];
      for(var entry in this.configJSON){
        if(this.configJSON[entry].unlock_type === "conditional"){
          var conditions = this.configJSON[entry].condition;
          for(var c in conditions){
            if(conditions[c].ifBlock != true){ //if it is a condition for lesson finished, it will just be ignored; these conditions are always valid & do not use elements
              allConditions.push(conditions[c]);
              if(!lessons.includes(Number(conditions[c].questionnaire_id))){
                lessons.push(Number(conditions[c].questionnaire_id))
              }
            }
          }
        }
      }

      var lessonElementsLabels = {};
      for(var lesson in lessons){
        let elements = await this.exportLessonElementsRequest(lessons[lesson]);
        var elementProps = this.jsonToProperties(elements, false);
        var labelList = this.getLabelList(elementProps).list;
        lessonElementsLabels[lessons[lesson]] = { elements: elementProps, labelList: labelList };
      }
      this.lessonElementsLabels = lessonElementsLabels;

      //check if conditions valid (for notification message only)
      var self = this;
      var checkedConditions = allConditions.map(condition => self.treeToString(condition.ifBlock, lessonElementsLabels[condition.questionnaire_id].labelList, false).includes('...'));

      this.loadingConfig = false;
      this.disableConfigBool = false;
      if(checkedConditions.includes(true)){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.checkConfigInvalid')});
      }else{
        this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.checkConfigValid')});
      }
    }
  }
}
</script>
