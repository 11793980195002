<template>
  <div class="lessonPreview ion-color-page-font" id="lessonPreview">
    <section class="wrapper" v-if="!isInterventionPreview">
      <section class="col sec50">
        <select :value="getSelectedLanguage" id="inputSelectPreviewLanguage" class="buttonForm" @change="changeLanguage1($event.target.value)">
          <option value="" id="inputSelectPreviewLanguageNone" disabled>
            {{ 'generalTranslation.chooseLanguage' | translate }}
          </option>
          <option v-for="language in localesArray" :id="'inputSelectPreviewLanguage' + language" :key="language">
            {{language}}
          </option>
        </select>

        <select v-model="selectedSize" id="inputSelectPreviewSize" class="buttonForm previewSizeInput">
          <option value="" id="inputSelectPreviewSizeNone" disabled>
            {{ 'lessonTranslation.selectPreviewSize' | translate }}
          </option>
          <option v-for="size in previewSizeArray" :value="size" :id="'inputSelectPreviewSize' + size" :key="size">
            {{ 'lessonTranslation.selectPreviewSize' + size | translate }}
          </option>
        </select>
      </section>

      <section v-if="!isSkill" class="col sec50Right">
        <!-- todo also use in intervention preview -->
        <section class="checkboxSection notBold">
          <label class="container labelMargin">
            {{ 'lessonTranslation.allConditions' | translate }}
            <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('lessonTranslation.allConditionsTip')"></i>
            <input type="checkbox" v-model="showAllConditions" id="inputAllConditions">
            <span class="checkmark"></span>
          </label>
          <label class="container">
            {{ 'lessonTranslation.questionsRequiredCheck' | translate }}
            <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('lessonTranslation.questionsRequiredTip')"></i>
            <input type="checkbox" v-model="requiredMode" id="inputRequiredQuestions">
            <span class="checkmark"></span>
          </label>
          <label class="container">
            {{ 'lessonTranslation.ignorePageConditions' | translate }}
            <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('lessonTranslation.ignorePageConditionsTip')"></i>
            <input type="checkbox" v-model="ignorePageConditions" id="inputPageConditions">
            <span class="checkmark"></span>
          </label>
        </section>
      </section>
    </section>

    <ion-card v-if="showProgressBars && currentPage > 0" class="progress-panel" slot="fixed">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size="11">
            <div class="text-progress-bar">
              <ion-progress-bar class="progress-bar" :value="getLessonProgress / 100"></ion-progress-bar>
              <div class="p-text">
                {{getLessonProgress + '%'}}
              </div>
            </div>
          </ion-col>
          <ion-col size="1">
            <ion-icon name="flag" class="flag"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row style="height: auto;">
          <ion-col style="padding: 0;">
            <ion-card-content v-if="hasProgressbarText && showProgressbarText" class="progressbar-text" :class="{showProgressbarText: showProgressbarText}">
              <div style="padding-top: 10px; padding-bottom: 10px;">
                {{progressbarText}}
              </div>
            </ion-card-content>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>

    <!-- {{getPreviewAnswers}} -->

    <section class="wrapper" :class="{lessonPreviewClass: currentPage > 0, lessonPreviewClassFirstPage: currentPage === 0, lessonPreviewSizeTablet: selectedSize === 'Tablet', lessonPreviewSizeMobile: selectedSize === 'Mobile'}"
        :style="[currentPage > 0 ? (pageAttributes[currentPage - 1].color.startsWith('#') ? {'background': pageAttributes[currentPage - 1].color} : { 'background-image': pageAttributes[currentPage - 1].color}) : {}]">
      <section v-if="currentPage == 0" class="previewStart">
        <img v-if="interventionPicture != ''" :src="interventionPicture">
        <i class="far fa-image fa-2x" v-else></i>
        <h4>
          {{interventionTitleText}}
        </h4>
        <h3>
          {{titleText}}
        </h3>
      </section>
      <div v-if="currentPage > 0 && currentPage < numberOfPages + 1" class="spaceBottom">
        <!-- todo key -->
        <LessonPreviewElement
          v-for="(element, index) in evaluatedElementsPerPage[currentPage - 1]"
          :key="'preview' + index"
          :element="element"
          :isDiary="isDiary"
          :showRequiredQuestions="showRequiredQuestions"
          :showAllConditions="showAllConditions"
          :repetitionIndex="0"
          class="lessonPreviewElements"
          :class="{lessonPreviewElementsSmall: interventionSmallLayout}"
        ></LessonPreviewElement>
        <!-- repetition index is 0 since only used inside block -->
      </div>

      <section v-if="!isSkill" class="navButtons">
        <section :class="{navigationButtons: currentPage > 0, navigationButtonsFirst: currentPage === 0}">
          <ion-button v-show="currentPage === 0" id="buttonPreviewStartLesson" slot="start" class="navigationButton" @click="nextPage">
            {{ $tlang(getSelectedLanguage, 'lessonTranslation.startLesson')}}
          </ion-button>
          <ion-button v-show="currentPage === 1" id="buttonPreviewShowStart" slot="start" class="navigationButton" @click="previousPage">
            <ion-icon name="arrow-back"></ion-icon>
            {{ $tlang(getSelectedLanguage, 'lessonTranslation.back')}}
          </ion-button>
          <ion-button v-show="currentPage > 1" id="buttonPreviewLastPage" slot="start" class="navigationButton" @click="previousPage">
            <ion-icon name="arrow-back"></ion-icon>
            {{ $tlang(getSelectedLanguage, 'lessonTranslation.previousPage')}}
          </ion-button>
          <ion-button v-show="currentPage < numberOfPages && currentPage != 0" id="buttonPreviewNextPage" slot="end" class="navigationButton" @click="nextPage">
            {{ $tlang(getSelectedLanguage, 'lessonTranslation.nextPage')}}
            <ion-icon name="arrow-forward"></ion-icon>
          </ion-button>
          <ion-button v-show="currentPage === numberOfPages && currentPage != 0" id="buttonPreviewFinishLesson" slot="end" class="finishLessonButton" @click="finishLesson">
            {{ $tlang(getSelectedLanguage, 'lessonTranslation.endLesson')}}
            <ion-icon name="checkmark"></ion-icon>
          </ion-button>
          <div v-show="currentPage > 0">
            {{ $tlang(getSelectedLanguage, 'lessonTranslation.page')}} {{currentPage}}
          </div>
        </section>
      </section>
    </section>

    <div class="notPageModal">
      <BaseModal
        v-if="showAlert"
        id="warningIncompleteModal"
        :headerText="$t('lessonTranslation.warning')"
        :bodyText="alertText"
        :leftButtonText="$t('lessonTranslation.continue')"
        @close-modal="closeAlert"
      ></BaseModal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
//import alertController from '@ionic/vue';
import {mapGetters,mapMutations} from 'vuex';
import '@aas2/aas2-evaluation-service';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import elementsCompleteHelper from '../mixins/elementsCompleteHelper';
import LessonPreviewElement from './LessonPreviewElement.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: 'LessonPreview',

  components: {
    LessonPreviewElement,
    BaseModal
  },

  mixins: [httpHelper, elementJSONToPropsHelper, elementsCompleteHelper],

  props: {
    isShownFromStart: {
      required: true,
      type: Boolean
    },

    previewPage: {
      required: false,
      type: Boolean
    },

    isDiary: {
      required: true,
      type: Boolean
    },

    lessonDetails: {
      required: true,
      type: Object
    },

    interventionDetails: {
      required: true,
      type: Object
    },

    pageChanged:{
      required: true,
      type: Boolean
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      localesArray: [],
      elementsPerPage: [],
      pageAttributes: [],
      currentPage: 0,
      showAllConditions: false,
      requiredMode: false,
      showRequiredQuestions: false,
      showAlert: false,
      alertText: "",
      alertButtons: [],
      ignorePageConditions: false,
      evaluationService: '',
      showProgressbarText: false,
      textShowingHandler: null,
      selectedSize: "Desktop",
      previewSizeArray: ["Desktop", "Tablet", "Mobile"]
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getPreviewAnswers',
      'getLessonElementsArr',
    ]),

    interventionId: function(){
      return Number(this.lessonDetails.intervention_id)
    },

    studyId: function(){
      return Number(this.interventionDetails.study_id)
    },

    interventionTitleText: function(){
      var title = !this.isDiary ? this.interventionDetails.title : "";
      return title ? title : Vue.i18n.translate('generalTranslation.noTitle')
    },

    interventionPicture: function(){
      var details = !this.isDiary ? this.interventionDetails : this.lessonDetails;
      return details.picture ? this.$urlUploads + "/studies/" + this.studyId + "/" + details.picture : ""
    },

    interventionSmallLayout: function(){
       return !this.isDiary ? (this.interventionDetails.small_layout == 1) : (this.lessonDetails.small_layout == 1);
    },

    titleText: function(){
      var index = this.lessonDetails.translations.findIndex(translation => translation.locale === this.getSelectedLanguage);
      var title = (index != -1) ? this.lessonDetails.translations[index].title : "";
      return title ? title : Vue.i18n.translate('generalTranslation.noTitle')
    },

    showProgressBars: function(){
      if(this.isDiary || this.isSkill){
        return false
      }else if(this.$enabledFeatures.includes('progress') && this.interventionDetails.gamification && (this.interventionDetails.gamification.progressbars_enabled || this.interventionDetails.gamification.progressbars_enabled === 1)){
        return true
      }else{
        return false
      }
    },

    // returns lesson progress in [0; 100] percent
    getLessonProgress: function(){
      return Math.trunc((this.currentPage - 1) / this.numberOfPages * 100)
    },

    hasProgressbarText: function(){
      return this.progressbarText != undefined && this.progressbarText != ""
    },

    progressbarText: function(){
      if(this.pageAttributes[this.currentPage - 1] && this.pageAttributes[this.currentPage - 1].progressText){
        return this.pageAttributes[this.currentPage - 1].progressText[this.getSelectedLanguage]
      }else{
        return ""
      }
    },

    isInterventionPreview: function(){
      return this.$route.name === 'interventionPreview'
    },

    getDefaultColor: function(){
      var defaultColor = this.lessonDetails.page_color ? this.lessonDetails.page_color : this.$defaultLessonPageColor;
      return defaultColor.startsWith('#') ? defaultColor : this.getLink(defaultColor);
    },

    evaluatedPageConditions: function(){
      var conditions = [];
      for(var page in this.pageAttributes){
        if(this.evaluationService && this.pageAttributes[page].condition){
          conditions.push(this.evaluationService.evaluateCondition(this.pageAttributes[page].condition, this.getPreviewAnswers));
        }else{
          conditions.push(true);
        }
      }
      return conditions
    },

    evaluatedElementsPerPage: function(){
      if(this.ignorePageConditions){
        return this.elementsPerPage
      }else{
        return this.elementsPerPage.filter((page, index) => this.evaluatedPageConditions[index] === true)
      }
    },

    numberOfPages: function(){
      return this.evaluatedElementsPerPage.length
    },
  },

  watch:{
    progressbarText: function(newVal){
      if(newVal != ""){
        // open text
        this.showProgressbarText = true;
        this.textShowingHandler = setTimeout(() => {
          this.showProgressbarText = false;
        }, 6000)
      }else{
        // close text
        this.showProgressbarText = false;
        clearTimeout(this.textShowingHandler);
      }
    },
  },

  created(){
    this.evaluationService = require('@aas2/aas2-evaluation-service');
    this.SET_PREVIEWANSWERS({});
    this.getPagination();
    this.initPreview();
  },

  methods: {
    ...mapMutations([
      'SET_SELECTEDLANGUAGE',
      'SET_PREVIEWANSWERS',
    ]),

    getLink: function(filename){
      return 'url(' + this.$urlUploads + "/studies/" + this.studyId + "/" + filename + ')';
    },

    changeLanguage1: function(language){
      this.SET_SELECTEDLANGUAGE(language);
    },

    initPreview: function(){
      if(this.localesArray.length === 0){
        this.localesArray = !this.isDiary ? this.lessonDetails.locales : this.interventionDetails.locales;
      }
      if(this.localesArray == null){
        this.localesArray = ["de", "en"];
      }

      if(!this.localesArray.includes(this.getSelectedLanguage)){
        var language = (this.localesArray.includes("de")) ? "de" : this.localesArray[0];
        this.SET_SELECTEDLANGUAGE(language);
      }
    },

    getPagination: function(){
      var allElements = this.getLessonElementsArr;
      if(allElements.length > 0){
        var elementsOfPage = []; //contains array (with elements of page) for all pages
        var pageAttributesList = [];

        for(var elem in allElements){
          if(allElements[elem].type === "ElementsPage"){
            elementsOfPage.push([]);
            var pageColor = allElements[elem].propsObj.color ? allElements[elem].propsObj.color : this.getDefaultColor;

            var condition = "";
            //check if condition for page set & complete
            if(allElements[elem].propsObj.condition && this.checkIfConditionComplete(allElements[elem], [], false)){
              condition = allElements[elem].propsObj.condition;
            }

            var texts = {};

            if(this.showProgressBars){
              var translationsArr = allElements[elem].translations;
              for(var lang in translationsArr){
                texts[lang] = translationsArr[lang];
              }
            }

            pageAttributesList.push(
              {
                color: (pageColor.startsWith('#') || pageColor.startsWith('url(')) ? pageColor : "url(" + pageColor + ")",
                condition: condition,
                progressText: texts
              }
            );
          }else{
            elementsOfPage[elementsOfPage.length - 1].push(allElements[elem]);
          }
        }
        this.elementsPerPage = elementsOfPage;
        this.pageAttributes = pageAttributesList;

        if(this.isInterventionPreview || this.isShownFromStart){
          this.currentPage = 0;
        }else{
          this.currentPage = this.previewPage;

          //if current page has page condition, set ignorePageConditions to true so that the page can be shown
          //if page was changed in lesson editor it was a non conditional page anyway -> don't set ignorePageConditions
          if(!this.pageChanged && this.pageAttributes[this.currentPage - 1].condition != ""){
            this.ignorePageConditions = true;
          }
        }
      }
    },

    showTopOfNewPage: function(){
      if(this.isInterventionPreview){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }else{
        this.$emit('new-page');
      }
    },

    previousPage: function(){
      this.currentPage--;
      this.showTopOfNewPage();
    },

    nextPage: function(){
      if(this.requiredMode && this.currentPage > 0 && !this.checkRequiredQuestions(true)){
        this.showRequiredQuestions = true;

        this.alertText = this.lessonDetails.questions_required ? Vue.i18n.translate('lessonTranslation.warningUnansweredRequiredQuestionsNextPage') :
          Vue.i18n.translate('lessonTranslation.warningUnansweredNonRequiredQuestionsNextPage');
        this.showAlert = true;
      }else{
        this.currentPage++;
        this.showRequiredQuestions = false;
        this.showTopOfNewPage();
      }
    },

    closeAlert: function(done){
      this.showAlert = false;
      if(done){
        if(this.currentPage === this.numberOfPages && this.currentPage != 0){ // finish lesson
          this.requiredMode = false;
          this.finishLesson();
        }else{ // next page
          this.currentPage++;
          this.showRequiredQuestions = false;
          this.showTopOfNewPage();
        }
      }
    },

    finishLesson: function(){
      if(this.requiredMode && !this.checkRequiredQuestions(false)){
        this.showRequiredQuestions = true;
        this.alertText = this.lessonDetails.questions_required ? Vue.i18n.translate('lessonTranslation.warningUnansweredRequiredQuestionsNextPage') :
          Vue.i18n.translate('lessonTranslation.warningUnansweredNonRequiredQuestionsNextPage');
        this.showAlert = true;
      }else if(this.isInterventionPreview){
        this.showRequiredQuestions = false;
        this.$emit('new-page');
      }else{
        this.showRequiredQuestions = false;
        this.$emit('close-modal');
      }
    },

    //checkPage true for page, false for all elements
    checkRequiredQuestions: function(checkPage){
      var elements = checkPage ? this.evaluatedElementsPerPage[this.currentPage - 1] : this.getLessonElementsArr;
      return this.checkElements(elements)
    },

    checkElements: function(elements){
      for(var elem in elements){
        if(elements[elem].type === "ElementsQuestion" && elements[elem].propsObj.questionReq &&
            (!this.getPreviewAnswers[elements[elem].propsObj.id] || this.getPreviewAnswers[elements[elem].propsObj.id].length === 0)){
          return false
        }else if(elements[elem].type === "ElementsBlock"){
          //only check questions in block if block shown
          var blockShown = true;
          if(this.evaluationService && elements[elem].propsObj.condition){
            blockShown = this.evaluationService.evaluateCondition(elements[elem].propsObj.condition, this.getPreviewAnswers);
          }

          if(blockShown && !this.checkElements(elements[elem].propsObj.elements)){
            return false
          }
        }
      }
      return true
    },

    // presentAlert(text, buttonText, callbackButton) {
    //   return alertController
    //     .create({
    //       header: Vue.i18n.translate('lessonTranslation.warning'),
    //       subHeader: text,
    //       buttons: [{
    //         text: Vue.i18n.translate('generalTranslation.abort'),
    //         role: 'cancel'
    //       }, {
    //         text: buttonText,
    //         handler: () => {
    //           callbackButton();
    //         }
    //       }]
    //     })
    //     .then(a => a.present());
    // },
    //
    // presentAlert: function(text, buttonText, callbackButton){
    //   this.alertText = text;
    //   this.alertButtons = [{
    //     text: Vue.i18n.translate('generalTranslation.abort'),
    //     handler: () => {
    //       this.closeAlert();
    //     },
    //     role: 'cancel'
    //   }, {
    //     text: buttonText,
    //     handler: () => {
    //       callbackButton();
    //     }
    //   }];
    //   // this.showAlert = true;
    // },
  }
}
</script>
