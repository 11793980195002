<template>
  <div class="baseAddElement">
    <section class="wrapper">
      <i class="fa fa-plus fa-lg" data-toggle="tooltip" :id="'buttonAddElement' + id" :title="$t('lessonTranslation.addNewElement')"
        @click="$emit('change-element', {'type': 'show-popup', 'id': id, 'top': top})"></i>
      <section class="popup">
        <span :id="popupId" class="popuptext">
          <i class="fa fa-window-close fa-lg closeButton closeBtn" :id="'buttonCloseAddElement' + id"
            @click="$emit('change-element', {'type': 'show-popup', 'id': id, 'top': top})"></i>
          <h3>
            {{ 'lessonTranslation.addNewElement' | translate }}
          </h3>
          <button type="button" v-for="button in addElementsButtons(isInBlock, isSkill)" :id="'buttonAdd' + button.elementType + id" :key="button.elementType" class="buttonForm"
              @click="$emit('change-element', {'type': 'add-element', 'id': id, 'top': top, 'index': index, 'elementType': button.elementType})">
            {{"+ " + button.text}}
          </button>
        </span>
      </section>
    </section>
  </div>
</template>
<script>
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'BaseAddElement',

  mixins: [elementsHelper],

  props: {
    id: {
      required: true,
      type: Number,
    },

    index: {
      required: true,
      type: Number,
    },

    top: {
      required: true,
      type: Boolean,
    },

    isInBlock: {
      required: true,
      type: Boolean,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    popupId: function(){
      //has to be the same as in lessonEditorElements
      return 'popupId' + this.id + (this.top ? 'top' : '')
    },
  },
}
</script>
