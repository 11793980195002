<template>
  <div class="studyPermissions">
    <section class="smallerComp">
      <h3 v-if="isEditable">
        {{ 'permissionsTranslation.permissionsInstructions' | translate }}
        <i class="far fa-question-circle" data-toggle="tooltip" :title="permissionTooltipText"></i>
      </h3>
      <h5>
        {{editableText}}
      </h5>

      <section class="wrapper">
        <select :disabled="loading || !isEditable" :value="selectedView" id="inputShowUsers" class="buttonForm"
            @change="toggleView(Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0])">
          <option value="" id="inputShowUsersNone" disabled>
            {{ 'permissionsTranslation.chooseUsers' | translate }}
          </option>
          <option value="collabView" id="inputShowUsersCollaborating">
            {{ 'permissionsTranslation.collab' | translate }}
          </option>
          <option value="nonCollabView" id="inputShowUsersNonCollaborating">
            {{ 'permissionsTranslation.nonCollab' | translate }}
          </option>
        </select>
      </section>

      <BaseSearch
        v-show="!loading && (permissionsListData.list.length > 0 || noSearchResults)"
        :key="searchKey"
        type="study-users"
        @get-new-list="getNewList"
        @reset-page="resetPage"
      ></BaseSearch>

      <section v-show="!loading">
        <BasePagination
          id="paginationPermissionsTop"
          :numberOfPages="permissionsListData.allPages"
          :currentPage="permissionsListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <table v-if="permissionsListData.list && permissionsListData.list.length > 0" id="permissionList" class="permissionsTable">
          <tr v-for="(user, index) in permissionsListData.list" :id="'entryUser' + user.id" :key="user.id" :class="{disablePermissions: !isEditable || Number(user.id) === Number(userId)}"
              class="wrapper interventionElement">
            <div class="tableFirstColumn">
              <label>
                {{user.firstname}} {{user.lastname}} ({{user.name}})
              </label>
              {{user.email}}
            </div>
            <section class="notBold notNameTd" @change="changePermission(user.role, index)">
              <div v-for="listIndex in 4" class="tableCell" :key="listIndex">
                <section class="radioSection">
                  <label class="container">
                    {{descriptionList[listIndex-1]}}
                    <input type="radio" v-model="user.role" :value="valueList[listIndex-1]" :id="'inputUserRole' + user.id + '-' + valueList[listIndex-1]"
                      :disabled="!isEditable || Number(user.id) === Number(userId)" class="hide">
                    <span class="checkmark"></span>
                  </label>
                </section>
              </div>
            </section>
          </tr>
        </table>

        <section v-else id="noUsers">
          {{noUsers}}
        </section>

        <BasePagination
          id="paginationPermissionsBottom"
          :numberOfPages="permissionsListData.allPages"
          :currentPage="permissionsListData.currentPage"
          @new-page="getNextPage"
          class="paginationBottom"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loading"></i>

      <button type="button" v-if="isEditable && !loading" id="buttonSavePermissions" class="permissionsSaveButton" @click="savePermissions">
        {{ 'generalTranslation.save' | translate }}
      </button>
    </section>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesPermissionsModal"
      :headerText="$t('permissionsTranslation.leaveSiteWarning')"
      :bodyText="modalHeaderText"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="closeModal"
    ></BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';
import BasePagination from './BasePagination.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'StudyPermissions',

  components: {
    BaseModal,
    BasePagination,
    BaseSearch
  },

  mixins: [httpHelper],

  props: {
    studyId: {
      required: true,
      type: Number
    }
  },

  data: function(){
    return {
      valueList:["Study Owner","Study Collaborator","Study Access","none"],
      permissionsListData: {}, //used for both views
      selectedView: "collabView",
      changedRoles: [],
      idsOfChangedRoles: [],
      warningChangesVisible: false,
      loading: true,
      collaboratorListData: {},
      // collaboratorListData has
      // - list: collaborators of intervention (used in nonCollabView)
      // - idList: list of all ids of users that are collaborators of this intervention (used in nonCollabView)
      permissionsChanged: false,
      filterTerm: "",
      searchKey: 1,
    }
  },

  computed: {
    ...mapGetters([
      'getUserId',
      'getNotificationText',
      'getMyRoleForStudy',
    ]),

    userId: function(){
      return this.getUserId
    },

    isEditable: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    hasNoPermissions: function(){
      return (this.getMyRoleForStudy === "None")
    },

    isCollabView: function(){
      return (this.selectedView === 'collabView')
    },

    modalHeaderText: function(){
      return (Vue.i18n.translate('permissionsTranslation.unsavedChangesPermissions') + " " + Vue.i18n.translate('generalTranslation.warning'))
    },

    editableText: function(){
      if(this.isEditable){
        return Vue.i18n.translate('permissionsTranslation.ownPermissionsNotEditable')
      }else{
        return Vue.i18n.translate('permissionsTranslation.permissionsNotEditable')
      }
    },

    descriptionList: function(){
      return [
        Vue.i18n.translate('permissionsTranslation.owner'),
        Vue.i18n.translate('permissionsTranslation.editPermission'),
        Vue.i18n.translate('permissionsTranslation.copyPermission'),
        Vue.i18n.translate('permissionsTranslation.noPermission')
      ]
    },

    permissionTooltipText: function(){
      return Vue.i18n.translate('permissionsTranslation.permissionExplanation')
    },

    noSearchResults: function(){
      return this.permissionsListData.list.length === 0 && this.filterTerm != ''
    },

    noUsers: function(){
      if(this.filterTerm === ""){
        return Vue.i18n.translate('permissionsTranslation.noUsers')
      }else{
        return Vue.i18n.translate('generalTranslation.noResults')
      }
    },
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.loading = false;
      }
    },
  },

  created(){
    if(!this.hasNoPermissions){
      this.getCollaboratorsIncludingPermissions(1);
    }
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    changePermission: function(role, index){
      this.permissionsListData.list[index].role = role;
      this.permissionsChanged = true;
      this.$emit('set-changed', { name: 'permissions', value: true });
    },

    savePermissions: async function(){
      await this.saveChanges();
      var addPermissions = [];
      var removePermissions = [];
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('permissionsTranslation.updateStudyPermissionsLoad')});
      for(var i = 0; i < this.changedRoles.length; i++){

        //permissions
        if(this.changedRoles[i].oldRole != this.changedRoles[i].role){
          //none -> access/collaborator/owner
          if(this.changedRoles[i].oldRole === "none"){
            addPermissions.push(
              {
                "id" : this.changedRoles[i].id,
                "role" : this.changedRoles[i].role.toLowerCase().replace(/ /g,".")
              }
            );
          }
          //access/collaborator/owner -> none
          else if(this.changedRoles[i].role === "none"){
            removePermissions.push(
              {
                "id" : this.changedRoles[i].id,
                "role" : this.changedRoles[i].oldRole.toLowerCase().replace(/ /g,".")
              }
            );
          }
          //access -> collaborator/owner OR collaborator -> access/owner OR owner -> collaborator/access
          else {
            removePermissions.push(
              {
                "id" : this.changedRoles[i].id,
                "role" : this.changedRoles[i].oldRole.toLowerCase().replace(/ /g,".")
              }
            );
            addPermissions.push(
              {
                "id" : this.changedRoles[i].id,
                "role" : this.changedRoles[i].role.toLowerCase().replace(/ /g,".")
              }
            );
          }
        }
      }

      try{
        if(removePermissions.length != 0){
          await this.removeCollaboratorsFromStudyRequest(removePermissions, this.studyId)
        }
        if(addPermissions.length != 0){
          await this.addCollaboratorsToStudyRequest(addPermissions, this.studyId)
        }
        this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('permissionsTranslation.updateStudyPermissionsSuccess')});
      }catch(error){
        this.handleErrors(error, function(){ this.savePermissions() }, "");
      }
      this.loading = true;
      this.changedRoles = [];
      this.idsOfChangedRoles = [];
      if(this.isCollabView){
        this.getCollaboratorsIncludingPermissions(1);
      }else{
        this.getCollaboratorsIncludingPermissions(0);
      }
      this.permissionsChanged = false;
      this.$emit('set-changed', { name: 'permissions', value: false });
    },

    toggleView: function(newView){
      if(this.permissionsChanged){
        this.warningChangesVisible = true;
      }else{
        this.selectedView = newView;
        this.searchKey++;
        this.filterTerm = "";
        if(this.isCollabView){
          this.getCollaboratorsIncludingPermissions(1);
        }else{
          this.getCollaboratorsIncludingPermissions(0);
        }
        this.changedRoles = [];
        this.idsOfChangedRoles = [];
      }
    },

    saveChanges: async function(){
      for(var collaborator in this.permissionsListData.list){
        var id;
        if(this.permissionsListData.list[collaborator].role != this.permissionsListData.list[collaborator].oldRole){
          if(this.idsOfChangedRoles.includes(this.permissionsListData.list[collaborator].id)){
            for(id in this.idsOfChangedRoles){
              if(this.permissionsListData.list[collaborator].id === this.idsOfChangedRoles[id]){
                this.changedRoles[id] = this.permissionsListData.list[collaborator];
              }
            }
          }else{
            this.changedRoles.push(this.permissionsListData.list[collaborator]);
            this.idsOfChangedRoles.push(this.permissionsListData.list[collaborator].id);
          }
        }else{
          for(id in this.idsOfChangedRoles){
            if(this.permissionsListData.list[collaborator].id === this.idsOfChangedRoles[id]){
              this.changedRoles.splice(id,1);
              this.idsOfChangedRoles.splice(id,1);
            }
          }
        }
      }
      return true
    },

    closeModal: function(done){
      this.warningChangesVisible = false;

      if(done){
        this.permissionsChanged = false;
        this.$emit('set-changed', { name: 'permissions', value: false });
        if(this.isCollabView){
          this.toggleView("nonCollabView");
        }else{
          this.toggleView("collabView");
        }
      }
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.saveChangesAndGetNewPermissions(selectedNumber);
      }
    },

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.saveChangesAndGetNewPermissions(1);
    },

    resetPage: function(){
      this.permissionsListData.currentPage = 1;
    },

    saveChangesAndGetNewPermissions: async function(page){
      await this.saveChanges();
      if(this.isCollabView){
        this.getCollaboratorsIncludingPermissions(page);
      }else{
        this.getAllEditorsIncludingPermissions(page);
      }
    },

    getCollaboratorsIncludingPermissions: function(page){
      this.loading = true;
      var queryString = (page === 0) ? "limit=0" : ("page=" + page);
      queryString += this.filterTerm;

      var self = this;
      this.requestCollaboratorsIncludingPermissions(queryString, this.studyId, this.isCollabView, this.idsOfChangedRoles, this.changedRoles)
      .then( function(response){
        if(self.isCollabView){
          self.permissionsListData = response;
          self.loading = false;
        }else{
          self.collaboratorListData = response;
          self.getAllEditorsIncludingPermissions(1);
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getCollaboratorsIncludingPermissions(page) }, "");
      });
    },

    getAllEditorsIncludingPermissions: function(page){
      this.loading = true;
      var self = this;
      this.requestAllEditorsIncludingPermissions(this.collaboratorListData, page, this.filterTerm, this.idsOfChangedRoles, this.changedRoles)
      .then( function(response){
        self.permissionsListData = response;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getAllEditorsIncludingPermissions(page) }, "");
      });
    },
  }
}
</script>
