export default {
  generalTranslation: {
    create: "Create",
    save: "Save",
    copy: "Copy",
    choose: "Select",
    delete: "Delete",
    abort: "Cancel",
    select: "Select..",
    publish: "Publish",
    import: "Import",
    next: "Next",
    back: "Back",
    yes: "Yes",
    no: "No",
    of: "of",
    to: "to",
    chooseLanguage: "Select language..",
    name: "Name",
    nameIntervention: "Intervention name",
    email: "Email",
    lesson: "Lesson",
    creationDate: "Date of creation",
    ascending: "ascending",
    descending: "descending",
    newStudy: "New group",
    createNewStudy: "Create group",
    noResults: "No search results",
    noGroups: "No groups available.",
    noGroupsInstruction: "Please create a group by clicking the button in the top right corner or request being added to an existing group of another editor. Interventions can be created in the group then.",
    noGroupsInstructionModal: 'Please create a group on "My groups" first.',
    noStudies: "No studies available.",
    noStudiesInstruction: "Please contact an admin to request a study in the ecoach platform.",
    noInterventions: "No interventions available.",
    noInterventionsInstruction: 'Please create an intervention by clicking the button in the top right corner. If you do not have a group, create one on "My groups" first.',
    noInterventionsInstructionStudy: "Please create an intervention by clicking the button in the top right corner.",
    noInterventionsInstructionModal: 'Please create an intervention on "My interventions" first. If you do not have a group, create one on "My groups" before that.',
    noDiaries: "No diaries available.",
    noDiariesTooltip: "Please create a diary by clicking the button in the top right corner.",
    noLessons: "No lessons available.",
    noLessonsTooltip: "Please create an intervention by clicking the button in the top right corner.",
    noLessonElements: "No lesson elements available.",
    noLessonElementsTooltip: "Elements can be added by clicking on +",
    noSkills: "No skills available.",
    noSkillsTooltip: "Please create a skill by clicking the button in the top right corner.",
    noTitle: "No title",
    noDescription: "No description",
    showHideAnnouncements: "Show/hide announcements",
    leaveSiteWarning: "Do you want to leave this site?",
    unsavedChanges: "There are unsaved changes on the following pages",
    warning: "All unsaved changes are discarded.",
    warningCreate: "All unsaved changes are discarded on creation.",
    warningCopy: "All unsaved changes are discarded on copy.",
    warningImport:"All unsaved changes are discarded on import.",
    savingLoad: "Saving {name}..",
    savingSuccess: "{name} saved.",
    errorNameEmpty: "Insert name",
    errorSelectFile: "Select file",
    errorSelectStudy: "Select study",
    errorSelectWorkgroup: "Select workgroup",
    errorSelectIntervention: "Select intervention",
    errorSelectLesson: "Select lesson",
    errorSelectDiary: "Select diary",
    errorAccept: "Accept checklist",
    none: "None",
    privacy: "Privacy",
    imprint: "Imprint"
  },
  myStudiesTranslation: {
    myStudies: "My groups",
    myInterventions: "My interventions",
    more: "More",
    moreStudies: "Other groups",
    moreStudiesExplanation: "This list shows groups in which you have no permissions. Interventions and diaries are visible inside the group but no other details. To receive access see group > details.",
    moreInterventions: "Other interventions",
    moreInterventionsExplanation: "This list shows interventions in which you have no permissions. No details of the intervention are accessible. To receive access see group > details.",
    filter: "Filter",
    search: "Search..",
    chooseSortOption: "Select sort option..",
    apply: "Apply",
    reset: "Reset",
    details: "Details",
    activate: "Publish",
    interventionActive: "This intervention is already published.",
    deleteWarning: 'Are you sure to delete group "{name}"?',
    warning: "This action can not be undone.",
    warningLanguages: "The following translations are incomplete: ",
    warningInterventionDetails: "The details of the intervention were not provided.",
    activateText: "Note: The intervention can not be edited in the eCoach Platform after published but is still editable in the CMS. Test interventionen, not yet reviewed or declined official interventions can be deleted in the eCoach platform.",
    activateMode: "Publishing intervention as",
    activateTest: "test intervention (without real participants)",
    activateReal: "official intervention (with real participants)",
    activateTestConfirm: "Hereby it is confirmed that no real participants will be added to this intervention.",
    activateHint: "By publishing this intervention it is confirmed that:",
    activateChecked: "The content of the intervention has been checked.",
    activateCritical: "The intervention does not contain critical or inappropriate content (texts, media, links).",
    activateEmergencyText: "An emergency contact text is included in all lessons or external documents for participants or is not needed in this intervention.",
    activateIntro: "The intervention has an introduction lesson with explanations of the functionality of the eSano Patient App or these are included in external documents for participants.",
    activateContact: "A contact (e.g. eCoach manager) for content-related questions or reporting problems with the eCoach is included in the intervention or in external documents for participants.",
    activateOtherPerson: "Afterwards, publishing has to be confirmed by a reviewer of the study in the eCoach platform.",
    lessonWarning: "The intervention can not be published because it does not have any lessons.",
    loginWarning: "Please sign in.",
    deleteStudyLoad: "Deleting group..",
    deleteStudySuccess: "Group deleted.",
    studyHasInterventionsWarning: "This group includes interventions and/or diaries. When deleting the group, these are deleted as well.",
    receiveAccess: "To receive access see group details",
  },
  loginTranslation: {
    password: "Password",
    forgotPassword: "Forgot your password?",
    login: "Sign in",
    loginLoad: "Signing in..",
    errorEnterEmail: "Enter your email.",
    errorEnterPassword: "Enter your password.",
    errorEnterValidEmail: "Enter a valid email address.",
    errorEnterValidPassword: "Enter a password in valid format (at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character)",
  },
  logoutTranslation: {
    logout: "Logout",
    logoutLoad: "Signing out..",
  },
  answerTranslation: {
    answer: "Answer",
    answerText: "Text for value",
    label: "Answer label",
    answerTranslation: "Translation of answer",
    answerTextTranslation: "Translation of text for value",
  },
  studyTranslation: {
    createStudyLoad: "Creating group..",
    createStudySuccess: "Group created.",
    copyStudy: 'Copy study "{name}"',
    newName: "New name",
    copyStudyLoad: "Copying group..",
    copyStudySuccess: "Group copied.",
    studyDetails: "Group details",
    newInterv: "New intervention",
    newDiary: "New diary",
    interventions: "Interventions of group",
    diaries: "Diaries",
    permissions: "Permissions",
    studyMedia: "Manage Media",
  },
  interventionTranslation: {
    newIntervention: "Create intervention",
    newInterventionGroup: 'Create intervention in group "{name}"',
    createInterventionLoad: "Creating intervention..",
    createInterventionSuccess: "Intervention created.",
    selectedStudy: "Selected group",
    backToStudySelection: "Back to group selection",
    selectStudy: "Select a group to create the intervention.",
    shownGroups: "Only groups with owner or edit permissions are shown.",
    shownStudies: "Only studies with owner or eCoachManager permissions are shown.",
    shownInterventions: "Only interventions with owner or edit permissions are shown.",
    copyIntervention: 'Copy intervention "{name}"',
    activateIntervention: 'Publish intervention "{name}"',
    copyInstruction: "Select a group to copy the intervention.",
    activateInstruction: "Select a study in the E-Coach Platform to published the intervention.",
    copyInterventionLoad: "Copying intervention..",
    copyInterventionSuccess: "Intervention copied.",
    publishInterventionLoad: "Publishing intervention in study of E-Coach Platform..",
    publishInterventionSuccess: "Intervention published in study of E-Coach Platform and has to be checked by a reviewer (see Reviews tab).",
    showTranslation: "Show translation",
    hideTranslation: "Hide translation",
    name: "Name (not shown to participants)",
    title: "Title",
    description: "Description",
    titleTranslation: "Translation of title",
    descriptionTranslation: "Translation of description",
    addLanguage: "Add more languages",
    add: "Add",
    note: "Note",
    ok: "Ok",
    unsavedChangesInterventionDetails: "The intervention details have unsaved changes.",
    unsavedChangesConfigLeave: "The configuration has unsaved changes. Save the changes or click Cancel again.",
    unsavedChangesConfigLeaveInterventionEditor: "The configuration has unsaved changes. Click Save or Cancel.",
    leave: "Leave",
    deleteSelectedLanguageWarning: "The current language can not be deleted. If you want to delete the language, select another language to show.",
    showTranslationWarning: "The translation can only be shown if more than one language is selected in the details.",
    permissionContact: "To receive access please contact:",
    or: "or",
    editableForOwner: "Note: The configuration is only editable with owner permissions.",
    preview: "Preview",
    interventionDetails: "Intervention details",
    image: "Image",
    selectFile: "Select file..",
    languagesIntervention: "Languages of intervention",
    interventionGuided: "Guided or unguided intervention",
    guided: "guided",
    unguided: "unguided",
    smallLayout: "Small layout",
    participants: "Participants of Intervention",
    addedByEcoach: "are added by an E-Coach only",
    addedOrSubscribing: "are added by an E-Coach or can subscribe themselves",
    useBuddyFeature: "Enable buddy feature?",
    buddyFeatureExplanation: 'By enabling this feature, two participants can be connected. These "buddies" can then remind each other of their daily diary entries.',
    useProgressBars: "Enable progress bars in lessons?",
    lessonsOfIntervention: "Corresponding lessons",
    deleteLessonWarning: 'Are you sure to delete lesson "{name}"?',
    updatePositionsLessonsLoad: "Updating positions of the lessons..",
    updatePositionsLessonsSuccess: "Positions of the lessons updated.",
    deleteLessonLoad: "Deleting lesson..",
    deleteLessonSuccess: "Lesson deleted.",
    deleteSkillWarning: 'Are you sure to delete skill "{name}"?',
    deleteSkillLoad: "Deleting skill..",
    deleteSkillSuccess: "Skill deleted.",
    updateInterventionDetailsLoad: "Saving intervention details..",
    updateInterventionDetailsSuccess: "Intervention details saved.",
    uploadStudyPictureLoad: "Uploading media..",
    uploadStudyPictureSuccess: "Media uploaded.",
    translationProgress: "Translation progress",
    chooseProgress: "Translation progress for..",
    total: "Total",
    intervention: "Intervention",
    noProgress: "No translation progress available",
    updateInterventionConfigLoad: "Saving configuration..",
    updateInterventionConfigSuccess: "Configuration saved.",
    selectLanguageWarning: "Select a language",
    export: "Export",
    exportInterventionLoad: "Exporting intervention..",
    exportInterventionSuccess: "Intervention exported.",
    deleteWarning: 'Are you sure to delete intervention "{name}"?',
    unlocktype: "Unlocking of lesson",
    chooseUnlocktype: "Select unlock type..",
    chooseDateType: "Days/weeks",
    dateTypeDays: "Day(s)",
    dateTypeWeeks: "Week(s)",
    feedback: "Feedback",
    feedbackRequired: "Required",
    feedbackNotRequired: "Not required",
    showLessonAs: "Show lesson as",
    normalLesson: "Normal lesson",
    bonusLesson: "Bonus lesson",
    unlockDiaries: "Lesson unlocks the following diaries (at the same time as the lesson)",
    unlockDiariesIntervention: "Lesson unlocks the following intervention",
    unlockDiariesInterventionExplanation: "(at the same time as the intervention)",
    unlockDiariesEmpty: "None",
    unlockDiariesAdd: "Add diary",
    chooseDiary: "Select diary..",
    unlockLessons: "Conditional lessons selectable for patient in dialog",
    unlockLessonsMultiple: "Multiple",
    unlockLessonsOne: "One",
    always: "Always unlocked",
    manually: "Manually unlocked by ecoach",
    afterPrevious: "Unlocked after previous lesson ends",
    conditionalAfterPrevious: "Unlocked after previous conditional lesson ends",
    atDate: "Unlocked x days/weeks after start of intervention",
    condition: "Automatically unlocked (conditional)",
    selectLessonOrDiary: "Lesson or diary used for condition",
    conditionQuestion: "Condition with question(s)",
    conditionLessonFinished: "If lesson finished",
    noConditionSet: "No condition set",
    conditionLesson: "Unlocked if:",
    descriptionCondition: "Description of condition (used in eCoach platform)",
    descriptionConditionShort: "Description of condition",
    conditionExplanation: "Conditions for one/more lessons/diaries unlock this lesson (Lesson a OR b): ",
    addCondition: "+ condition",
    removeCondition: "Remove condition",
    addConditionError: "No more lessons/diaries available",
    removeConditionError: "At least one condition needed",
    errorSelectLessonDiary: "Select a lesson or diary for the condition first.",
    errorNoQuestions: "The selected lesson/diary does not have any questions.",
    errorConditionsIncomplete: "The configuration for automatic unlock is incomplete.",
    checkConfig: "Check configuration",
    checkConfigLoad: "Checking configuration...",
    checkConfigValid: "The configuration is valid.",
    checkConfigInvalid: "The configuration has errors (see marked conditions below).",
    inCustomOrder: "Lessons included in default configuration",
    notInCustomOrder: "Lessons not included in default configuration",
    changeConfig: "Update configuration",
    showConfig: "Show configuration",
    deleteInterventionLoad: "Deleting intervention..",
    deleteInterventionSuccess: "Intervention deleted.",
    selectImport: "JSON file with exported intervention",
    importIntervention: 'Import intervention',
    importInterventionHeader: 'Import intervention into group "{name}"',
    importInterventionLoad: "Importing intervention..",
    importInterventionSuccess: "Intervention imported.",
    customOrderAddAll: "Add all lessons",
    customOrderRemoveAll: "Remove all lessons",
    setUnlocktypeForAll: "Use for all",
    updateLessonPositions: "Update positions",
    unsavedChangesPositionsLeave: "The positions have unsaved changes. Save the changes or click Cancel again.",
    unsavedChangesPositionsLeaveInterventionEditor: "The positions have unsaved changes. Click Save or Cancel.",
    updatePositionsDragDrop: "Update positions via Drag and Drop",
    interventionGuidedSelected:"The intervention is guided (defined in intervention details).",
    interventionUnguidedSelected:"The intervention is unguided (defined in intervention details), therefore not all configurations can be selected.",
    noDiariesInStudy: "No diaries available in this group.",
    noMoreDiariesInStudy: "No more diaries available in this group.",
    schedule: "Default schedule for diary (notifications)",
    scheduleHint: "Defines when reminders to fill out the diary will be sent. The diary can be filled out as often as needed regardless.",
    perWeek: "times per week",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    unsavedChangesConfig: "intervention configuration",
    skills: "Skills",
    skill: "Skill",
    createSkill: "New Skill",
    createSkillHeader: 'Create skill for intervention "{name}"',
    createSkillLoad: "Creating skill..",
    createSkillSuccess: "Skill created.",
    selectSkillColor: "Skill color",
    useThemeColor: "Color depending on theme",
    selectColor: "Select color",
    skillIcon: "Skill icon",
    skillIconType: "SVG-File recommended",
    selectSkillIcon: "Select skill icon",
    removeSkillIcon: "Remove skill icon",
    skillDetails: "Skill details",
    skillDescription: "Skill description",
    updateSkillDetailsLoad: "Saving skill details..",
    updateSkillDetailsSuccess: "Skill details saved.",
    updateSkillElementsLoad: "Saving skill description..",
    updateSkillElementsSuccess: "Skill description saved.",
    updatePositionsSkillsLoad: "Updating positions of the skills..",
    updatePositionsSkillsSuccess: "Positions of the skills updated.",
    updateSkills: "Define skills",
    updateSkillsText: "Define skills for lessons",
    updateSkillsLessonsLoad: "Saving skills for lessons..",
    updateSkillsLessonsSuccess: "Skills for lessons saved.",
    unsavedChangesSkillsLeave: "The skills have unsaved changes. Save the changes or click Cancel again.",
    unsavedChangesSkillsLeaveInterventionEditor: "The skills have unsaved changes. Click Save or Cancel.",
    skillsForLesson: "Skills for lesson",
    noSkillsSelected: "No skills selected",
    addSkills: "Add skills",
    selectSkill: "Select skill",
    noMoreSkills: "No more skills available",
  },
  interventionPreviewTranslation: {
    start: "Start",
    outro: "Outro",
    selectLesson: "Select lesson..",
    startIntervention: "Start intervention",
  },
  lessonTranslation: {
    createLesson: "New lesson",
    createLessonHeader: 'Create lesson in intervention "{name}"',
    createLessonLoad: "Creating lesson..",
    createLessonSuccess: "Lesson created.",
    copyLesson: 'Copy lesson "{name}"',
    copyInstruction: "Select an intervention to copy the lesson.",
    copyLessonLoad: "Copying lesson..",
    copyLessonSuccess: "Lesson copied.",
    languagesLesson: "Languages of lesson",
    lessonDetails: "Lesson details",
    lessonElements: "Lesson elements",
    selectElements: "Select elements",
    selectElementsAbort: "Clear element selection",
    copyElements: "Copy elements",
    selectIntervention: "First, select an intervention to copy the elements.",
    selectDiary: "Select a diary to copy the elements.",
    selectLesson: "Now, select a lesson.",
    backToInterventionSelection: "Back to intervention selection",
    openAll: "Open all",
    closeAll: "Close all",
    toggleDragDropTrue: "Show drag & drop",
    toggleDragDropFalse: "Hide drag & drop",
    selectPage: "Select page",
    page: "Page",
    allPages: "All pages",
    addNewElement: "Add new element",
    addNewElementText: "Add elements via + or drag & drop",
    addElementsDragDrop: "Add elements via drag & drop",
    addHeadline: "Headline",
    addText: "Text",
    addMedia: "Media",
    addQuestion: "Question",
    addPage: "Page",
    addLine: "Line",
    addSpace: "Space",
    addTable: "Table",
    addBlock: "Block",
    saveLesson: "Save elements",
    checkLesson: "Check elements",
    unsavedChangesLessonElements: "The lesson elements have unsaved changes.",
    updateLessonElementsLoad: "Saving lesson elements..",
    updateLessonElementsSuccess: "Lesson elements saved.",
    checkElementsLoad: "Checking lesson elements..",
    checkElementsComplete: "All elements are complete",
    checkElementsIncomplete: "The elements are incomplete, see marks",
    updateLessonDetailsLoad: "Saving lesson details..",
    updateLessonDetailsSuccess: "Lesson details saved.",
    copyLessonElementsLoad: "Copying lesson elements..",
    copyLessonElementsSuccess: "Lesson elements copied.",
    errorElements: "The elements are incomplete or incorrect. Correct the errors and save again. Note: errors can also be on other pages than the current one.",
    errorElementsLabels: "The elements incomplete or incorrect. All question labels must be different. Correct the errors and save again. Note: errors can also be on other pages than the current one.",
    warningDeleteQuestion: "The question is used in at least one complex block condition. If deleted, the conditions are incomplete and require a revision.",
    warningDeleteBlockQuestion: "The block has at least one question, which is used in a complex block condition. If deleted, the conditions are incomplete and require a revision.",
    warningeDeletePage: "All elements of the page will be deleted as well. If questions on this page are used in conditions or references on other pages, these will become incomplete and will require a revision",
    deleteQuestionWarning: 'Are you sure to delete question "{name}"?',
    deleteBlockWarning: 'Are you sure to delete this block?',
    deletePageWarning: "Are you sure to delete this page?",
    errorElementsCondition: "The question elements are incomplete. Correct the errors and try again. Note: errors can also be on other pages than the current one.",
    errorElementsLabelsCondition: "The elements incomplete or incorrect. All question labels must be different. Correct the errors and try again. Note: errors can also be on other pages than the current one.",
    removeElement: "Remove element",
    duplicateElement: "Duplicate element",
    changePosition: "Change position",
    changePositionInBlockUp: "Move element in block above",
    changePositionInBlockDown: "Move element in block below",
    changePositionOutBlockUp: "Move element above block",
    changePositionOutBlockDown: "Move element below block",
    dragAndDrop: "Change position via Drag and Drop",
    copyElementsErr: "The elements can not be copied.",
    copyElementsQuestionsMissing: "Some questions are needed for correct conditions/ repetitions/ references. Also copy the following questions:",
    copyElementsBlockOpenMissing: "You can not copy the end of a block without the begin of the block.",
    copyElementsBlockCloseMissing: "You can not copy the begin of a block without the end of the block.",
    saveElementsPrompt: "Save the lesson elements before copying.",
    saveDiaryElementsPrompt: "Save the diary elements before copying.",
    saveDetailsPrompt: "Save the lesson details before copying.",
    saveDiaryDetailsPrompt: "Save the diary details before copying.",
    saveElementsAndDetailsPrompt: "Save the lesson elements and details before copying.",
    saveDiaryElementsAndDetailsPrompt: "Save the diary elements and details before copying.",
    back: "Back",
    previousPage: "Previous page",
    nextPage: "Next page",
    endLesson: "Finish lesson",
    startLesson: "Fill out",
    selectDefaultPageColor: "Default page background",
    selectColor: "Single color",
    selectBackground: "Picture",
    questionsRequired: "Enable submitting lesson without answering required questions",
    questionsRequiredExplanationNo: "Meaning: Note if required questions not answered when clicking on next page button. Lesson can be submitted.",
    questionsRequiredExplanationYes: "Meaning: Warning if required questions not answered when clicking on next page button. Lesson can not be submitted.",
    errorPreviewNoElements: "The lesson has no elements.",
    errorPreviewFirstElementPage: "The first lesson element can not be a page break.",
    deletePage: "Delete whole page",
    allConditions: "Show all conditional content",
    allConditionsTip: "All conditions are considered fulfilled, regardless of the answers of the questions",
    ignorePageConditions: "Do not evaluate page conditions",
    ignorePageConditionsTip: "All pages are shown, regardless of whether the page condition is fulfilled or not",
    questionsRequiredCheck: "Hinweise zu erforderlichen Fragen",
    questionsRequiredTip: "On: Notes on page change and submit of lesson are shown as in patient app; Off: For easier content viewing",
    warning: "Warning",
    warningUnansweredRequiredQuestionsNextPage: "There are unanswered yet mandatory questions on this page. Want to continue?",
    warningUnansweredRequiredQuestionsSubmit: "There are unanswered yet mandatory questions in this lection. First, answer them before completing this lesson.",
    warningUnansweredNonRequiredQuestionsNextPage: "There are unanswered questions on this page. Want to continue?",
    warningUnansweredNonRequiredQuestionsSubmit: "There are unanswered questions in this lection. Sure to submit?",
    continue: "Continue",
    removedOldConditionalPage: "{counter} page break(s) in block(s) was/were removed (on page(s) {pages}). Please use the condition of the page element instead.",
    selectPreviewSize: "Select screen size..",
    selectPreviewSizeDesktop: "Desktop / Tablet landscape",
    selectPreviewSizeMobile: "Mobile",
    selectPreviewSizeTablet: "Tablet portrait",
  },
  elementsBlockTranslation: {
    typeOfBlock: "Type of block",
    selectTypeOfBlock: "Select type of block",
    typeConditional: "Conditional block",
    typeDetails: "Details block",
    typeBoth: "Conditional details block",
    textForDetails: "Text for details block",
    textForDetailsTranslation: "Translation of text for details block",
    beginBlock: "Begin of block",
    blockFor: "Block for",
    blockForWithDetails: "Details block for",
    condition: "Condition to show block",
    selectLabel: "Select label..",
    answeredWith: "answered with",
    answeredAny: "answered with any value",
    selectAnswer: "Select answer..",
    updateLabellistManually: "Update label list manually",
    endBlock: "End of block",
    errorLabel: "Select a label from the list. If the label list is empty, create a question first.",
    errorTypeEmpty: "Block type",
    errorValueEmpty: "Answer of question",
    errorOperationEmpty: "Question operation",
    errorSecondValueEmpty: "Second answer of question",
    errorValueSecondValue: "The first value has to be smaller than the second.",
    errortextDetailsEmpty: "Text of details block",
    complexCondition: "Complex condition",
    simpleCondition: "Simple condition",
    defineCondition: "Define condition",
    changeCondition: "Update condition",
    noQuestions: "If the label list is empty, create a question first.",
    errorComplexCondition: "The complex condition is incomplete and requires a revision",
    conditionDescription: "Description of condition",
    repetition: "Repetition",
    repetitionInstruction: "Number, how often the content of the block will be shown",
    constant: "constant",
    dependingOnQuestion: "Answer of slider question",
    errorRepetitionMin: "Number of repetitions (greater 0)",
    errorRepetitionLabel: "Answer reference for repetition",
    noneBlock: "Block",
    repetitionContent: "Content shown",
    repetitionDisplayed: "times",
    repetitionQuestionContent: "Content shown as many times as answer of",
    none: "Only repetition",
    typeNone: "Repetition block",
    dateFormat: "YYYY-MM-DD",
    timeHours: "hh",
    timeMinutes: "mm",
    placeholderTime: "hh:mm",
    placeholderDate: "mm.dd.yyyy",
    sum: "Sum",
  },
  elementsHeadlineTranslation: {
    headline: "Headline",
    headlineTranslation: "Translation of headline",
    selectHeadlineFontsize: "Select fontsize of headline..",
    headlineType0: "None",
    headlineType1: "Headline 1",
    headlineType2: "Headline 2",
    headlineType3: "Headline 3",
    headlineType4: "Headline 4",
    headlineType5: "Headline 5",
    headlineType6: "Headline 6",
    headlineFontsize: "Fontsize of headline",
    selectHeadlineColor: "Color of headline",
  },
  elementsPageTranslation: {
    pageConfig: "Configuration of page",
    selectPageColor: "Background of page",
    defaultColor: "Default (lesson details)",
    addCondition: "Add condition",
    removeCondition: "Remove condition",
    condition: "Condition to show page",
    conditional: "conditional",
    deletePageBreak: "Delete page break",
    progressbarText: "Text for progress bar",
    progressbarTextTranslation: "Translation of text for progress bar",
  },
  elementsLineTranslation: {
    line: "Dividing line",
  },
  elementsSpaceTranslation: {
    space: "Space",
    size: "Size",
    selectSize: "Select size..",
    errorSize: "Select size",
  },
  elementsMediaTranslation: {
    media: "Media",
    title: "Title",
    titleTranslation: "Translation of title",
    file: "File",
    sameFile: "Same file as translation",
    upload: "Upload",
    selectMedia: "Select media",
    selectPicture: "Select picture",
    selectType: "Select type",
    all: "All types",
    image: "Image",
    video: "Video",
    audio: "Audio",
    application: "File",
    description: "Description",
    descriptionTranslation: "Translation of description",
    errorSelectMedia: "Select media",
    uploadFileError: "Upload media",
    uploadFileTranslationError: "Upload a file or use the file of the translation.",
    uploadStudyMediaLoad: "Uploading media..",
    uploadStudyMediaSuccess: "Media uploaded.",
    noMedia: "No media available.",
    noMediaTooltip: "Media can be uploaded via the file input above.",
    size: "Width (%)",
    banner: "Banner",
    isAudio: "Audio only",
    sizeValidValue: "Width (valid value between 0 and 100)",
    fileNotSupported: "The type of the selected file is not supported. The following file types are supported: jpeg, jpg, png, gif, svg, wav, m4a, mp3, mp4, pdf, doc, docx, odt, ppt, pptx.",
    selectMediaDelete: "Select media",
    selectMediaAbort: "Clear media selection",
    deleteMedia: "Delete media",
    deleteMediaLoad: "Deleting media..",
    deleteMediaSuccess: "Media deleted..",
    deleteWarning: "Are you sure to delete the selected media?",
    deleteWarningText: "The media will also be deleted from interventions, diaries and lessons of the group.",
    favorite: "Favorite",
    notFavorite: "No favorite",
    favoriteNoFavorite: "Favorite & no favorite",
    updateMediaAttributesLoad: "Saving changes..",
    updateMediaAttributesSuccess: "Changes saved.",
    errorTooLarge: "One or more files exceed the limit of 150 MB.",
    recommendedMaxSize: "Recommended maximum file size: 50 MB",
    supportedFileTypes: "Supported file types: jpeg, jpg, png, gif, svg, wav, m4a, mp3, mp4, pdf, doc, docx, odt, ppt, pptx",
  },
  elementsTextTranslation: {
    text: "Text",
    textType: "Select type of text..",
    textTypeDesc: "Type of text",
    none: "None",
    important: "Important",
    tip: "Tip",
    info: "Info",
    success: "Success",
    highlight: "Highlight",
    errorFillIn: "Fill in the following fields: ",
    errorFillInOne: "Fill in at least on of the following fields: ",
    errorOneTranslation: "in at least one language",
    errorOtherPictures: "Remove external pictures and upload these on Manage Media page",
    font: "Select font",
    header: "Select header",
    bold: "Bold",
    italic: "Italic",
    underline: "Underline",
    strike: "Strike",
    align: "Left-aligned",
    alignCenter: "Centered",
    alignRight: "Right-aligned",
    alignJustify: "Justified",
    blockquote: "Quote",
    listOrdered: "Numbered list",
    listBullet: "Unnumbered list",
    scriptSub: "Subscript",
    scriptSuper: "Superscript",
    indentMinus: "Indent left",
    indentPlus: "Indent right",
    color: "Font color",
    background: "Background color",
    link: "Add link",
    image: "Add image",
    clean: "Delete formatting",
    expand: "Expand",
    reference: "Reference",
  },
  elementsTableTranslation: {
    table: "Table",
    header: "Headline row",
    row: "Row",
    column: "Column",
    expand: "Expand/Shrink",
  },
  elementsQuestionTranslation: {
    yesNoSwitch: "Question with Yes-No-Answer",
    singleChoice: "Single choice question",
    multipleChoice: "Multiple choice question",
    slider: "Question with slider answer",
    textDate: "Question with date answer",
    textTime: "Question with time answer",
    textDateTime: "Question with date and time answer",
    textString: "Question with short text answer",
    textArea: "Question with long text answer",
    invalidQuestionTable: "Answer options and Questions",
    questionTable: "Question table",
    question: "Question",
    questions: "Questions",
    questionType: "Question type",
    selectQuestionType: "Select question type..",
    questionLabel: "Question label",
    reference: "Reference for conditional contents",
    questionTranslation: "Translation of question",
    answer: "Answer",
    answerExample: "Answer example",
    answerOptions: "Answer options",
    answerOption: "Answer option",
    answerLabel: "Answer label",
    answerTranslation: "Translation of answer",
    addAnswerOption: "Add more answer options",
    addQuestion: "Add more questions",
    valueMin: "Minimum value",
    valueMax: "Maximum value",
    labelMinMax: "label (max. 40 characters)",
    labelMinMaxTranslation: "Translation of label (max. 40 characters)",
    step: "Step",
    questionRequired: "Question required?",
    answerfield: "Answer field",
    singleMultipleChoiceError: "A single or multiple choice question must have at least two answer options.",
    tableAnswersError: "A question table must have at least two answer options.",
    tableQuestionsError: "A question table must have at least two questions.",
    errorAnswerValueEmpty: "Label of answer option",
    errorDuplicateAnswerLabel: "distinct labels of answer options",
    errorSliderMinTextValueEmpty: "Label of minimum value",
    errorSliderMaxTextValueEmpty: "Label of maximum value",
    errorSliderTooManyValues: "There can be no more than 101 values. Change the slider step or the minimum or maximum value.",
    errorDuplicateLabel: "This question label is already used for another question.",
    errorRepetition: "This slider question can not be repeated because another repetition number is depending on this question. Set the repetition on the outer block to 1 or change the repetition that uses the slider question as repetition number.",
    generateQuestionLabel: "Generate",
    removeAnswer: "Remove answer",
    removeQuestion: "Remove question",
    dateFormat: "l, F j, Y",
    dateFormatCondition: "F j, Y",
    timeFormat: "G:i K",
    ampm: "true",
    errorSingleMultiple: "The number of answers and labels is not equal",
    startValue: "Start value",
    startValueNotIncluded: "The start value is not included.",
    minGreaterMax: "The minimal value is greater than the maximum value.",
    minIsMax: "The minimal value and the maximum value have to be different.",
    selectDate: "Please select",
    time: "",
  },
  appTranslation: {
    topButton: "Back to top",
  },
  resetPasswordTranslation: {
    to: "To",
    resetPasswordInstructions: "Enter your email to get a link to change your password.",
    verifyEmail: "Verify email",
    resetPasswordLoad: "Sending email to change password..",
    resetPasswordSuccess: "Password reset instructions have been sent to the email provided, if the email is available in the system in this spelling: ",
    resetPasswordSuccessHint: "If no email was received, please check the spelling.",
    send: "Send",
  },
  permissionsTranslation: {
    permissionsInstructions: "Here the permissions for the group are set.",
    ownPermissionsNotEditable: "You can not change your own permissions.",
    permissionsNotEditable: "You do not have permissions to update the group permissions.",
    collab: "Users with permissions",
    nonCollab: "All users",
    owner: "Owner",
    editPermission: "Permission to edit",
    copyPermission: "Permission to copy",
    noPermission: "No access",
    eCoach: "E-Coach",
    eCoachManager: "E-Coach manager",
    updateStudyPermissionsLoad: "Saving permissions..",
    updateStudyPermissionsSuccess: "Permissions saved.",
    unsavedChangesPermissions: "The permissions have unsaved changes.",
    leaveSiteWarning: "Do you want to leave this view?",
    chooseUsers: "Select view..",
    noUsers: "No users available",
    permissionExplanation: "Owner: all functions in group; Permissions to edit: limited functions (no editing of permissions, no deleting/publishing of content); Permission to copy: view content & copy in other group, no editing of content; No access: group & intervention visible, no details accessible",
  },
  httpHelperTranslation: {
    errorCode2: "Insufficient role.",
    errorCode3: "Wrong request type.",
    errorCode4: "Entity not found.",
    errorCode5: "Entity already exists.",
    errorCode6: "Entity not active.",
    errorCode7: "Entity not found.",
    errorCode10: "Path not allowed.",
    errorCode11: "Invalid credentials.",
    errorCode12: "Please sign in again.",//Auth-Token error.
    errorCode13: "Please sign in again.",//Auth-Token expired.
    errorCode14: "Please sign in again.",//Auth-Token blacklisted.
    errorCode20: "Please sign in again.",//Token missing.
    errorCode21: "Please sign in again.",//Token expired.
    errorCode22: "Reset-Token missing.",
    errorCode23: "Reset-Token expired.",
    errorCode24: "Verification-Token missing.",
    errorCode25: "Verification-Token expired.",
    errorCode30: "Email not yet verified.",
    errorCode31: "Email already verified.",
    errorCode32: "Email address or name already exists.",
    errorCode40: "Unsupported media format.",
    errorCode41: "Base64 encoding error.",
    errorCode42: "Media not found.",
    errorCode82: "Lesson is active.",
    errorCode83: "Required data of lesson missing.",
    errorCode84: "Unequal number of blocks.",
    errorCode85: "Intervention is active.",
    httpErrorCode500: "Internal Server Error.",
    httpErrorCode503: "Service Unavailable.",
    httpErrorCode504: "Gateway Timeout.",
    error: "An error occurred: ",
    registerErrorEmailInvalid: "Email address invalid.",
    registerErrorEmailDuplicate: "There is an account for this email address already.",
    errorLoginAgain: "Please sign in again.",
    errorInvalidEmail: "Please insert a valid email address.",
    wrongPassword: "Invalid password",
    tooManyLogins: "Too many login attempts. Wait 1 minute before retrying.",
    usernameAlreadyExists: "The username already exists. Please enter a different one.",
    passwordDataLeak: "The entered password has appeared in a data leak. Please enter a different one.",
    configDiaryNotIncluded: "A diary selected in a condition is not part of this intervention. Please add it to the intervention or one of the lessons or select a different diary.",
  },
  conditionEditorTranslation: {
    addNewItems: "Add Items via Drag and Drop",
    condition: "Condition",
    clipboard: "Clipboard",
    testCondition: "Test Condition",
    testConditionDescription: "The condition can be tested by selecting answers for the various questions. Questions can stay unanswered.",
    test: "Test",
    hideTest: "Hide test",
    errorCondition: "For testing the condition must be complete. Drop conditons or blocks in dotted areas. For conditions select labels, answers and operations, where necessary.",
    questionAnswered: "Question answered",
    with: "with",
    apply: "Apply",
    dragAndDropArea: "Add Question-, AND-, OR- and NOT-blocks via Drag and Drop.",
    clipboardArea: "Drop conditions or blocks to store temporarily.",
    errorConditionNotComplete: "The condition is incomplete. Drop conditons or blocks in dotted areas. For conditions select labels, answers and operations, where necessary.",
    tooltipQuestion: "Defines a single condition.",
    tooltipAnd: "'AND' combines two or more conditions or blocks. An AND block is evaluated to true if ALL conditions or blocks within the block are true.",
    tooltipOr: "'OR' combines two or more conditions or blocks. An OR block is evaluated to true if AT LEAST ONE condition or block within the block is true.",
    tooltipNot: "A NOT block negates the condition or block within.",
    and: "AND",
    or: "OR",
    not: "NOT",
    selectOperation: "Select operation",
    between: "between",
    betweenAnd: "and",
    nextField: "additional field",
    conditionString: "The condition holds if: ",
    questionString: "the question",
    withString: "is answered with",
    withAnyString: "is answered with any value",
    operationString: "is answered with a value",
    answeredString: "",
    less: "less",
    lessEqual: "less than or equal to",
    greater: "greater",
    greaterEqual: "greater than or equal to",
    equals: "equal to",
    notEquals: "not equal",
    unsavedChangesCondition: "The condition has unsaved changes",
    unsavedChangesConditionLessonElements: "The lesson elements and the condition have unsaved changes",
    codeView: "Code editor",
  },
  codeViewTranslation: {
    selectSnippet: "Click in code editor or select text and then select a snippet to add at this position",
    insert: "Insert",
    values: "Values for selected questionLabel (also usable for secondValue)",
    selectValue: "Select value",
    showExplanation: "Show explanation",
    correctErrors: "Errors",
    clickOrSelect: "Click in code editor or select text before inserting a snippet.",
    questionLabels: "QuestionLabels",
    operations: "Operations",
    leafTypes: "LeafTypes",
    leafTypeToQuestionLabel: "leafType to questionLabel",
    hasToBe: "has to be:",
    valueToQuestionLabel: "value to questionLabel",
    hasToBeOneOf: "has to be one of:",
    secondValueToQuestionLabel: "secondValue to questionLabel",
    hasToBeValidDate: "has to be a valid date in format YYYY-MM-DD",
    questionLeaf: "Question",
    leafType: "type of question",
    question: "Yes-No/Single/Multiple",
    slider: "Slider",
    date: "Date",
    text: "Text",
    none: "None",
    addAtLeastTwo: "add at least two AND-/OR-/NOT-/Question-blocks here",
    separated: "separated by , ",
    addExactlyOne: "add exactly one AND-/OR-/NOT-/Question-block here",
    oneOfLeafType: "one of:",
    label: "Label",
    ifNotOperationAnswered: 'only if operation not "answered"',
    ifQuestion: 'if question: true/false/"label"',
    ifSlider: "if slider: number",
    ifDate: 'if date: string like "2019-10-02"',
    ifBetween: 'only if operation is "between"',
    codeSnippets: "Code snippets",
    editorView: "Editor view",
    questionLabel: "The label",
    isNotValid: "is not valid",
    errorSave: "Errors occurred when saving (see below). Correct the errors and save again.",
    errorSwitchCondition: "Errors occurred (see below). Correct the errors to switch to editor view.",
    errorSnippet: "Errors occurred (see below).",
    conditionEmpty: "The condition must not be empty.",
    inLine: "in line",
    valueInstruction: "Select a questionLabel to show corresponding values.",
    ifNotQuestion: "if slider or date",
    forAll: "for all",
    forQuestion: "for question",
  },
  referenceTranslation: {
    insertReference: "Insert reference",
    insert: "Insert",
    referenceFor: "Reference for question",
    answerOf: "Answer of",
  },
  adminUsersTranslation: {
    manageUsers: "Manage users",
    allUsers: "All users",
    deletionRequests: "Account deletion requests",
    edit: "Edit",
    changeRoles: 'Edit roles for user "{email}"',
    unsavedChangesRoles: "The roles have unsaved changes. Save the changes or click Cancel again.",
    saveRolesLoad: "Saving roles..",
    saveRolesSuccess: "Roles saved.",
    verified: "Email verified",
    notVerified: "Email not yet verified",
    verifiedNotVerified: "Email verified & not verified",
    createAccount: "Create new account",
    role: "Role",
    patient: "Patient",
    editor: "Editor",
    ecoach: "E-Coach",
    admin: "Admin",
    allRoles: "All roles",
    username: "Username",
    firstname: "Firstname",
    lastname: "Lastname",
    errorFillIn: "Fill in the following fields: ",
    createAccountLoad: "Creating account..",
    createAccountSuccess: "Account created. A verification email was sent.",
    resendVerification: "Resend verification email",
    resend: "Resend",
    resendVerificationLoad: "Sending verification email..",
    resendVerificationSuccess: "Verification email sent.",
    adminFunctions: "Admin Functions",
    deleteWarning: 'Are you sure to delete user "{email}"?',
    deleteUserLoad: "Deleting user..",
    deleteUserSuccess: "User deleted.",
    noDeletionRequests: "No account deletion requests available",
    userDetails: "User",
    studiesOfUser: "Studies of user",
    eCoachManager: "eCoachManagers",
    data: "Data",
    deleteAll: "Delete completely",
    pseudonymize: "Pseudonymize",
    dataDeleted: "The user data will be deleted completely.",
    dataPseudonymized: "The user data will be pseudonymized.",
    userHasNoStudies: "The user is not part of a study",
    acceptDeletion: "Accept deletion request",
    declineDeletion: "Decline deletion request",
    acceptAccountDeletionLoad: "Accepting deletion request..",
    acceptAccountDeletionSuccess: "Deletion request accepted. The user will receive a confirmation email.",
    declineWarning: 'Are you sure to decline the deletion request for user "{email}"?',
    declineText: "The user account will not be deleted.",
    decline: "Decline",
    declineAccountDeletionLoad: "Declining deletion request..",
    declineAccountDeletionSuccess: "Deletion request declined. The user will receive an email regarding decline.",
    acceptDecline: "Accept/ decline request",
  },
  preferencesTranslation: {
    preferences: "Preferences",
    changeAppLanguage: "Change the language of the page",
    appLanguageText: "The content language can be changed in groups, interventions, diaries and lessons.",
    profile: "Account details",
    updateProfileLoad: "Saving account details..",
    updateProfileSuccess: "Account details saved.",
    notEditable: "not editable",
    roles: "Roles",
    changePW: "Change password",
    passwordOld: "Old password",
    passwordNew: "New password",
    passwordNewConfirm: "Confirm new password",
    change: "Change",
    changePWLoad: "Changing password..",
    changePWSuccess: "Password changed",
    changePWError: "The passwords do not match.",
  },
  diaryTranslation: {
    diaryDetails: "Diary details",
    updateDiaryDetailsLoad: "Saving diary details..",
    updateDiaryDetailsSuccess: "Diary details saved.",
    languagesDiary: "Languages of diary",
    participants: "Participants of diary",
    diaryElements: "Diary elements",
    diary: "Diary",
    deleteWarning: 'Are you sure to delete diary "{name}"?',
    deleteWarningUsed: "The diary is used in interventions or lessons of the group.",
    deleteDiaryLoad: "Deleting diary..",
    deleteDiarySuccess: "Diary deleted.",
    newDiary: 'Create diary in group "{name}"',
    createDiaryLoad: "Creating diary..",
    createDiarySuccess: "Diary created.",
    copyDiary: 'Copy diary "{name}"',
    copyDiaryLoad: "Copying diary..",
    copyDiarySuccess: "Diary copied.",
    copyInstruction: "Select a group to copy the diary.",
    exportDiaryLoad: "Exporting diary..",
    exportDiarySuccess: "Diary exported.",
    importDiary: 'Import diary',
    importDiaryHeader: 'Import diary into group "{name}"',
    selectImport: "JSON file with exported diary",
    importDiaryLoad: "Importing diary..",
    importDiarySuccess: "Diary imported.",
    publishDiarySuccess: "Diary published in study of E-Coach Platform.",
    diaryNotComplete: "The diary was not created correctly. Please delete this diary and create a new one.",
    diaryInstruction: "Assigning a diary",
    diaryInstructionTooltip: "Assign a diary to an intervention/lesson via Intervention > Corresponding lessons > Update configuration",
  },
  adminAnnouncementsTranslation: {
    adminAnnouncements: "Manage announcements",
    createAnnouncement: "Create announcement",
    editAnnouncement: "Edit announcement",
    content: "Content",
    title: "Title DE",
    message: "Message DE",
    messageWithChars: "Message DE (max. 255 characters)",
    titleTranslation: "Title EN",
    messageTranslation: "Message EN",
    messageWithCharsTranslation: "Message EN (max. 255 characters)",
    type: "Type",
    success: "Success",
    info: "Info",
    warning: "Warning",
    error: "Error",
    creator: "Creator",
    clients: "Platforms",
    cms: "CMS",
    ecoach: "eCoach platform",
    app: "Patient app",
    date: "Validity",
    startDate: "Start date",
    endDate: "End date",
    errorFillIn: "Fill in the following fields: ",
    createAnnouncementLoad: "Creating announcement..",
    createAnnouncementSuccess: "Announcement created.",
    updateAnnouncementLoad: "Saving announcement..",
    updateAnnouncementSuccess: "Announcement saved.",
    dateTimeFormat: "F j, Y, G:i K",
    deleteWarning: 'Are you sure to delete announcement "{name}"?',
    deleteAnnouncementLoad: "Deleting announcement..",
    deleteAnnouncementSuccess: "Announcement deleted.",
    selectType: "Select type..",
    selectClient: "Select platform..",
    shown: "Only valid",
    noAnnouncements: "No announcements available",
    unsavedChanges: "The announcement has unsaved changes. Save the changes or click Cancel again.",
    allTypes: "All types",
    allPlatforms: "All platforms",
  }
};
