<template>
  <div class="lessonEditorDetails">
    <section :style="{visibility: showIfNotTranslation}">
      <label>
        {{ 'interventionTranslation.name' | translate }} *
      </label>
      <input
        type="text"
        :value="lessonDetails.name"
        id="inputName"
        :placeholder="$t('interventionTranslation.name')"
        :disabled="!getIsEditable"
        @input="$emit('change-attribute', {'name': 'name', 'value': $event.target.value})"
      >
    </section>

    <label>
      {{titleTextInLanguage}} *
    </label>
    <input
      type="text"
      :value="translation.title"
      :id="'inputTitle' + (isTranslation ? 'Translation' : '')"
      :placeholder="titleTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', {'name': 'title', 'isTranslation': isTranslation, 'value': $event.target.value})"
    >

    <label>
      {{descriptionTextInLanguage}} *
    </label>
    <textarea
      :value="translation.description"
      :id="'inputDescription' + (isTranslation ? 'Translation' : '')"
      :placeholder="descriptionTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', {'name': 'description', 'isTranslation': isTranslation, 'value': $event.target.value})"
    />

    <section v-if="!isTranslation">
      <section class="wrapper">
        <label>
          {{ 'lessonTranslation.languagesLesson' | translate }} *
        </label>
        <ul>
          <li v-for="(languageOption,index) in lessonDetails.locales" :id="'language' + languageOption" :key="languageOption">
            {{languageOption}}
            <i
              v-if="getIsEditable"
              @click="deleteLanguage(index)"
              :id="'buttonDeleteLanguage' + languageOption"
              :title="$t('lessonTranslation.removeElement')"
              class="fa fa-trash"
              data-toggle="tooltip"
            ></i>
          </li>
        </ul>
      </section>

      <section v-if="getIsEditable && lessonDetails.locales">
        <label>
          {{ 'interventionTranslation.addLanguage' | translate }}
        </label>
        <form class="buttonForm" @submit.prevent="updateLanguages">
          <select v-model="selectedLanguageToAdd" id="inputLanguage" class="addLanguageSelect buttonForm">
            <option value="" id="inputLanguageNone" disabled>
              {{ 'generalTranslation.chooseLanguage' | translate }}
            </option>
            <option
              v-for="language in getAvailableLocales.filter(language => !lessonDetails.locales.includes(language))"
              :id="'inputLanguage' + language" :key="language"
            >
              {{language}}
            </option>
          </select>
          <button type="submit" id="buttonAddLanguage" :disabled="selectedLanguageToAdd === ''" class="buttonMiddle2">
            {{ 'interventionTranslation.add' | translate }}
          </button>
        </form>
      </section>

      <section class="wrapper">
        <label>
          {{ 'lessonTranslation.selectDefaultPageColor' | translate }} *
        </label>

        <BaseSelectBackground
          :pageColor="lessonDetails.page_color"
          :studyId="studyId"
          @change-attribute="$emit('change-attribute', $event)"
          @show-modal="$emit('show-modal', $event)"
        ></BaseSelectBackground>

        <section class="wrapper">
          <label>
            {{ 'lessonTranslation.questionsRequired' | translate }} *
          </label>
          <select :value="lessonDetails.questions_required" id="inputQuestionsRequired" :disabled="!getIsEditable"
              @input="$emit('change-attribute',{'name': 'questions_required', 'value': Number($event.target.value)})">
            <option value="" id="inputQuestionsRequiredNone" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option :value="1" id="inputFinishWithoutQuestionsRequiredFalse" selected>
              {{ 'generalTranslation.no' | translate }}
            </option>
            <option :value="0" id="inputFinishWithoutQuestionsRequiredTrue">
              {{ 'generalTranslation.yes' | translate }}
            </option>
          </select>
          <i class="far fa-question-circle" data-toggle="tooltip" :title="explanationText"></i>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import BaseSelectBackground from './BaseSelectBackground.vue';

export default {
  name: 'LessonEditorDetails',

  components: {
    BaseSelectBackground
  },

  props: {
    lessonDetails: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    }
  },

  data: function(){
    return {
      selectedLanguageToAdd: '',
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
      'getAvailableLocales',
      'getSelectedLanguage',
    ]),

    explanationText: function(){
      if(this.lessonDetails.questions_required === 1){
        return Vue.i18n.translate('lessonTranslation.questionsRequiredExplanationYes')
      }else{
        return Vue.i18n.translate('lessonTranslation.questionsRequiredExplanationNo')
      }
    },

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    titleTextInLanguage: function(){
      return Vue.i18n.translate('interventionTranslation.title' + (this.isTranslation ? 'Translation' : ''))
    },

    descriptionTextInLanguage: function(){
      return Vue.i18n.translate('interventionTranslation.description' + (this.isTranslation ? 'Translation' : ''))
    },
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    updateLanguages: function(){
      if(this.selectedLanguageToAdd != ""){
        this.closeNotification();
        var arr = JSON.parse(JSON.stringify(this.lessonDetails.locales));
        arr.push(this.selectedLanguageToAdd);
        this.selectedLanguageToAdd = "";
        this.$emit('change-attribute', {'name': 'locales', 'value': arr});
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.selectLanguageWarning')});
      }
    },

    deleteLanguage: function(index){
      var arr = JSON.parse(JSON.stringify(this.lessonDetails.locales));

      if((arr[index] === this.getSelectedLanguage) || (arr[index] === this.selectedLanguageForTranslation) && this.isTranslationMode){
        this.$emit('show-modal', { type: 'LanguageError' });
      }else{
        arr.splice(index, 1);
        this.$emit('change-attribute', {'name': 'locales', 'value': arr});
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },


  }
}
</script>
