<template>
  <div class="elementsHeadlinePreview">
    <div :id="'headlineTextPreview' + propsObj.id" :class="propsObj.selectedHeadlineProp" class="question-text headline"
        :style="{'color': propsObj.color}" align-items-start align-self-start>
      {{replaceReferences(translation)}} <!-- reference updates on create, translation change, getPreviewAnswers change -->
    </div>
  </div>
</template>

<script>
import questionConditionHelper from '../mixins/questionConditionHelper';

export default {
  name: 'ElementsHeadlinePreview',

  mixins: [questionConditionHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },
  },
}
</script>
