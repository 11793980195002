<template>
  <div class="elementsTablePreview">
    <section class="ulPreview">
      <section class="wrapper">
        <section class="question-text texttable" :class="{wideTable: numberOfColumns > 3}">
          <p>
            <span text-left v-html="translation"></span>
          </p>
        </section>
      </section>
    </section>
  </div>
</template>
<script>

export default {
  name: 'ElementsTablePreview',

  props: {
    translation: {
      required: true,
      type: String,
    },
  },

  computed: {
    numberOfColumns: function(){
      return this.translation.split("<tbody>")[1].split("<tr>")[1].split("<td>").length - 1
    }
  },
}
</script>
