<template>
  <div class="baseMenuBar">
    <section class="wrapper">
      <h6 id="navLinks" class="navigationNames">
        <template v-if="nameList.length > 1">
          <router-link :to="{ name: 'studyEditor', params: { studyId: idList[0] }}" id="navGroupName">
            {{nameList[0]}}
          </router-link>
          <i class="fas fa-angle-right"></i>
        </template>

        <template v-if="nameList.length > 2">
          <router-link :to="{ name: 'interventionEditor', params: { interventionId: idList[1] }}" id="navInterventionName">
            {{nameList[1]}}
          </router-link>
          <i class="fas fa-angle-right"></i>
        </template>

        {{nameList[nameList.length - 1]}}
      </h6>

      <h6 :id="'permissions-' + getMyRoleForStudy" class="permissionsName">
        <i class="far fas fa-pen" v-if="isCollaborator"></i>
        <i class="far fa-copy" v-else-if="isAccess"></i>
        {{permissionDescription}}
        <i class="far fa-question-circle fa-xs" v-if="hasNoPermissions" data-toggle="tooltip" :title="$t('myStudiesTranslation.receiveAccess')"></i>
      </h6>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';

export default {
  name: 'BaseMenuBar',

  props: {
    nameList: {
      required: true,
      type: Array,
    },

    idList: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
    ]),

    isCollaborator: function(){
      return (this.getMyRoleForStudy === "Collaborator")
    },

    isAccess: function(){
      return (this.getMyRoleForStudy === "Access")
    },

    hasNoPermissions: function(){
      return (this.getMyRoleForStudy === "None")
    },

    permissionDescription: function(){
      if(this.isCollaborator){
        return Vue.i18n.translate('permissionsTranslation.editPermission')
      }else if(this.isAccess){
        return Vue.i18n.translate('permissionsTranslation.copyPermission')
      }else if(this.hasNoPermissions){
        return Vue.i18n.translate('permissionsTranslation.noPermission')
      }else{
        return ""
      }
    },
  },
}
</script>
