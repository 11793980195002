<template>
  <div id="skillList" class="skillList">
    <section class="smallerComp">
      <section v-if="!loading">
        <BasePagination
          id="paginationSkillsTop"
          :numberOfPages="skillListData.allPages"
          :currentPage="skillListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <ul :class="{selectFromList: copyBool && !disableWhileCopy}">
          <li v-for="(skill, index) in skillListData.list" :id="'entrySkill' + skill.id" :key="skill.id" class="interventionElement"
              :class="{selectedForCopy: copyBool && selectedSkillId === skill.id}" @click="setSkillForCopy(skill.id)">
            <section class="wrapper">
              <section class="col interventionPositionSection">
                <section v-if="getIsEditable && !copyBool" class="changePos">
                  <i class="fa fa-chevron-up fa-2x" v-if="index > 0" :id="'buttonChangePositionSkillUp' + skill.id" data-toggle="tooltip" :title="$t('lessonTranslation.changePosition')"
                    @click="changePosition(skill.id, false)"></i>
                  <h3 v-if="skillListData.list.length > 1 || skillListData.currentPage != 1">
                    Position
                  </h3>
                  <i class="fa fa-chevron-down fa-2x" v-if="(skillListData.currentPage != skillListData.allPages)|| (index < skillListData.list.length - 1 && skillListData.currentPage === skillListData.allPages)"
                    :id="'buttonChangePositionSkillDown' + skill.id" data-toggle="tooltip" :title="$t('lessonTranslation.changePosition')" @click="changePosition(skill.id, true)"></i> <!-- change id to pos -->
                </section>
              </section>

              <section class="col interventionDescriptionSection">
                <section class="wrapper">
                  <section class="col homeImageSection">
                    <img v-if="skill.attributes.icon" :src="getPictureLink(skill.attributes.icon)">
                    <i class="far fa-image fa-2x" v-else></i>
                  </section>
                  <section class="col homeDescriptionSection">
                    <button type="button" :id="'buttonShowSkill' + skill.id" :disabled="hasNoPermissions || copyBool" class="linkButton" @click="updateSkillPage(skill.id, 1)">
                      {{index + 1}} {{skill.attributes.name}}
                    </button>

                    <p :style="[skill.attributes.color ? {'color': skill.attributes.color} : {'color': '#15417e'}]">
                      {{getSkillTitle(skill)}}
                    </p>
                  </section>
                </section>
              </section>

              <section v-if="!hasNoPermissions && !copyBool" class="col homeButtonSectionSmall">
                <i class="fa fa-info-circle fa-2x homeButtons" :id="'buttonShowSkillDetails' + skill.id" data-toggle="tooltip" :title="$t('myStudiesTranslation.details')"
                  @click="updateSkillPage(skill.id, 0)"></i>

                <!-- todo copy skill
                <i class="fa fa-copy fa-2x homeButtons" :id="'buttonCopySkill' + skill.id" data-toggle="tooltip" :title="$t('generalTranslation.copy')" @click="copySkill(skill.id, skill.attributes.name)" ></i>
                -->

                <i v-if="isOwner" class="fa fa-trash fa-2x homeButtons" :id="'buttonDeleteSkill' + skill.id" data-toggle="tooltip" :title="$t('generalTranslation.delete')"
                  @click="showModal(skill.id, skill.attributes.name)"></i>
              </section>
            </section>
          </li>
        </ul>

        <section v-if="!skillListData.list || skillListData.list.length === 0" id="noSkills" class="noLessons">
          <h4>
            {{ 'generalTranslation.noSkills' | translate }}
            <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('generalTranslation.noSkillsTooltip')"></i>
          </h4>
        </section>

        <BasePagination
          id="paginationSkillsBottom"
          :numberOfPages="skillListData.allPages"
          :currentPage="skillListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </section>

    <BaseModal
      v-if="deleteSkillVisible"
      id="deleteSkillModal"
      :headerText="$t('interventionTranslation.deleteSkillWarning', { name: skillNameForDeleting })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      :disableButtons="disableWhileCopy"
      @close-modal="closeModal"
    ></BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import BasePagination from './BasePagination.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: 'SkillList',

  mixins: [httpHelper, elementJSONToPropsHelper],

  components: {
    BaseModal,
    BasePagination,
  },

  props: {
    //true if used in copy elements functionality, false if used in Intervention
    copyBool: {
      required: true,
      type: Boolean,
    },

    //do not react in watch functions while copy lesson functionality is used
    disableWhileCopy: {
      required: true,
      type: Boolean,
    },

    interventionId: {
      required: true,
      type: Number,
    },

    interventionDetails: {
      required: true,
      type: Array,
    },

    skillListData: {
      required: true,
      type: Object,
    },

    studyId: {
      required: true,
      type: Number,
    },
  },

  data: function(){
    return{
      deleteSkillVisible: false,
      skillIdForDeleting: -1,
      skillIndexForDeleting: '',
      loading: true,
      skillNameForDeleting: "",
      selectedSkillId: "",
    }
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
      'getNotificationText',
      'getIsEditable'
    ]),

    isOwner: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    hasNoPermissions: function(){
      return (this.getMyRoleForStudy === "None")
    },
  },

  watch:{
    'skillListData.list': {
      handler: function(newVal){
        console.log(newVal)
        this.loading = false;
      },
      deep: true
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error" && !this.disableWhileCopy){
        this.loading = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_FORMELEMENTSCHANGED',
      'SET_LESSONELEMENTSARR',
      'SET_NOTIFICATIONTEXT',
    ]),

    updateSkillPage: function(skillId, view){
      this.closeNotification();
      this.SET_FORMELEMENTSCHANGED(false);
      this.SET_LESSONELEMENTSARR([]);
      this.$emit('route-to', { name: 'skillEditor', params: {skillId: skillId, view: view }});
    },

    copySkill: function(skillId, skillName){
      this.$emit('show-modal', { type: 'SkillCopy', id: skillId, name: skillName, studyId: this.studyId });
    },

    showModal: function(skillId, skillName){
      this.skillIdForDeleting = Number(skillId);
      this.skillNameForDeleting = skillName;
      this.deleteSkillVisible = true;
      this.$emit('show-modal', { type: 'SkillDelete', value: true });
    },

    closeModal: function(done){
      this.deleteSkillVisible = false;
      this.$emit('show-modal', { type: 'SkillDelete', value: false });

      if(done){
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.deleteSkillLoad')});

        var requestPage = this.skillListData.currentPage;
        if(this.skillListData.list.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteSkillRequest(this.skillIdForDeleting)
        .then(function (){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.deleteSkillSuccess')});
          self.loading = true;
          self.$emit('get-new-list', { page: requestPage, getDetails: true });
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModal(done) }, "");
        });
      }
    },

    //todo change skill pos
    //down = true -> number of position gets higher
    //down = false -> number of position gets lower
    changePosition: function(id, down){
      this.loading = true;
      this.closeNotification();
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updatePositionsSkillsLoad')});

      console.log(id)
      console.log(down)
      console.log(this.interventionDetails.gamification.skills_order)
      var skillsOrder = JSON.parse(JSON.stringify(this.interventionDetails.gamification.skills_order));

      var index = skillsOrder.findIndex(elem => Number(elem) === Number(id));
      if(index != -1){
        var oldPos;
        if(down){
          oldPos = skillsOrder[index + 1];
          skillsOrder[index + 1] = Number(id);
          skillsOrder[index] = Number(oldPos);
        }else{
          oldPos = skillsOrder[index - 1];
          skillsOrder[index - 1] = Number(id);
          skillsOrder[index] = Number(oldPos);
        }

        this.interventionDetails.gamification.skills_order = skillsOrder;
        console.log(this.interventionDetails.gamification.skills_order)
        this.updateDetails(skillsOrder)
      }else{
        this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updatePositionsSkillsSuccess')});
        this.loading = false;
      }
    },

    updateDetails: function(skillsOrder){
      var self = this;
      var details = {
        "data": {
          "type": "interventions",
          "attributes": {
            "gamification": {
                "skills_order": skillsOrder
            }
          }
        }
      };

      this.updateInterventionDetailsRequest(details, this.interventionId)
      .then(function (){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updatePositionsSkillsSuccess')});
        self.$emit('get-new-list', { page: self.skillListData.currentPage, getDetails: false });
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.updateDetails(details) }, "");
      });
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.closeNotification();
        this.loading = true;
        this.$emit('get-new-list', { page: selectedNumber, getDetails: false });
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    setSkillForCopy: function(skillId){
      if(this.copyBool){
        this.selectedSkillId = skillId;
        this.$emit('item-selected', Number(skillId));
      }
    },

    getSkillTitle: function(skill){
      return skill.attributes.title ? skill.attributes.title : Vue.i18n.translate('generalTranslation.noTitle')
    },

    getPictureLink: function(skillIcon){
      return this.$urlUploads + '/studies/' + this.studyId + '/' + skillIcon
    },
  }
}
</script>
