<template>
  <div id="studyList" class="studyList">
    <section class="smallerComp">
      <section v-if="!copyBool" class="wrapper">
        <section class="headlineClass">
          <h1>
            {{myOrMoreStudiesHeadline}}
            <i class="far fa-question-circle fa-xs" v-if="!isMyStudies" data-toggle="tooltip" :title="$t('myStudiesTranslation.moreStudiesExplanation')"></i>
          </h1>
        </section>
        <button type="button" v-if="!loading && isMyStudies" id="buttonCreateStudy" class="createButton" @click="$emit('show-modal', { type: 'StudyCreate' })">
          {{ 'generalTranslation.newStudy' | translate }}
        </button>
      </section>

      <BaseSearch
        v-show="!loading && (studies.length > 0 || noSearchResults)"
        id="searchStudies"
        type="study-list"
        @get-new-list="getNewList"
        @reset-page="$emit('reset-page')"
      ></BaseSearch>

      <section v-if="!loading">
        <BasePagination
          id="paginationStudiesTop"
          :numberOfPages="studyListData.allPages"
          :currentPage="studyListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <ul :class="{selectFromList: copyBool && !disableWhileCopy}">
          <li v-for="(study, index) in studies" :id="'entryStudy' + study.id" :key="study.id" class="interventionElement" :class="{selectedForCopy: copyBool && selectedStudyId === study.id}"
              @click="setStudyForCopy(study.id, studyRoles[index], study.attributes.name)">
            <section class="wrapper">
              <section class="col homeImageSection">
                <img v-if="study.attributes.picture && !isMoreStudies" :src="getPictureLink(study.attributes.picture, study.id)">
                <i class="far fa-image fa-2x" v-else></i>
              </section>
              <section class="col homeDescriptionSection">
                <button type="button" :id="'buttonShowStudy' + study.id" :disabled="copyBool" class="linkButton"
                    @click="getDetailsOfStudy(study.id, studyRoles[index], 2, study.relationships.owners.data)">
                  {{study.attributes.name}}
                </button>

                <p>
                  {{getStudyDescription(study)}}
                </p>

                <!-- permissions -->
                <section v-if="!copyBool && (studyRoles[index] === 'Collaborator' || studyRoles[index] === 'Access' || studyRoles[index] === 'None')"
                    :id="'permissionsStudy' + study.id" class="permissionSec">
                  <i class="far fas fa-pen" v-if="studyRoles[index] === 'Collaborator'" :id="'collabPermissionStudy' + study.id"></i>
                  <i class="far fa-copy" v-else-if="studyRoles[index] === 'Access'" :id="'accessPermissionStudy' + study.id"></i>
                  {{getPermissionText(index)}}
                </section>
              </section>

              <section v-if="!copyBool" key="nonCopySec" class="col homeButtonSectionMedium">
                <i class="fa fa-info-circle fa-2x homeButtons" :id="'buttonShowStudyDetails' + study.id" data-toggle="tooltip" :title="$t('myStudiesTranslation.details')"
                  @click="getDetailsOfStudy(study.id, studyRoles[index], 0, study.relationships.owners.data)"></i>

                <i v-if="studyRoles[index] != 'None'" class="fa fa-copy fa-2x homeButtons" :id="'buttonCopyStudy' + study.id" data-toggle="tooltip" :title="$t('generalTranslation.copy')"
                  @click="showCopyStudy(study.id, study.attributes.name)"></i>

                <section v-if="studyRoles[index] === 'Owner'">
                  <i class="fa fa-users-cog fa-2x homeButtons" :id="'buttonShowStudyPermissions' + study.id" data-toggle="tooltip" :title="$t('studyTranslation.permissions')"
                    @click="getDetailsOfStudy(study.id, studyRoles[index], 1, study.relationships.owners.data)"></i>

                  <i v-if="!study.attributes.is_active" class="fa fa-trash fa-2x homeButtons" :id="'buttonDeleteStudy' + study.id" data-toggle="tooltip" :title="$t('generalTranslation.delete')"
                    @click="showDeleteStudy(study.id, study.attributes.name)" ></i>
                </section>
              </section>
            </section>
          </li>
        </ul>
        <p v-if="studies.length < 1" id="noStudies" class="noAvailableElements">
          {{noGroups}}
          <i class="far fa-question-circle" v-if="isMyStudies && !noSearchResults" data-toggle="tooltip" :title="createStudyInstruction"></i>
        </p>

        <BasePagination
          id="paginationStudiesBottom"
          :numberOfPages="studyListData.allPages"
          :currentPage="studyListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </section>

    <BaseModal
      v-if="deleteStudyVisible"
      id="deleteStudyModal"
      :headerText="$t('myStudiesTranslation.deleteWarning', { name: studyNameForDeleting })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      :disableButtons="disableWhileCopy"
      @close-modal="closeModal"
    >
      <template v-slot:body>
        {{noteText}}
        <template v-if="noteText.length > 0">
          <br>
          <br>
        </template>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import permissionHelperMixin from '../mixins/permissionHelper';
import BasePagination from './BasePagination.vue';
import BaseModal from './BaseModal.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'StudyList',

  components: {
    BaseModal,
    BasePagination,
    BaseSearch,
  },

  mixins: [httpHelper, elementJSONToPropsHelper, permissionHelperMixin],

  props: {
    /*
    MyStudies: studyRoles[index] != 'None', copyBool: false
    MoreStudies: studyRoles[index] === 'None', copyBool: false
    CopyIntervention: studyRoles[index] != 'None', copyBool: true
    */

    //true if used in CopyIntervention/CopyDiary (always with studyRoles[index] != 'None')
    copyBool: {
      required: true,
      type: Boolean,
    },

    //true if used in CopyIntervention for activate intervention
    activateBool: {
      required: true,
      type: Boolean,
    },

    //do not react in watch functions while copy intervention functionality is used
    disableWhileCopy: {
      required: true,
      type: Boolean,
    },

    studyListData: { //with list, currentPage, allPages
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      studies: [],
      deleteStudyVisible: false,
      studyIdForDeleting: '',
      studyNameForDeleting: "",
      studyRoles: [],
      noteText: "",
      usersArr: [],
      loading: true,
      filterTerm: "",
      selectedStudyId: "",
      totp: null,
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getNotificationText',
    ]),

    isMyStudies: function(){
      return (this.$route.name === "myStudies")
    },

    isMoreStudies: function(){
      return (this.$route.name === "moreStudies")
    },

    myOrMoreStudiesHeadline: function(){
      if(this.isMyStudies){
        return Vue.i18n.translate('myStudiesTranslation.myStudies')
      }else{
        return Vue.i18n.translate('myStudiesTranslation.moreStudies')
      }
    },

    noGroups: function(){
      if(!this.activateBool){
        if(this.filterTerm === ""){
          return Vue.i18n.translate('generalTranslation.noGroups')
        }else{
          return Vue.i18n.translate('generalTranslation.noResults')
        }
      }else{
        return Vue.i18n.translate('generalTranslation.noStudies')
      }
    },

    createStudyInstruction: function(){
      if(!this.activateBool){
        if(this.isMyStudies){
          return Vue.i18n.translate('generalTranslation.noGroupsInstruction')
        }else{
          return Vue.i18n.translate('generalTranslation.noGroupsInstructionModal')
        }
      }else{
        return Vue.i18n.translate('generalTranslation.noStudiesInstruction')
      }
    },

    studyOrWorkgroup: function(){
      if(!this.activateBool){
        return "workgroup"
      }else{
        return "study"
      }
    },

    noSearchResults: function(){
      return (this.studies.length === 0 && this.filterTerm != '')
    },
  },

  watch:{
    'studyListData.list': function(newVal){
      this.studies = newVal;
      this.loading = false;
      this.getMyStudyRoles();
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error" && !this.disableWhileCopy){
        this.loading = false;
      }
    },
  },

  created(){
    if(this.getSelectedLanguage == ""){
      this.SET_SELECTEDLANGUAGE("de");
    }
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_SELECTEDLANGUAGE',
      'SET_MYROLEFORSTUDY',
    ]),

    getDetailsOfStudy: function(studyId, roleOrOwners, view, owners){
      this.closeNotification();
      this.SET_MYROLEFORSTUDY(roleOrOwners);
      this.$emit('route-to', { name: 'studyEditor', params: { studyId: studyId, view: view, owners: owners }});
    },

    showCopyStudy: function(studyId, studyName){
      if(studyId != ""){
        this.$emit('show-modal', { type: 'StudyCopy', id: studyId, name: studyName });
      }
    },

    showDeleteStudy: function(studyId, studyName){
      this.closeNotification();
      this.studyIdForDeleting = studyId;
      this.studyNameForDeleting = studyName;

      var self = this;
      this.requestInterventionsOfStudy(studyId, 1, "", false, -1, false)
      .then(function (response){
        self.noteText = (response.list.length > 0) ? Vue.i18n.translate('myStudiesTranslation.studyHasInterventionsWarning') : "";
        return self.requestDiariesOfStudy(studyId, 1, "", -1, false);
      })
      .then(function (response){
        if(response.list.length > 0){
          self.noteText = Vue.i18n.translate('myStudiesTranslation.studyHasInterventionsWarning');
        }

        self.deleteStudyVisible = true;
        self.$emit('show-modal', { type: 'StudyDelete', value: true });
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.showDeleteStudy(studyId, studyName) }, "");
      });
    },

    closeModal: function(done){
      this.deleteStudyVisible = false;
      this.$emit('show-modal', { type: 'StudyDelete', value: false });

      if(done){
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('myStudiesTranslation.deleteStudyLoad')});
        var requestPage = this.studyListData.currentPage;
        if(this.studies.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteStudyRequest(this.studyIdForDeleting)
        .then(function (){
          self.getStudies(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('myStudiesTranslation.deleteStudySuccess')});
          self.studyIdForDeleting = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModal(done) }, "");
        });
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    searchStudy: function(){
      this.getStudies(1);
    },

    //my roles for studies (access/collaborator/owner)
    getMyStudyRoles: function(){
      if(this.isMyStudies || this.copyBool){
        this.studyRoles = this.getMyRoles(this.studies, true, this.activateBool);
      }else{
        var arr = new Array(this.studies.length);
        arr.fill("None");
        this.studyRoles = arr;
      }
      this.loading = false;
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.closeNotification();
        this.getStudies(selectedNumber);
      }
    },

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getStudies(1);
    },

    getStudies: function(page){
      this.loading = true;
      this.$emit('get-new-list', { page: page, filterTerm: this.filterTerm });
    },

    setStudyForCopy: function(studyId, myRole, name){
      if(this.copyBool){
        this.selectedStudyId = studyId;
        this.$emit('item-selected', { studyId: Number(studyId), role: myRole, studyName: name } );
      }
    },

    getPermissionText: function(index){
      if(this.studyRoles[index] === "Collaborator"){
        return Vue.i18n.translate('permissionsTranslation.editPermission')
      }else if(this.studyRoles[index] === "Access"){
        return Vue.i18n.translate('permissionsTranslation.copyPermission')
      }else{
        return ""
      }
    },

    getStudyDescription: function(study){
      if(study.attributes.description != null && study.attributes.description != ''){
        return study.attributes.description
      }else{
        return Vue.i18n.translate('generalTranslation.noDescription')
      }
    },

    getPictureLink: function(studyPicture, studyId){
      if(!this.activateBool){
        return this.$urlUploads + '/studies/' + studyId + '/' + studyPicture
      }else{
        return this.$urlBase + "/" + studyPicture
      }
    },
  }
}
</script>
