<template>
  <div class="elementsQuestionSingleMultiple">
    <!-- questions with configurable answers -->
    <section class="wrapper">
      <section v-if="!isTranslation">
        <h3 class="answers">
          {{ 'elementsQuestionTranslation.answerOptions' | translate }} *
        </h3>
      </section>

      <ul :class="{ulAnswers: getIsEditable, wrapper: !getIsEditable, answerSpace: isTranslation}">
        <li v-for="(val, index) in propsObj.values" :key="index"> <!-- todo key -->
          <section :class="{wrapper: !isTranslation}">
            <section :class="{col: getIsEditable, secAnswerTable: getIsEditable, secAnswerTableTranslation: getIsEditable && isTranslationMode}">
              <ElementsQuestionAnswer
                :key="'answer' + (answerCounter + index)"
                :id="'element' + propsObj.id + 'answer' + (answerCounter + index) + (isTranslation ? 'Translation' : '')"
                :pos="index"
                :answerProp="translation.answers[index]"
                :valueProp="val"
                :type="propsObj.selectedQuestionProp"
                :isTranslation="isTranslation"
                @change-element="$emit('change-element', $event)"
              ></ElementsQuestionAnswer>
            </section>

            <section v-if="getIsEditable && !isTranslation" class="col secAnswerLeft">
              <i class="fa fa-trash" :id="'buttonRemoveAnswerOption' + propsObj.id + 'Index' + index" data-toggle="tooltip"
                :title="$t('elementsQuestionTranslation.removeAnswer')" @click="addRemoveAnswer('removeAnswer', index)"></i>
            </section>
          </section>
        </li>
      </ul>

      <section v-if="getIsEditable && !isTranslation" class="wrapper addAnswerButton">
        <button type="button" :id="'buttonAddAnswerOption' + propsObj.id" @click="addRemoveAnswer('addAnswer', 0)">
          {{ 'elementsQuestionTranslation.addAnswerOption' | translate }}
        </button>
      </section>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ElementsQuestionAnswer from './ElementsQuestionAnswer.vue'

export default {
  name: 'ElementsQuestionSingleMultiple',

  components: {
    ElementsQuestionAnswer,
  },

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      answerCounter: 0,
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),
  },

  methods:{
    addRemoveAnswer: function(name, index){
      this.answerCounter += this.propsObj.values.length;
      this.$emit('change-element', {'type': 'attribute', 'name': name, 'isTranslation': this.isTranslation, 'pos': index }); 
    },
  }
}
</script>
