<template>
  <div class="elementsText">
    <section class="notSummary">
      <section v-if="errorTextsForElements[propsObj.id] != '' && getIsEditable" :id="'errorTextElement' + propsObj.id" :style="{visibility: showIfNotTranslation}" class="errorMessage">
        {{errorTextsForElements[propsObj.id]}}
      </section>

      <section v-if="getIsEditable" key="secEditable" class="wrapper">
        <section v-if="!showEditorBool" class="question-text text ql-editor">
          <span :id="'textEditorClosed' + propsObj.id + (isTranslation ? 'Translation' : '')" v-html="content" :class="{textElementDisplayFullScreen: !isTranslationMode,
            textElementDisplay: isTranslationMode}" @click="showEditor"></span>
        </section>
        <div v-show="showEditorBool">
          <div :id="toolbarId" :class="{toolbarNormal: isTranslationMode, toolbarFullScreen: !isTranslationMode, 'ql-toolbar': true, 'ql-snow': true}">
            <ElementsTextToolbar
              :id="propsObj.id"
              :isTranslationMode="isTranslationMode"
              :isSkill="isSkill"
              @show-modal="showModal(true, $event.isRef)"
            ></ElementsTextToolbar>
          </div>
          <!-- :customModules="customModulesForEditor" -->
          <vue-editor
            :id="'textEditor' + propsObj.id + (isTranslation ? 'Translation' : '')"
            ref="textEditorRef"
            :key="reInitKey"
            v-model="content"
            :editorToolbar="'#' + toolbarId"
            :editorOptions="editorSettings"
            class="textEditor"
            :class="{textEditorFullScreen: !isTranslationMode}"
            @text-change="$emit('change-element', {'type': 'translation-attribute', 'name': 'text', 'isTranslation': isTranslation,
              'value': replaceLabelsWithPositions(content)})"
          ></vue-editor>
        </div>
      </section>
      <section v-else :id="'textEditorNotEditable' + propsObj.id + (isTranslation ? 'Translation' : '')" key="secNotEditable" class="wrapper">
        <p>
          <section class="question-text text ql-editor">
            <span v-html="content"></span>
          </section>
        </p>
      </section>

      <section v-if="getIsEditable" class="hide">
        {{content}}
      </section>

      <section v-if="!isTranslation" class="wrapper">
        <label>
          {{ 'elementsTextTranslation.textTypeDesc' | translate }} *
        </label>
        <select :value="propsObj.type" :disabled="!getIsEditable" :id="'inputTextType' + propsObj.id" class="textTypeSelect" @input="$emit('change-element', {'type': 'attribute',
            'name': 'type', 'value': $event.target.value})">
          <option value="" :id="'inputTextType' + propsObj.id + 'None'" key="empty" disabled>
            {{textTypeEmpty}}
          </option>
          <option v-for="data in textTypeList" :value="data" :id="'inputTextType' + propsObj.id + data" :key="data">
            {{ $t('elementsTextTranslation.' + data) }}
          </option>
        </select>
      </section>
    </section>

    <StudyMedia
      v-if="studyMediaVisible"
      :id="'mediaSelection' + propsObj.id"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="true"
      @close-modal="showModal(false, false)"
      @item-selected="selectMedia"
    ></StudyMedia>

    <BaseModal
      v-if="insertReferenceVisible"
      id="insertReferenceModal"
      :headerText="$t('referenceTranslation.insertReference')"
      :leftButtonText="$t('referenceTranslation.insert')"
      :disableLeftButton="!(insertLabel != '' || insertLabel === 0)"
      @close-modal="closeModal"
    >
      <template v-slot:body>
        {{ 'referenceTranslation.referenceFor' | translate }}<br>
        <select v-model="insertLabel" :id="'inputReference' + propsObj.id">
          <option value="" disabled :id="'inputReference' + propsObj.id + 'None'">
            {{ 'elementsBlockTranslation.selectLabel' | translate }}
          </option>
          <option v-for="(option, index) in getQuestionLabelList.filter(option => option && option != 0)" :value="index" :id="'inputReference' + propsObj.id + '-' + index" :key="index">
            {{option.label}}
          </option>
        </select>
        <p v-if="errorModal != ''" class="errorMessage" id="errorTextReference">
          {{errorModal}}
        </p>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {VueEditor, Quill} from 'vue2-editor';
//import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';
import BaseModal from './BaseModal.vue';
import ElementsTextToolbar from './ElementsTextToolbar.vue';
import StudyMedia from './StudyMedia.vue';

Quill.register('modules/imageResize', ImageResize);

export default {
  name: 'ElementsText',

  components: {
    BaseModal,
    VueEditor,
    ElementsTextToolbar,
    StudyMedia,
  },

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    showTextEditor: {
      required: true,
      type: Number,
    },

    studyId: {
      required: true,
      type: Number,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      toolbarId: "",
      errorModal: "",
      insertReferenceVisible: false,
      position: 0,
      insertLabel: "",
      showEditorBool: false,
      studyMediaVisible: false,
      reInitKey: 0,
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        //[{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        //[{ 'header': 1 }, { 'header': 2 }],
        ['blockquote'], //['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link'], //['link', 'image', 'video', 'formula'],
        //[{ 'direction': 'rtl' }],
        ['clean']
      ],
      editorSettings: {
        modules: {
          //imageDrop: true,
          imageResize: {},
        }
      },
      customModulesForEditor: [
          //{ alias: 'imageDrop', module: ImageDrop },
          { alias: 'imageResize', module: ImageResize }
      ],
      textTypeList: ["none", "important", "tip", "info", "success", "highlight"],
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
      'getQuestionLabelList',
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    textTypeEmpty: function(){
      if(this.getIsEditable){
        return Vue.i18n.translate('elementsTextTranslation.textType')
      }else{
        return Vue.i18n.translate('elementsTextTranslation.none')
      }
    },
  },

  watch: {
    showTextEditor: function(newVal){
      if(newVal != -1){
        if(newVal != this.propsObj.id){
          this.showEditorBool = false;
        }else{
          this.showEditorBool = true;
        }
      }
    },

    //needs deep:true & handler because array in array changes -> 'normal' watch function does not detect changes in nested array
    getQuestionLabelList: {
      handler: function(){
        this.content = this.replacePositionsWithLabels(this.translation);
        this.reInitKey++;
      },
      deep: true
    },

    'translation': function(newVal){
      if(newVal != ""){
        if(!newVal.includes("cursor: nwse-resize")){
          this.content = this.replacePositionsWithLabels(newVal);
        }
      }else{
        this.content = "";
      }
    },
  },

  created(){
    this.toolbarId = 'textEditorToolbar' + this.propsObj.id + this.isTranslation;

    if(this.showTextEditor != -1 && this.showTextEditor === this.propsObj.id){
      this.showEditorBool = true;
    }

    this.content = this.replacePositionsWithLabels(this.translation);
  },

  methods: {
    closeModal: function(done){
      if(done){
        //check if label was selected
        if(this.insertLabel != "" || this.insertLabel === 0){
          this.errorModal = "";
          this.insertRef(this.insertLabel);
        }else{
          this.errorModal = Vue.i18n.translate('elementsBlockTranslation.errorLabel');
        }
      }else{
        this.showModal(false, true);
      }
    },

    replacePositionsWithLabels: function(textProp){
      if (textProp == undefined) {
        return "";
      }
      let text = JSON.parse(JSON.stringify(textProp));
      const self = this;
      //first replace gets {{number}} as x -> brackets are replaced in second replace -> only number -> get label
      text = text.replace(/\{{2}([^}{]+)}{2}/g, function(x) {
        const questionId = x.replace(/{/g, "").replace(/}/g, "");
        let label = "";
        if (questionId != "") {
          if (self.getQuestionLabelList[questionId] != 0) {
            label = self.getQuestionLabelList[questionId].label;
          }
        }
        if (questionId != -1 && label != "") {
          return "{{" + label + "}}";
        } else {
          return "";
        }
      });
      return text;
    },

    replaceLabelsWithPositions: function(textProp){
      var text = JSON.parse(JSON.stringify(textProp));
      text = text.replace(/"/g, "'");
      var self = this;
      //first replace gets {{label}} as x -> brackets are replaced in second replace -> only label -> get index
      //if label not in questionlabellist -> return ""
      text = text.replace(/\{{2}([^}{]+)}{2}/g, function(x){
        var label = x.replace(/{/g, "").replace(/}/g, "");
        var questionId = self.getQuestionLabelList.findIndex(elem => elem && elem.label === label);
        return (questionId != -1) ? "{{" + questionId + "}}" : ""
      });
      return text
    },

    insertRef: function(index){
      var labelToInsert = "";
      var labelList = this.getQuestionLabelList.filter(option => option && option != 0);

      if(labelList[index] != 0){
        labelToInsert = "{{" + labelList[index].label + "}}";
      }

      this.$refs.textEditorRef.quill.insertText(this.position, String(labelToInsert));
      //this.$refs.textEditorRef.quill.clipboard.dangerouslyPasteHTML('<cite contenteditable="false">bla</cite>');
      this.showModal(false, true);
    },

    showModal: function(bool, isRef){
      if(bool){
        this.position = this.$refs.textEditorRef.quill.getSelection(true).index;
      }

      if(isRef){
        this.insertReferenceVisible = bool;
      }else{
        this.studyMediaVisible = bool;
      }

      this.$emit('change-element', {'type': 'show-media-reference-modal', 'value': bool});
    },

    showEditor: function(){
      this.showEditorBool = true;
      this.$emit('change-element', {'type': 'show-texteditor', 'value': this.propsObj.id});
    },

    selectMedia: function(obj){
      obj.reverse();
      for(var file in obj){
        let url = obj[file].link;
        this.$refs.textEditorRef.quill.insertEmbed(this.position, 'image', url);
      }
      this.showModal(false, false);
    },
  },
}
</script>
