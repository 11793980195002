<template>
  <div id="diaryList" class="diaryList">
    <section class="smallerComp">
      <section v-if="getIsEditable && !copyBool && !loading" class="wrapper createImportButtons">
        <section class="col sec50">
          <section v-if="diaryListData.list.length > 0">
            {{ 'diaryTranslation.diaryInstruction' | translate }}
            <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('diaryTranslation.diaryInstructionTooltip')"></i>
          </section>
        </section>
        <section class="col sec50">
          <!--
          <button type="button" id="buttonImportDiary" class="createButton" @click="$emit('show-modal', {type: 'DiaryImport'})">
            {{ 'diaryTranslation.importDiary' | translate }}
          </button>
          -->
          <button type="button" id="buttonCreateDiary" class="createButton buttonForm" @click="$emit('show-modal', {type: 'DiaryCreate'})">
            {{ 'studyTranslation.newDiary' | translate }}
          </button>
        </section>
      </section>

      <BaseSearch
        v-show="!loading && (diaryListData.list.length > 0 || noSearchResults)"
        id="searchDiaries"
        type="diary-list"
        @get-new-list="getNewList"
        @reset-page="$emit('reset-page')"
      ></BaseSearch>

      <section v-if="!loading">
        <BasePagination
          id="paginationDiariesTop"
          :numberOfPages="diaryListData.allPages"
          :currentPage="diaryListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <ul :class="{selectFromList: copyBool && !disableWhileCopy}">
          <li v-for="(diary, index) in diaryListData.list" :id="'entryDiary' + diary.id" :key="diary.id" class="interventionElement" :class="{selectedForCopy: copyBool && selectedDiaryId === diary.id}"
              @click="setDiaryForCopy(diary.id, diary.attributes.questionnaire_id, diary.attributes.study_id, diaryRoles[index])">
            <section class="wrapper">
              <section class="col homeImageSection">
                <img v-if="diary.attributes.picture && !hasNoPermissions" :src="getPictureLink(diary.attributes.picture, diary.attributes.study_id)">
                <i class="far fa-image fa-2x" v-else></i>
              </section>
              <section class="col homeDescriptionSection">
                <button type="button" :id="'buttonShowDiary' + diary.id" :disabled="hasNoPermissions || copyBool" class="linkButton" @click="getDetailsOfDiary(diary.id, 1)">
                  {{diary.attributes.name}}
                </button>

                <p>
                  {{getDiaryTitle(diary)}}
                </p>
                <p>
                  {{getDiaryDescription(diary)}}
                </p>
              </section>

              <section v-if="!hasNoPermissions && !copyBool" class="col homeButtonSectionSmall">
                <i class="fa fa-info-circle fa-2x homeButtons" :id="'buttonShowDiaryDetails' + diary.id" data-toggle="tooltip" :title="$t('myStudiesTranslation.details')"
                   @click="getDetailsOfDiary(diary.id, 0)"></i>

                <i class="fa fa-copy fa-2x homeButtons" :id="'buttonCopyDiary' + diary.id" data-toggle="tooltip" :title="$t('generalTranslation.copy')"
                  @click="copyDiary(diary.id, diary.attributes.name)"></i>

                <section v-if="isOwner">
                  <i v-if="!diary.attributes.is_active" :id="'buttonDeleteDiary' + diary.id" class="fa fa-trash fa-2x homeButtons" data-toggle="tooltip"
                    :title="$t('generalTranslation.delete')" @click="showModal(Number(diary.id), diary.attributes.name)"></i>
                </section>
              </section>
            </section>
          </li>
        </ul>
        <p v-if="diaryListData.list.length < 1" id="noDiaries" class="noAvailableElements">
          {{noDiaries}}
          <i class="far fa-question-circle" v-if="!noSearchResults" data-toggle="tooltip" :title="$t('generalTranslation.noDiariesTooltip')"></i>
        </p>

        <BasePagination
          id="paginationDiariesBottom"
          :numberOfPages="diaryListData.allPages"
          :currentPage="diaryListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </section>

    <BaseModal
      v-if="deleteDiaryVisible"
      id="deleteDiaryModal"
      :headerText="$t('diaryTranslation.deleteWarning', { name: diaryNameForDeleting })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      @close-modal="closeModal"
    >
      <template v-slot:body>
        <template v-if="diaryUsedInLessons.length > 0 || diaryUsedInInterventions.length > 0">
          {{ 'diaryTranslation.deleteWarningUsed' | translate }}
          <br>
          <br>
        </template>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import permissionHelperMixin from '../mixins/permissionHelper';
import BasePagination from './BasePagination.vue';
import BaseModal from './BaseModal.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'DiaryList',

  components: {
    BaseModal,
    BasePagination,
    BaseSearch
  },

  mixins: [httpHelper, permissionHelperMixin],

  props: {
    /*
    differentiation between collaborating & non-collaborating:
    collaborating: getMyRoleForStudy != "None", non-collaborating: getMyRoleForStudy === "None"
    */

    //do not react in watch functions while copy diary functionality is used
    disableWhileCopy: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number
    },

    diaryListData: { // with list, currentPage, allPages
      required: true,
      type: Object,
    },

    //true if used in copy elements functionality, false if used in study
    copyBool: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return{
      deleteDiaryVisible: false,
      diaryIdForDeleting: -1,
      loading: true,
      diaryUsedInLessons: [],
      diaryUsedInInterventions: [],
      filterTerm: "",
      diaryNameForDeleting: "",
      selectedDiaryId: "",
      diaryRoles: [],
    }
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
      'getNotificationText',
      'getIsEditable',
    ]),

    isOwner: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    hasNoPermissions: function(){
      return (this.getMyRoleForStudy === "None")
    },

    noSearchResults: function(){
      return (this.diaryListData.list.length === 0 && this.filterTerm != '')
    },

    noDiaries: function(){
      if(this.filterTerm === ""){
        return Vue.i18n.translate('generalTranslation.noDiaries')
      }else{
        return Vue.i18n.translate('generalTranslation.noResults')
      }
    },
  },

  watch:{
    'diaryListData.list'(){
      this.loading = false;
      if(this.copyBool){
        this.diaryRoles = this.getMyRoles(this.diaryListData.list, false, false);
      }
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error" && !this.disableWhileCopy){
        this.loading = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    getDetailsOfDiary: function(diaryId, view){
      this.closeNotification();
      this.$emit('route-to', { name: 'diaryEditor', params: { diaryId: diaryId, view: view }});
    },

    copyDiary: function(diaryId, diaryName){
      if(diaryId != ""){
        this.$emit('show-modal', { type: 'DiaryCopy', id: diaryId, name: diaryName, studyId: this.studyId });
      }
    },

    closeModal: function(done){
      this.deleteDiaryVisible = false;
      this.$emit('show-modal', { type: 'DiaryDelete', value: false });

      if(done){
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('diaryTranslation.deleteDiaryLoad')});
        var requestPage = this.diaryListData.currentPage;
        if(this.diaryListData.list.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteDiaryRequest(this.diaryIdForDeleting)
        .then(async function (){
          //remove id of deleted diary from other interventions
          if(self.diaryUsedInInterventions.length > 0){
            for(var intervention in self.diaryUsedInInterventions){
              var unlockDiaries = self.diaryUsedInInterventions[intervention].attributes.unlock_diaries;
              var index = unlockDiaries.indexOf(self.diaryIdForDeleting);
              if(index != -1){
                unlockDiaries.splice(index, 1);
              }

              var json = {
                "data" : {
                  "type" : "interventions",
                  "attributes" : {
                    "unlock_diaries": unlockDiaries
                  }
                }
              };

              if(json.data.attributes.unlock_diaries === null){
                json.data.attributes.unlock_diaries = [];
              }

              await self.updateInterventionDetailsUnlockDiariesRequest(self.diaryUsedInInterventions[intervention].id, json);
            }
          }

          if(self.diaryUsedInLessons.length > 0){
            var unlockJson = self.getUpdateUnlockDiariesForLessonsData(self.diaryIdForDeleting, self.diaryUsedInLessons);
            self.updateUnlockDiariesForLessonsAfterDeleteDiaryRequest(unlockJson, requestPage);
          }else{
            self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.deleteDiarySuccess')});
            self.getNewDiariesList(requestPage);
          }
          self.diaryIdForDeleting = -1;
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModal(done) }, "");
        });
      }
    },

    updateUnlockDiariesForLessonsAfterDeleteDiaryRequest: function(unlockJson, requestPage){
      var self = this;
      this.updateUnlockDiariesForLessonsRequest(unlockJson)
      .then( function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.deleteDiarySuccess')});
        self.getNewDiariesList(requestPage);
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.updateUnlockDiariesForLessonsAfterDeleteDiaryRequest(unlockJson, requestPage) }, "");
      });
    },

    getUpdateUnlockDiariesForLessonsData: function(diaryId, diaryUsedInLessons){
      //remove id of deleted diary from other lessons
      var newUnlockData = [];
      for(var lesson in diaryUsedInLessons){
        var arr = diaryUsedInLessons[lesson].attributes.unlock_diaries;
        var index = arr.indexOf(diaryId);
        if(index != -1){
          arr.splice(index, 1);
          newUnlockData.push(
            {
              "questionnaire_id": diaryUsedInLessons[lesson].id,
              "unlock_diaries": arr
            }
          );
        }
      }

      var unlockJson = {
        "data": {
          "type": "questionnaires",
          "attributes": {
            "unlock_data": newUnlockData
          }
        }
      }
      return unlockJson
    },

    showModal: function(diaryIdForDeleting, diaryName){
      this.closeNotification();
      this.diaryIdForDeleting = diaryIdForDeleting;
      this.diaryNameForDeleting = diaryName;

      var self = this;
      this.requestDiaryUsedInLessonsOfStudy(diaryIdForDeleting, this.studyId)
      .then(function (response){
        self.diaryUsedInLessons = response;
        return self.requestDiaryUsedInInterventionsOfStudy(diaryIdForDeleting, self.studyId);
      })
      .then(function (response){
        self.diaryUsedInInterventions = response;
        self.deleteDiaryVisible = true;
        self.$emit('show-modal', { type: 'DiaryDelete', value: true });
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.showModal(diaryIdForDeleting, diaryName) }, "");
      });
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getNewDiariesList(1);
    },

    getNewDiariesList: function(page){
      this.loading = true;
      this.$emit('get-new-list', {studyId: this.studyId, page: page, filterTerm: this.filterTerm });
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.closeNotification();
        this.getNewDiariesList(selectedNumber);
      }
    },

    getDiaryTitle: function(diary){
      if(diary.attributes.title != null && diary.attributes.title != ''){
        return diary.attributes.title
      }else{
        return Vue.i18n.translate('generalTranslation.noTitle')
      }
    },

    getDiaryDescription: function(diary){
      if(diary.attributes.description != null && diary.attributes.description != ''){
        return diary.attributes.description
      }else{
        return Vue.i18n.translate('generalTranslation.noDescription')
      }
    },

    getPictureLink: function(diaryPicture, studyId){
      return this.$urlUploads + '/studies/' + studyId + '/' + diaryPicture
    },

    setDiaryForCopy: function(diaryId, lessonId, studyId, role){
      if(this.copyBool){
        this.selectedDiaryId = diaryId;
        this.$emit('item-selected', { diaryId: Number(diaryId), lessonId: Number(lessonId), studyId: Number(studyId), role: role });
      }
    },
  }
}
</script>
