<template>
  <div class="elementsHeadline">
    <section v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''" :id="'errorTextElement' + propsObj.id" :style="{visibility: showIfNotTranslation}" class="wrapper errorMessage">
      {{errorTextsForElements[propsObj.id]}}
    </section>

    <section class="wrapper">
      <ElementsReference
        :id="propsObj.id + isTranslation"
        :text="translation"
        :classToSet="propsObj.selectedHeadlineProp"
        :color="propsObj.color"
        :placeholderData="placeholderText"
        :isSkill="isSkill"
        @change-text="changeText"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </section>

    <section v-if="!isTranslation" class="wrapper">
      <label>
        {{ 'elementsHeadlineTranslation.headlineFontsize' | translate }} *
      </label>
      <select :value="propsObj.selectedHeadlineProp" :id="'inputHeadlineSize' + propsObj.id" class="col sec50" :disabled="!getIsEditable"
          @input="$emit('change-element', {'type': 'attribute', 'name': 'selectedHeadlineProp', 'value': $event.target.value})">
        <option value="" disabled :id="'inputHeadlineSize' + propsObj.id + 'None'">
          {{ 'elementsHeadlineTranslation.selectHeadlineFontsize' | translate }}
        </option>
        <option v-for="n in 7" :value="'headline' + (n - 1)" :id="'inputHeadlineSize' + propsObj.id + '-' + (n - 1)" :key="n">
          {{getHeadlineText(n - 1)}}
        </option>
      </select>

      <section class="col sec50">
        <label>
          {{ 'elementsHeadlineTranslation.selectHeadlineColor' | translate }} *
        </label>
        <input type="color" :id="'inputHeadlineColor' + propsObj.id" :list="'headlineColorValues' + propsObj.id" :value="propsObj.color" :disabled="!getIsEditable"
          @input="$emit('change-element', {'type': 'attribute', 'name': 'color', 'value': $event.target.value})">

        <datalist :id="'headlineColorValues' + propsObj.id">
          <option v-for="data in colorsList" :value="data" :id="'headlineColorValues' + propsObj.id + '-' + data" :key="data">
          </option>
        </datalist>
      </section>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import ElementsReference from './ElementsReference.vue';

export default {
  name: 'ElementsHeadline',

  components: {
    ElementsReference
  },

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      colorsList: [
        "#ffffff", "#bbbbbb", "#888888", "#444444", "#000000",
        "#facccc", "#f06666", "#e60000", "#a10000", "#5c0000",
        "#ffebcc", "#ffc266", "#ff9900", "#b26b00", "#663d00",
        "#ffffcc", "#ffff66", "#ffff00", "#b2b200", "#666600",
        "#cce8cc", "#66b966", "#008a00", "#006100", "#003700",
        "#cce0f5", "#66a3e0", "#0066cc", "#0047b2", "#002966",
        "#ebd6ff", "#c285ff", "#9933ff", "#6b24b2", "#3d1466"
      ],
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    placeholderText: function(){
      return !this.isTranslation ? Vue.i18n.translate('elementsHeadlineTranslation.headline') :
        Vue.i18n.translate('elementsHeadlineTranslation.headlineTranslation')
    },
  },

  methods:{
    changeText: function(text){
      this.$emit('change-element', {'type': 'translation-attribute', 'name': 'headline', 'isTranslation': this.isTranslation, 'value': text });
    },

    getHeadlineText: function(size){
      return Vue.i18n.translate('elementsHeadlineTranslation.headlineType' + size)
    }
  }
}
</script>
